@charset 'UTF-8';

/* キャリコネとは - セクション */
.about-section {
    margin-bottom: 80px;
}
.about-section__title {
    margin-bottom: 40px;
    color: $font--black;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.6;
    text-align: center;
}
.about-section__lead {
    margin-bottom: 40px;
    color: $font--black;
    font-size: 16px;
    line-height: 1.6;
    text-align: center;
}

/* キャリコネとは - 登録ボタン */
.about-button {
    display: inline-flex;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 12px 32px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    font-weight: bold;
    line-height: 1.6;
    &::before {
        display: block;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        font-weight: normal;
    }
    &:hover, &:active {
        text-decoration: none !important;
    }

    &.is-register { //登録ボタン
        border-color: $border--link--regist;
        background: $bg--link--regist;
        font-size: 14px;
        &:link, &:visited {
            color: $font--black;
        }
        &:hover, &:active {
            border-color: $border--link--regist--hover;
            background: $bg--link--regist--hover;
        }
    }
    &.is-secondary { //セカンダリーボタン
        border-color: $border--blue;
        background: $bg--blue--light;
        font-size: 13px;
        &:link, &:visited {
            color: $font--link--blue;
        }
        &:hover, &:active {
            border-color: $border--blue--hover;
            background: $bg--blue--light--hover;
            color: $font--link--blue--hover;
        }
    }
}

/* キャリコネとは - ローカルナビ */
.about-local-nav {
    margin-bottom: 80px;
}
.about-local-nav__title {
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    text-align: center;
}
.about-local-nav__list {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: auto;
    gap: 24px;
    margin-bottom: 20px;
}
.about-local-nav__list-item {
    display: flex;
    align-items: center;
    height: 72px;
    padding: 0 8px 0 12px;
    border: 1px solid $border--blue;
    border-radius: 4px;
    background: $white;
    color: $font--link--blue;
    &::before {
        margin-right: 8px;
        font-size: 40px;
    }
    > span {
        flex: 1;
        color: $font--black;
        font-size: 14px;
        font-weight: bold;
    }
    .i--font-cc-arrow {
        font-size: 20px;
    }
    &:hover, &:active {
        border-color: $border--blue--hover;
        color: $font--link--blue--hover;
        text-decoration: none !important;
        > span {
            color: $font--link--blue--hover;
        }
    }
    &.is-current {
        border-color: $blue;
        background: $blue;
        color: $white;
        > span {
            color: $white;
        }
    }
}

/* キャリコネとは - モーダルウィンドウ */
.about-modal-link {
    display: inline-block;
    position: relative;
    padding-right: 20px;
    color: $font--link--blue;
    cursor: pointer;
    &:hover, &:active {
        text-decoration: underline;
    }
    > i {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        line-height: 1;
    }
}
.about-modal__subtitle {
    position: relative;
    margin-bottom: 12px;
    padding-left: #{3px + 8px};
    font-size: 14px;
    font-weight: bold;
    &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: calc(100% - 0.4em);
        border-left: 3px solid $font--blue--review;
        content: "";
    }
}
.modal .modal__wrap {
    dt.about-modal__title {
        padding-left: 0;
        &::before {
            display: none;
        }
    }
    dd {
        .about-modal__list {
            margin-bottom: 24px;
            &:last-of-type {
                margin-bottom: 0;
            }
            > li {
                text-indent: -1.4em;
                &::marker {
                    margin-right: 0;
                }
            }
        }
    }
}

/* キャリコネとは（トップ） - バルーン */
.about-index-balloon {
    position: relative;
    width: 920px;
    margin: 0 auto 100px;
    &::before {
        display: block;
        position: absolute;
        left: 6px;
        top: 6px;
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 60px;
        background: linear-gradient(90deg, rgba(191,223,255,1) 0%, rgba(212,204,255,1) 100%);
        content: "";
    }
}
.about-index-balloon__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
    width: 100%;
    min-height: 300px;
    padding: 24px 120px;
    border: 3px solid $blue;
    border-radius: 60px;
    background: $white;
}

.about-index-balloon__decoration {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    i {
        display: block;
        color: $blue;
        font-size: 72px;
        &:nth-of-type(2) {
            margin: 20px 0;
        }
    }
    &.is-left {
        left: 20px;
        i:nth-of-type(1),
        i:nth-of-type(3) {
            margin-left: 30px;
        }
    }
    &.is-right {
        right: 20px;
        i:nth-of-type(2) {
            margin-left: 30px;
        }
    }
}

/* キャリコネとは（トップ） - バルーン テキスト */
.about-index-balloon__title {
    margin: 0 0 20px;
    padding: 0;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.6;
    text-align: center;
}
.about-index-balloon__text {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.6;
    text-align: center;
}

/* キャリコネとは（トップ） - バルーン テイル */
.about-index-balloon__tail {
    position: absolute;
    right: 150px;
    bottom: -21px;
    z-index: 4;
    width: 48px;
    height: 42px;
}
.about-index-balloon__tail-fill {
    fill: $white;
    fill-rule: evenodd;
    stroke-width: 0;
}
.about-index-balloon__tail-stroke {
    fill: $blue;
    stroke-width: 0;
}
.about-index-balloon__tail-shadow {
    position: absolute;
    right: calc(150px - 6px);
    bottom: calc(-21px - 6px);
    z-index: 2;
    width: 48px;
    height: 42px;
}
.about-index-balloon__tail-shadow-fill {
    fill: #d1cfff;
    stroke-width: 0;
}

/* キャリコネとは（トップ） - 口コミを閲覧するには */
.about-index-permissions {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 24px;
}
.about-index-permissions-item {
    display: flex;
    flex-direction: column;
    padding: 16px 20px 20px;
    border: 1px solid $border--gray;
    background: $white;
    line-height: 1.6;
    box-shadow: 0 4px 8px rgba(0,0,0,.05);
}
.about-index-permissions-item__title {
    margin-bottom: 20px;
    text-align: center;
    i {
        display: flex;
        justify-content: center;
        color: $font--black--light;
        font-size: 48px;
    }
}
.about-index-permissions-item__title-main {
    display: block;
    font-size: 18px;
    font-weight: bold;
}
.about-index-permissions-item__title-sub {
    display: inline-block;
    margin-top: 8px;
    padding: 4px 20px;
    border-radius: 15px;
    background: $bg--gray;
    font-size: 13px;
}
.about-index-permissions-item__content {
    flex: 1;
    font-size: 14px;
}
.about-index-permissions-item__content-main {
    margin-bottom: 20px;
}
.about-index-permissions-item__content-note {
    color: $font--gray--light;
    font-size: 11px;
}
.about-index-permissions-item__cta {
    margin-top: 20px;
}

/* キャリコネとは（トップ） - 会員特典 */
.about-index-benefits {
    position: relative;
    width: 100%;
    margin-bottom: 40px;
    border: 1px solid $border--gray;
    background: $white;
}
.about-index-benefits__inner {
    border: 8px solid $white;
}
.about-index-benefits__table {
    width: 100%;
    table-layout: fixed;
    color: $font--black;
    font-size: 14px;
    td {
        padding: 12px;
        border: 1px solid $border--gray2;
        text-align: center;
    }
}
.about-index-benefits__table-head {
    padding: 12px;
    border: 1px solid $border--gray2;
    background: $bg--blue--light;
    font-weight: bold;
    text-align: center;
}
.about-index-benefits__table-sticky {
    padding: 12px;
    border: 1px solid $border--gray2;
    background: $bg--gray;
}

.about-index-benefits__title {
    display: flex;
    align-items: center;
    font-weight: bold;
}
.about-index-benefits__title-number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 4px;
    background: $blue;
    color: $white;
    font-size: 12px;
    font-weight: bold;
}
.about-index-benefits__title-text {
    flex: 1;
}
.about-index-benefits__circle {
    width: 24px;
    height: 24px;
    vertical-align: bottom;
    circle {
        fill: $white;
        stroke: $blue;
        stroke-width: 4px;
    }
}
.about-index-benefits__note {
    display: inline-block;
    margin-top: 4px;
}
.about-index-benefits__hint {
    display: none;
}

.about-index-benefits__detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.about-index-benefits__detail-item {
    width: calc((100% - 24px) / 2);
    padding: 20px;
    border: 1px solid $border--gray;
    background: $white;
    line-height: 1.6;
    &:nth-of-type(n+3) {
        margin-top: 24px;
    }
    > dt {
        margin-bottom: 8px;
    }
    > dd {
        font-size: 14px;
    }
    .about-index-benefits__title-number {
        width: 24px;
        height: 24px;
        font-size: 14px;
    }
    .about-index-benefits__title-text {
        font-size: 16px;
    }
}

/* キャリコネとは（トップ） - スカウト */
.about-index-scout {
    margin-bottom: 40px;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: $border--gray;
    background: $white;
    color: $font--black;
    line-height: 1.6;
}
.about-index-scout__title {
    padding: 8px 20px;
    border-top: 3px solid $blue;
    border-bottom: 1px solid $border--gray;
    font-size: 20px;
    font-weight: bold;
}
.about-index-scout__content {
    padding: 20px;
    font-size: 14px;
}

.about-index-scout-description {
    position: relative;
    min-height: 245px;
    margin-bottom: -20px;
}
.about-index-scout-description__image {
    position: absolute;
    left: 20px;
    bottom: 0;
}
.about-index-scout-description__text {
    padding: 0 0 20px #{200px + 20px + 40px};
}

.about-index-scout-setting {
    margin-top: 20px;
    padding: 0 20px;
    border: 1px solid $border--gray;
    border-radius: 6px;
}
.about-index-scout-setting__item {
    position: relative;
    padding: 20px 200px 20px 80px;
    &:nth-of-type(n+2) {
        border-top: 1px solid $border--gray;
    }
    > dt {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
    }
    > dd {
        font-size: 14px;
    }
    .about-button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 180px;
    }
}
.about-index-scout-setting__item-num {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    color: $font--blue--review;
    font-size: 18px;
    line-height: 1;
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 60px;
        font-weight: normal;
    }
}
.about-index-scout__cta {
    width: 280px;
    margin: 0 auto 40px;
}