@charset 'UTF-8';

/*
REVIEWトップ
---------------------------------------------------------------------------- */
// 小チャート
.sub_page {
    #column>li.column_2 {
        .chart_box {
            position: relative;
            margin: -5px auto 15px;
            z-index: 1;
            width: 156px;
            height: 150px;
        }
    }
}

.review_top_more_read {
    display: block;
    margin: 10px 0;
    text-align: right;
    width: 100%;
}

@media (max-width: 767px) {
    .review_top_more_read {
        text-align: left;
    }
}
