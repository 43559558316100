@charset 'UTF-8';

/*
口コミを探すリンク（2017.7.5）
---------------------------------------------------------------------------- */
@media (max-width: 640px) {

    /* REVIEWトップに配置 */
    .column_2 {
        .recommend_navi {
            background: none;
            border: none;
            margin-bottom: 25px;
            padding: 0;

            /* モーダルウィンドウを開くメニュー  */
            #sp_modal_menu {
                border-top: 1px solid $lightgray;
                font-size: $fsize-small-middle;
                margin-top: -60px;
                margin-bottom: 15px;
                padding-top: 60px;
                a {
                    background: $white;
                    border: 1px solid $lightgray;
                    color: $darkgray;
                    display: block;
                    font-weight: bold;
                    margin-top: -1px;
                    padding: 15px 25px 15px 38px;
                    position: relative;
                    width: 100%;
                    &:before {
                        color: $blue;
                        display: block;
                        font-family: "fontawesome";
                        font-size: $fsize-large;
                        left: 35px;
                        margin-top: -8px;
                        position: absolute;
                        top: 50%;
                    }
                    &.category_region:before {
                        content: "\f041";
                        margin-left: -24px;
                    }
                    &.category_industry:before {
                        content: "\f024";
                        font-size: $fsize-middle;
                        margin-left: -26px;
                    }
                    &.category_attribute:before {
                        content: "\f2bb";
                        margin-left: -26px;
                    }
                    &.category_rating:before {
                        content: "\f006";
                        margin-left: -26px;
                    }
                    &:after {
                        @include sp_link_arrow;
                    }
                }
            }

            /* カテゴリごとのボックス  */
            .recommend_search_box {
                background: $white;
                border-bottom: none;
                font-size: $fsize-small-middle;
                height: 100%;
                left: -100%;
                margin-bottom: 0;
                overflow-y: auto;
                padding-bottom: 60px;
                position: fixed;
                top: 0;
                transition: all .25s ease-in-out 0s;
                width: 100%;
                z-index: 100;
                &.no-fixed {
                    position: static;
                }
                &.is_open {
                    -webkit-transform: translate(100%, 0);
                    -ms-transform: translate(100%, 0);
                    transform: translate(100%, 0);
                }

                /* ボックス見出し  */
                .search_box_title {
                    background: #1c2f8b;
                    color: $white;
                    margin-bottom: 0;
                    padding: 14px 45px 14px 35px;
                    &::before {
                        color: $white;
                        left: 10px;
                        margin-top: -8px;
                        position: absolute;
                        top: 50%;
                    }
                    a {
                        color: $white;
                    }
                    .close_modal_btn {
                        background: $white;
                        border-radius: 14px;
                        display: table !important;
                        height: 28px;
                        margin-top: -14px;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        width: 28px;
                        &::before {
                            background: $blue;
                            content: "";
                            display: block;
                            height: 2px;
                            position: absolute;
                            right: 9px;
                            top: 50%;
                            width: 10px;
                            transform: rotate(45deg);
                        }
                        &::after {
                            background: $blue;
                            content: "";
                            display: block;
                            height: 10px;
                            margin-top: -4px;
                            position: absolute;
                            right: 13px;
                            top: 50%;
                            width: 2px;
                            transform: rotate(45deg);
                        }
                    }
                }

                &.category_study_occupation {
                    border-left: 1px solid $lightgray;
                    border-right: 1px solid $lightgray;
                    border-top: 1px solid $lightgray;
                    margin-top: 0;
                    padding-bottom: 0;
                    .search_link {
                        li {
                            width: 100%;
                        }
                    }
                }

            }

            /* リンク一覧  */
            .sp_search_accordion {
                border-bottom: 1px solid $lightgray;

                > li {
                    display: block;
                    margin-top: -1px;
                    &::after {
                        display: none;
                    }
                    > span {
                        border-top: 1px solid $lightgray;
                        cursor: pointer;
                        display: block;
                        font-weight: bold;
                        min-height: 44px;
                        padding: 14px 30px 14px 10px;
                        position: relative;
                        width: 100%;
                        &::before {
                            background: #444;
                            content: "";
                            display: block;
                            height: 2px;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            width: 10px;
                        }
                        &::after {
                            background: #444;
                            content: "";
                            display: block;
                            height: 10px;
                            margin-top: -4px;
                            position: absolute;
                            right: 14px;
                            top: 50%;
                            width: 2px;
                        }
                        &.is_open::after {
                            display: none;
                        }
                    }
                }
                .search_link {
                    display: none;
                    li:nth-child(odd) a {
                        border-right: 1px solid #E3E3E3;
                    }
                }
            }

            .search_link {
                background: #f5f5f5;
                border-top: 1px solid $lightgray;
                &::after {
                    content: " ";
                    clear: both;
                    display: block;
                }
                li {
                    border-bottom: none;
                    display: table;
                    float: left;
                    position: relative;
                    width: 50%;
                    &::after {
                        display: none;
                    }
                    a {
                        background: $white;
                        border-bottom: 1px solid $lightgray;
                        color: $darkgray;
                        display: table-cell;
                        height: 44px;
                        padding: 0 24px 0 10px;
                        position: relative;
                        vertical-align: middle;
                        width: 100%;
                        &::after {
                            @include sp_link_arrow;
                        }
                    }
                }
            }
            .category_industry .search_link {
                li {
                    width: 50%;
                }
            }
            .category_attribute .search_link {
                li {
                    width: 50%;
                }
            }
            .category_rating .search_link {
                li {
                    width: 100%;
                }
            }
        }
    }

    /* 企業トップ、口コミ詳細に配置 */
    .footer_row_2 {
        .recommend_navi {

            /* モーダルウィンドウを開くメニュー  */
            #sp_modal_menu {
                border-top: 1px solid $lightgray;
                font-size: $fsize-small-middle;
                margin-top: -60px;
                margin-bottom: 15px;
                padding-top: 60px;
                a {
                    background: $white;
                    border: 1px solid $lightgray;
                    color: $gray;
                    display: block;
                    font-weight: bold;
                    margin-top: -1px;
                    padding: 15px 25px 15px 38px;
                    position: relative;
                    width: 100%;
                    &:before {
                        color: $gray;
                        display: block;
                        font-family: "fontawesome";
                        font-size: $fsize-large;
                        position: absolute;
                    }
                    &.category_region:before {
                        content: "\f041";
                        margin-left: -24px;
                    }
                    &.category_industry:before {
                        content: "\f024";
                        font-size: $fsize-middle;
                        margin-left: -26px;
                    }
                    &.category_rating:before {
                        content: "\f006";
                        margin-left: -26px;
                    }
                    &:after {
                        @include sp_link_arrow;
                        color: $gray;
                    }
                }
            }

            /* カテゴリごとのボックス  */
            .recommend_search_box {
                background: $white;
                border-bottom: none;
                font-size: $fsize-small-middle;
                height: 100%;
                left: -100%;
                margin-bottom: 0;
                overflow-y: auto;
                padding-bottom: 60px;
                position: fixed;
                top: 0;
                transition: all .25s ease-in-out 0s;
                width: 100%;
                z-index: 100;
                &.is_open {
                    -webkit-transform: translate(100%, 0);
                    -ms-transform: translate(100%, 0);
                    transform: translate(100%, 0);
                }

                /* ボックス見出し  */
                .search_box_title {
                    background: #1c2f8b;
                    color: $white;
                    display: block;
                    padding: 14px 10px;
                    position: relative;
                    width: auto;
                    &::before {
                        color: $white;
                        font-family: "fontawesome";
                        font-size: $fsize-large;
                        margin-right: 8px;
                    }
                    a {
                        color: $white;
                    }
                    .close_modal_btn {
                        background: $white;
                        border-radius: 14px;
                        display: table !important;
                        height: 28px;
                        margin-top: -14px;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        width: 28px;
                        &::before {
                            background: $blue;
                            content: "";
                            display: block;
                            height: 2px;
                            position: absolute;
                            right: 9px;
                            top: 50%;
                            width: 10px;
                            transform: rotate(45deg);
                        }
                        &::after {
                            background: $blue;
                            content: "";
                            display: block;
                            height: 10px;
                            margin-top: -4px;
                            position: absolute;
                            right: 13px;
                            top: 50%;
                            width: 2px;
                            transform: rotate(45deg);
                        }
                    }
                }
                &.category_region .search_box_title::before {
                    content: "\f041";
                }
                &.category_industry .search_box_title::before {
                    content: "\f024";
                    font-size: $fsize-middle;
                }
                &.category_rating .search_box_title::before {
                    content: "\f006";
                }
                &.category_change .search_box_title::before {
                    content: "\f00d";
                }
            }

            /* リンク一覧  */
            .sp_search_accordion {
                border-bottom: 1px solid $lightgray;
                display: block;
                width: auto;

                > li {
                    display: block;
                    margin-top: -1px;
                    &::after {
                        display: none;
                    }
                    > span {
                        border-top: 1px solid $lightgray;
                        color: $darkgray;
                        cursor: pointer;
                        display: block;
                        font-weight: bold;
                        min-height: 44px;
                        padding: 14px 30px 14px 10px;
                        position: relative;
                        width: auto;
                        &::before {
                            background: #444;
                            content: "";
                            display: block;
                            height: 2px;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            width: 10px;
                        }
                        &::after {
                            background: #444;
                            content: "";
                            display: block;
                            height: 10px;
                            margin-top: -4px;
                            position: absolute;
                            right: 14px;
                            top: 50%;
                            width: 2px;
                        }
                        &.is_open::after {
                            display: none;
                        }
                    }
                }
                .search_link {
                    display: none;
                    li:nth-child(odd) a {
                        border-right: 1px solid #E3E3E3;
                    }
                }
            }

            .search_link {
                display: block;
                letter-spacing: 0;
                background: #f5f5f5;
                border-top: 1px solid $lightgray;
                &::after {
                    content: " ";
                    clear: both;
                    display: block;
                }
                li {
                    border-bottom: none;
                    display: table;
                    font-size: $fsize-small;
                    float: left;
                    letter-spacing: 0;
                    margin-right: 0;
                    position: relative;
                    width: 50%;
                    &::after {
                        display: none;
                    }
                    a {
                        background: $white;
                        border-bottom: 1px solid $lightgray;
                        color: $darkgray;
                        display: table-cell;
                        height: 44px;
                        padding: 0 24px 0 10px;
                        position: relative;
                        vertical-align: middle;
                        width: 100%;
                        &::after {
                            @include sp_link_arrow;
                        }
                    }
                }
            }
            .category_rating .search_link {
                li {
                    width: 100%;
                }
            }
        }
    }
}
