@charset 'UTF-8';

@media (max-width: 640px) {

    /*
    地域、業界、評価・労働環境一覧で共通（2017.7.5）
    ---------------------------------------------------------------------------- */
    .category {
        /* h1見出し */
        h1 {
            background-size: cover !important;
            display: block;
            height: auto;
            margin: 0 -15px 15px;
            width: auto;
            span {
                display: block;
                font-size: $fsize-xxlarge;
                font-weight: bold;
                padding: 20px 20px 20px 50px;
                &::before {
                    font-size: $fsize-xxxxlarge;
                    height: 24px;
                    margin-top: -12px;
                    width: 24px;
                }
            }
        }

        /* リード文 */
        .category_lead {
            background: none;
            margin-bottom: 15px;
            padding: 0;
        }

        /* h2見出し */
        h2 {
            font-size: $fsize-large;
            padding: 13px 10px 10px;
        }
        &.category_region h2 {
            margin-bottom: 1px;
        }
    }

    /*
    地域一覧（2017.7.5）
    ---------------------------------------------------------------------------- */
    .category_region {
        /* 地域から探す */
        .category_region_navi {
            background: #f5f5f5;
            min-height: initial;
            .region_box {
                background: none;
                border: none;
                box-shadow: none;
                height: auto !important;
                left: 0 !important;
                margin-top: -1px;
                padding: 0;
                position: relative;
                top: 0 !important;
                width: auto !important;
                h3 {
                    background: $white;
                    border-top: 1px solid $lightgray;
                    color: $darkgray;
                    cursor: pointer;
                    min-height: 44px;
                    padding: 14px 30px 14px 10px;
                    position: relative;
                    &::before {
                        background: #444;
                        content: "";
                        display: block;
                        height: 2px;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        width: 10px;
                    }
                    &::after {
                        background: #444;
                        content: "";
                        display: block;
                        height: 10px;
                        margin-top: -4px;
                        position: absolute;
                        right: 14px;
                        top: 50%;
                        width: 2px;
                    }
                    &.is_open::after {
                        display: none;
                    }
                }
                &:first-child h3 {
                    border-top: none;
                }
                ul {
                    border-top: 1px solid $lightgray;
                    display: none;
                    width: 100%;
                    &::after {
                        content: " ";
                        clear: both;
                        display: block;
                    }
                    li {
                        display: table;
                        float: left;
                        position: relative;
                        width: 50%;
                        a {
                            background: $white;
                            border-bottom: 1px solid $lightgray;
                            color: $darkgray;
                            display: table-cell;
                            height: 44px;
                            padding: 0 24px 0 10px;
                            position: relative;
                            vertical-align: middle;
                            width: 100%;
                            &::after {
                                @include sp_link_arrow;
                            }
                        }
                        &:nth-child(odd) a {
                            border-right: 1px solid $lightgray;
                        }
                    }
                }
            }
        }
    }
}
