@charset 'UTF-8';

/*
エラーページ 404
---------------------------------------------------------------------------- */

#column {
    li.error_page {
        float: none;
        margin: 0 auto;
        width: 800px;

        //エラーステータス
        .error_intro {
            align-content: center;
            display: flex;
            flex-wrap: wrap;
            height: 180px;
            line-height: 1.5;
            margin: 40px 0 50px;
            position: relative;
            text-align: center;
            h1 {
                flex-basis: 100%;
                font-size: 28px;
                font-weight: bold;
                margin-bottom: 10px;
                position: relative;
                z-index: 2;
            }
            .error_status_eng {
                color: #ebebeb;
                flex-basis: 100%;
                font-family: "Bebas Neue", sans-serif;
                font-size: 40px;
                left: 0;
                line-height: 40px;
                position: absolute;
                text-align: center;
                top: 0;
                width: 100%;
                z-index: 1;
                .error_status_num {
                    display: block;
                    font-size: 180px;
                    line-height: 140px;
                }
            }
            .error_status_message {
                flex-basis: 100%;
                font-size: 12px;
                position: relative;
                z-index: 2;
            }
        }

        h2 {
            line-height: 1.4;
        }

        //テキスト検索
        .error_searchform {
            .error_searchform_tab {
                align-items: center;
                display: flex;
                justify-content: center;
                li {
                    background: $white;
                    border-radius: 4px 4px 0 0;
                    border-style: solid;
                    border-width: 1px 1px 0;
                    cursor: pointer;
                    font-size: 13px;
                    font-weight: bold;
                    margin: 0 5px;
                    padding: 10px 0;
                    text-align: center;
                    width: 180px;
                    .pc {
                        display: inline !important;
                    }
                    &.error_searchform_tab_review {
                        border-color: #26267f;
                        color: #009;
                        &:hover {
                            background: #f0f4fc;
                        }
                        &.is_select {
                            background: #26267f;
                            color: $white;
                        }
                    }
                    &.error_searchform_tab_job {
                        border-color: #cc0000;
                        color: #cc0000;
                        &:hover {
                            background: #fcebeb;
                        }
                        &.is_select {
                            background: #cc0000;
                            color: $white;
                        }
                    }
                    &.error_searchform_tab_biz {
                        border-color: #20660a;
                        color: #20660a;
                        &:hover {
                            background: #eaf7e6;
                        }
                        &.is_select {
                            background: #20660a;
                            color: $white;
                        }
                    }
                }
            }

            .error_searchform_content {
                margin-bottom: 50px;
                li {
                    background: #ececec;
                    border-style: solid;
                    border-width: 3px 0 0;
                    display: none;
                    padding: 30px;
                    &.is_show {
                        display: block;
                    }
                    &.error_searchform_content_review {
                        border-color: #26267f;
                    }
                    &.error_searchform_content_job {
                        border-color: #cc0000;
                        .form_text {
                            width: 300px;
                        }
                    }
                    &.error_searchform_content_biz {
                        border-color: #20660a;
                    }
                    .form_text {
                        height: 48px;
                        margin-right: 10px;
                        width: 630px;
                    }
                    .select_wrap {
                        min-width: 100px;
                        .customSelect {
                            background: $white;
                            box-shadow: 0 1px 0 #ccc;
                            width: 100px;
                            .customSelectInner {
                                color: #2f2f2f;
                            }
                        }
                        &::after {
                            color: #999;
                        }
                    }
                    .btn_l {
                        box-shadow: 0 -1px 0 #1a1a58 inset;
                        font-family: "fontawesome";
                        margin: 0;
                        min-height: 47px;
                        padding: 0;
                    }
                }
            }
        }

        //目的別に探す
        .error_purpose_link {
            margin-bottom: 50px;
            ul {
                display: flex;
                justify-content: space-between;
                li {
                    display: table;
                    flex-basis: 258px;
                    width: 258px;
                    a {
                        background: $white;
                        border-radius: 4px;
                        border-style: solid;
                        border-width: 1px;
                        display: table-cell;
                        font-size: 13px;
                        font-weight: bold;
                        height: 110px;
                        padding: 20px 0;
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;
                        em {
                            display: block;
                            font-size: 20px;
                            margin-top: 15px;
                            &::before {
                                display: inline-block;
                                font-family: "fontello";
                                font-weight: normal;
                                margin-right: 8px;
                            }
                        }
                        &.error_purpose_link_review {
                            border-color: #26267f;
                            color: #009;
                            em::before {
                                content: "\e812";
                            }
                            &:hover {
                                background: #f0f4fc;
                            }
                        }
                        &.error_purpose_link_job {
                            border-color: #cc0000;
                            color: #cc0000;
                            em::before {
                                content: "\e811";
                            }
                            &:hover {
                                background: #fcebeb;
                            }
                        }
                        &.error_purpose_link_biz {
                            border-color: #20660a;
                            color: #20660a;
                            em::before {
                                content: "\e80d";
                            }
                            &:hover {
                                background: #eaf7e6;
                            }
                        }
                    }
                }
            }
        }

    }
}
