@charset 'UTF-8';

/*
おすすめ系コンテンツ
---------------------------------------------------------------------------- */
.inner_wrap.recommend　{
    &::after {
        content: " ";
        clear: both;
        display: block;
    }
    .pan li:first-child span {
        display: inline-block;
        height: 0;
        overflow: hidden;
        width: 0;
    }
}

/* メインカラム  */
.recommend_main {
    float: right;
    margin: 0 0 30px 25px;
    width: 765px;

    /* h1見出し */
    h1 {
        border-bottom: 1px solid $lightgray;
        color: $blue;
        font-size: 28px;
        font-weight: bold;
        line-height: 1.5;
        margin: 0 0 15px;
        padding: 0 0 15px;
        .recommend_t_icon {
            background: $white;
            border: 1px solid #486cb3;
            border-radius: 4px;
            color: #486cb3;
            display: inline-block;
            font-size: 16px;
            margin: 2px 5px 0 0;
            padding: 2px 8px;
            vertical-align: text-top;
        }
    }

    q {
        display: block;
        font-size: 10px;
        color: $textlightgray !important;
    }

    /* リード文 */
    .recommend_lead {
        background: $white;
        font-size: 13px;
        line-height: 1.6;
        margin-bottom: 30px;
        padding: 15px;
        .sp_accordion_toggle {
            display: none;
        }
    }
    .recommend_lead2 {
        font-size: 13px;
        line-height: 1.6;
        margin: 10px 0;
    }

    /* 目次 */
    .recommend_toc {
        background: $white;
        border: 1px solid $lightgray;
        border-radius: 4px;
        .recommend_toc_title {
            border-bottom: 1px solid $lightgray;
            font-size: 13px;
            font-weight: bold;
            padding: 10px 20px;
        }
        ul {
            font-size: 0;
            letter-spacing: 1em;
            width: 100%;
            padding: 20px;
            li {
                border-bottom: 1px solid $lightgray;
                display: inline-block;
                font-size: 13px;
                margin: 0 1% 5px 1%;
                padding: 0 5px 5px 5px;
                width: 48%;
                a {
                    color: $blue2;
                }
            }
        }
    }

    /* 職種研究などの記事系コンテンツ */
    .recommend_h2 {
        border-bottom: 1px solid $lightgray;
        color: $darkgray;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.5;
        margin: 0 0 20px 0;
        padding: 20px 0 10px 0;
    }
    .recommend_content_frame {
        background: $white;
        border-top: 3px solid $blue;
        border-left: 1px solid $lightgray;
        border-right: 1px solid $lightgray;
        border-bottom: 1px solid $lightgray;
        margin: 0 0 30px 0;
        h3 {
            border-bottom: 1px solid $lightgray;
            color: $blue;
            font-size: 18px;
            font-weight: bold;
            padding: 10px 20px;
        }
        .recommend_content_frame_inner {
            font-size: 13px;
            padding: 20px;
            word-break: break-all;
            word-wrap: break-word;
            h4 {
                background: #edeffa;
                color: #486cb3;
                font-size: 16px;
                font-weight: bold;
                margin: 0 0 20px 0;
                padding: 10px;
                small {
                    font-size: 12px;
                    font-weight: normal;
                }
            }
            h5 {
                border-bottom: 1px solid $lightgray;
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 10px;
                padding-bottom: 5px;
            }
            p {
                font-size: 13px;
                line-height: 1.6em;
                padding: 0 0 20px 0;
                &::after {
                    content: "";
                    clear: both;
                    display: block;
                }
                .float_right {
                    float: right;
                    margin-left: 2em;
                    margin-bottom: 1em;
                    width: 270px;
                }
            }
            .ranking_area {
                @include cf;
                width: 100%;
                .ranking_left,
                .ranking_right {
                    width: 50%;
                    li {
                        @include cf;
                        border-bottom: 1px solid $lightgray;
                        display: block;
                        margin: 0 0 5px 0;
                        padding: 0 0 5px 30px;
                        position: relative;
                        &::before {
                            background: $white;
                            color: $darkgray;
                            display: block;
                            border-color: $lightgray;
                            border-style: solid;
                            border-width: 1px;
                            border-radius: 11px;
                            font-size: 12px;
                            font-weight: bold;
                            height: 22px;
                            left: 0;
                            line-height: 22px;
                            margin-top: -11px;
                            position: absolute;
                            text-align: center;
                            top: 40%;
                            width: 22px;
                        }
                        a {
                            color: $blue2;
                            float: left;
                            font-size: 13px;
                            font-weight: normal;
                            line-height: 2em;
                            max-width: 60%;
                        }
                        .star,
                        .income {
                            float: right;
                            display: block;
                            width: 40%;
                        }
                        .star {
                            text-align: right;
                            i {
                                line-height: 1.4em;
                                margin: 0 -3px;
                                vertical-align: top;
                            }
                        }
                        .income {
                            font-size: 12px;
                            text-align: right;
                            strong {
                                font-size: 16px;
                                font-weight: bold;
                                margin: 0 0 0 5px;
                            }
                            b {
                                font-weight: bold;
                            }
                        }
                    }
                }
                .ranking_left {
                    float: left;
                    padding-right: 15px;
                    li {
                        &:first-child {
                            &::before {
                                background: #fcde63;
                                border-color: #e5c339;
                                content: "1";
                            }
                        }
                        &:nth-child(2) {
                            &::before {
                                background: #ddd;
                                border-color: #aaa;
                                content: "2";
                            }
                        }
                        &:nth-child(3) {
                            &::before {
                                background: #eb956a;
                                border-color: #cd7548;
                                content: "3";
                            }
                        }
                        &:nth-child(4) {
                            &::before {
                                content: "4";
                            }
                        }
                        &:nth-child(5) {
                            &::before {
                                content: "5";
                            }
                        }
                    }

                }
                .ranking_right {
                    float: right;
                    padding-left: 15px;
                    li {
                        &:first-child {
                            &::before {
                                content: "6";
                            }
                        }
                        &:nth-child(2) {
                            &::before {
                                content: "7";
                            }
                        }
                        &:nth-child(3) {
                            &::before {
                                content: "8";
                            }
                        }
                        &:nth-child(4) {
                            &::before {
                                content: "9";
                            }
                        }
                        &:nth-child(5) {
                            &::before {
                                content: "10";
                            }
                        }
                    }
                }

            }
            .square_balloon_area {
                margin: 0 0 30px 0;
                text-align: center;
                .left_box {
                    border: 1px solid #f1ddb5;
                    border-radius: 4px;
                    display: inline-block;
                    width: 33%;
                    .left_box_title {
                        color: #fa0;
                        background: #fffbd9;
                        border-bottom: 1px solid #f1ddb5;
                        font-size: 14px;
                        font-weight: bold;
                        padding: 10px 20px;
                    }
                    .left_box_content {
                        padding: 10px 20px;
                        .left_box_content_left {
                            display: inline-block;
                            font-size: 18px;
                            font-weight: bold;
                            strong {
                                font-size: 28px;
                            }
                        }
                        .left_box_content_right {
                            display: inline-block;
                            font-size: 10px;
                            line-height: 1.3em;
                            margin: 0 0 0 10px;
                            text-align: left;
                            .good,
                            .bad {
                                font-weight: bold;
                                strong {
                                    font-size: 14px;
                                }
                            }
                            .good {
                                color: #e61739;
                            }
                            .bad {
                                color: #5d94cd;
                            }
                        }
                    }
                }
                .cross {
                    &::before {
                        @include font-cc($font-cc-cross);
                        color: #999;
                        display: inline-block;
                        font-size: 36px;
                        line-height: 3.5em;
                        vertical-align: top;
                    }
                }
                .right_box {
                    border: 1px solid #cfdae5;
                    border-radius: 4px;
                    display: inline-block;
                    width: 33%;
                    .right_box_title {
                        color: #5d94cd;
                        background: #edf4fa;
                        border-bottom: 1px solid #cfdae5;
                        font-size: 14px;
                        font-weight: bold;
                        padding: 10px 20px;
                    }
                    .right_box_content {
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 1.6em;
                        padding: 10px 20px;
                        strong {
                            font-size: 28px;
                        }
                    }
                }
                .star_ver {
                    position: relative;
                    &::after {
                        background: $white;
                        border-style: solid;
                        border-width: 1px 1px 0 0;
                        content: "";
                        display: block;
                        height: 12px;
                        left: 50%;
                        margin-left: -4px;
                        position: absolute;
                        bottom: -6px;
                        transform: rotate(135deg);
                        width: 12px;
                    }
                    &.yellow {
                        &::after {
                            border-color: #f1ddb5;
                        }
                    }
                    &.blue {
                        &::after {
                            border-color: #cfdae5;
                        }
                    }
                    .star {
                        i {
                            &::before {
                                font-size: 27px;
                            }
                        }
                        span {
                            color: #e61739 !important;
                            font-size: 20px !important;
                            font-weight: bold !important;
                            line-height: 1.3em;
                            margin: 0 0 0 10px;
                            vertical-align: top;
                        }
                    }

                }
            }
            .chart_area {
                margin: 0 0 20px 0;
            }
        }
        .recommend_content_frame_inner_kutikomi {
            font-size: 13px;
            position: relative;
            width: 100%;
            height: auto;
            margin-bottom: 15px;
            padding: 20px 15px 5px 15px;
            background: #FFF;
            border-bottom: 1px solid #E3E3E3;
            box-shadow: 0 0px 0 #E3E3E3;
            border-radius: 1px;
            word-break: break-all;
            word-wrap: break-word;
            .comment_theme {
                color: $white;
                position: absolute;
                top: 20px;
                right: -10px;
                display: inline-block;
                font-size: 11px;
                width: auto;
                padding: 0 10px;
                height: 20px;
                line-height: 20px;
                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: -10px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10px 10px 0 0;
                }
            }
            .company_link {
                font-size: 14px;
                font-weight: bold;
                line-height: 3em;
            }
            .comment_info_list {
                @include cf;
                dt {
                    float: left;
                    margin-right: 5px;
                    border-radius:3px;
                    font-size: 13px;
                    font-weight: bold;
                    color: $gray !important;
                }
                dd {
                    float: left;
                    margin-right: 20px;
                    font-size: 12px;
                    max-width:310px;
                    @include text-ellipsis;
                }
            }
            .comment_text {
                margin: 0 0 10px 0;
            }
            .comment_more_frame {
                margin: 0 0 10px 0;
                text-align: right;
                width: 100%;
                .comment_more {
                    color: $blue2;
                }
            }
            &.comment_theme_gold { //年収・給与
                border-top: 2px solid #F7BF30;
                .comment_theme {
                    background: #F7BF30;
                    text-shadow: 1px 1px 1px darken(#F7BF30, 15%);
                    &::after {
                        border-color: darken(#F7BF30, 15%) transparent transparent transparent;
                    }
                }
                .company_link {
                    color: #F7BF30;
                }
            }
            &.comment_theme_lightgreen { //働く環境
                border-top: 2px solid $lightgreen;
                .comment_theme {
                    background: $lightgreen;
                    text-shadow: 1px 1px 1px darken($lightgreen, 15%);
                    &::after {
                        border-color: darken($lightgreen, 15%) transparent transparent transparent;
                    }
                }
                .company_link {
                    color: $lightgreen;
                }
            }
            &.comment_theme_lightpurple { //やりがい
                border-top: 2px solid $lightpurple;
                .comment_theme {
                    background: $lightpurple;
                    text-shadow: 1px 1px 1px darken($lightpurple, 15%);
                    &::after {
                        border-color: darken($lightpurple, 15%) transparent transparent transparent;
                    }
                }
                .company_link {
                    color: $lightpurple;
                }
            }
            &.comment_theme_lightorange { //出世
                border-top: 2px solid $lightorange;
                .comment_theme {
                    background: $lightorange;
                    text-shadow: 1px 1px 1px darken($lightorange, 15%);
                    &::after {
                        border-color: darken($lightorange, 15%) transparent transparent transparent;
                    }
                }
                .company_link {
                    color: $lightorange;
                }
            }
            &.comment_theme_lightred { //ライバル企業
                border-top: 2px solid $lightred;
                .comment_theme {
                    background: $lightred;
                    text-shadow: 1px 1px 1px darken($lightred, 15%);
                    &::after {
                        border-color: darken($lightred, 15%) transparent transparent transparent;
                    }
                }
                .company_link {
                    color: $lightred;
                }
            }
            &.comment_theme_black { //残業・休日出勤
                border-top: 2px solid $lightblack;
                .comment_theme {
                    background: $lightblack;
                    text-shadow: 1px 1px 1px darken($lightblack, 15%);
                    &::after {
                        border-color: darken($lightblack, 15%) transparent transparent transparent;
                    }
                }
                .company_link {
                    color: $lightblack;
                }
            }
            &.comment_theme_lightyellow { //長所・短所
                border-top: 2px solid $lightyellow;
                .comment_theme {
                    background: $lightyellow;
                    text-shadow: 1px 1px 1px darken($lightyellow, 15%);
                    &::after {
                        border-color: darken($lightyellow, 15%) transparent transparent transparent;
                    }
                }
                .company_link {
                    color: $lightyellow;
                }
            }
            &.comment_theme_darkblue { //退職理由
                border-top: 2px solid $darkblue;
                .comment_theme {
                    background: $darkblue;
                    text-shadow: 1px 1px 1px darken($darkblue, 15%);
                    &::after {
                        border-color: darken($darkblue, 15%) transparent transparent transparent;
                    }
                }
                .company_link {
                    color: $darkblue;
                }
            }
            &.comment_theme_skyblue { //転職後のギャップ
                border-top: 2px solid $skyblue;
                .comment_theme {
                    background: $skyblue;
                    text-shadow: 1px 1px 1px darken($skyblue, 15%);
                    &::after {
                        border-color: darken($skyblue, 15%) transparent transparent transparent;
                    }
                }
                .company_link {
                    color: $skyblue;
                }
            }
            &.comment_theme_lightpink { //女性のキャリア
                border-top: 2px solid $lightpink;
                .comment_theme {
                    background: $lightpink;
                    text-shadow: 1px 1px 1px darken($lightpink, 15%);
                    &::after {
                        border-color: darken($lightpink, 15%) transparent transparent transparent;
                    }
                }
                .company_link {
                    color: $lightpink;
                }
            }
            &.comment_theme_lightblue { //経営者について
                border-top: 2px solid $lightblue;
                .comment_theme {
                    background: $lightblue;
                    text-shadow: 1px 1px 1px darken($lightblue, 15%);
                    &::after {
                        border-color: darken($lightblue, 15%) transparent transparent transparent;
                    }
                }
                .company_link {
                    color: $lightblue;
                }
            }
        }
        .link_btn_big {
            background: $blue;
            border-radius: 4px;
            color: $white;
            display: block;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.6em;
            margin: 20px auto;
            padding: 20px 40px;
            position: relative;
            text-align: center;
            width: 60%;
            &::after {
                @include sp-link-arrow-after;
                color: $white;
                font-size: 20px;
            }
        }
    }
    .link_text_big_frame {
        margin: 0 0 30px 0;
        text-align: center;
        width: 100%;
        .link_text_big {
            color: $blue2;
            font-size: 16px;
            text-decoration: underline;
        }
    }


    /* 投稿者属性のみの注意書き */
    .recommend_attribute_attention {
        font-size: $fsize-small;
        margin-bottom: 12px;
    }

    /* ソート切り替えのプルダウン */
    .select_wrap_outer {
        margin-bottom: 10px;
        &::after {
            content: " ";
            clear: both;
            display: block;
        }
        .select_wrap {
            float: right;
            height: 26px;
            margin-right: 0;
            position: relative;
            &::after {
                color: #000082;
                content: "\e800";
                display: block;
                font-size: $fsize-xxsmall;
                font-family: "fontello";
                height: 10px;
                left: 0px;
                line-height: 10px;
                margin-top: -5px;
                position: absolute;
                text-align: center;
                top: 50%;
                width: 10px;
                z-index: 1;
            }
            .custom_select {
                background: none;
                border: none;
                border-radius: 0;
                box-shadow: none;
                height: 26px;
                padding: 0 0 0 14px;
                width: 80px;
            }
            .customSelectInner {
                color: #000082;
                font-size: $fsize-small;
                letter-spacing: 0px;
                line-height: 26px;
                padding: 0;
                text-align: left;
            }
        }
    }

    /* 企業一覧 */
    .recommend_list {
        > li {
            background: $white;
            border-color: $lightgray;
            border-style: solid;
            border-width: 0 1px 1px;
            display: block;
            line-height: 1.5;
            margin-bottom: 15px;
            padding: 0 15px 15px;
            width: 100%;

            /* 企業名 */
            .recommend_list_title_rank {
                margin: 0 -15px;
                padding: 15px;
                position: relative;
                &::before {
                    background: $blue;
                    content: "";
                    display: block;
                    height: 3px;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
                .button-follow {
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100px;
                    padding: 4px 4px 4px 28px;
                    font-size: $fs--xsmall;
                    line-height: 1.5;
                }
                .button-follow__icon {
                    width: 20px;
                    height: 20px;
                    font-size: 20px;
                }
                .button-follow__tooltip {
                    top: 40px;
                }
            }
            h2 {
                display: flex;
                align-items: center;
                position: relative;
                padding: 0 120px 0 0;
                font-size: $fsize-xlarge;
                font-weight: bold;
                a {
                    color: $blue;
                }
                .recommend_list_title_company {
                    flex: 1;
                }
                .recommend_list_title_rank_no1,
                .recommend_list_title_rank_no2,
                .recommend_list_title_rank_no3 {
                    border-style: solid;
                    border-width: 1px;
                    border-radius: 11px;
                    font-size: 12px;
                    height: 22px;
                    line-height: 22px;
                    margin-right: 10px;
                    text-align: center;
                    width: 22px;
                }
                .recommend_list_title_rank_no1 {
                    background: #fcde63;
                    border-color: #e5c339;
                }
                .recommend_list_title_rank_no2 {
                    background: #ddd;
                    border-color: #aaa;
                }
                .recommend_list_title_rank_no3 {
                    background: #eb956a;
                    border-color: #cd7548;
                }
            }

            /* 評価と件数表示 */
            .recommend_list_detail {
                display: table;
                padding-bottom: 7px;
                &::after {
                    content:" ";
                    display:block;
                    clear:both;
                }
                .rating_data {
                    display: table-cell;
                    vertical-align: middle;
                    width: 213px;
                    .primary_data {
                        color: #ffae00;
                        font-size: $fsize-large;
                        .rating_data_title {
                            color: $darkgray;
                            display: block;
                            font-size: $fsize-small;
                            line-height: 1.2;
                        }
                        i {
                            font-size: $fsize-xxlarge !important;
                            height: 20px !important;
                            margin-right: -4px !important;
                            width: 20px !important;
                            &::before {
                                color: #ffae00;
                                font-size: $fsize-xxlarge;
                            }
                            + .rating_data_value {
                                margin-left: 8px;
                            }
                        }
                        .rating_data_value {
                            color: #ffae00;
                            font-size: $fsize-xxlarge;
                            font-weight: bold;
                        }
                        p{
                            background: #ccc;
                        }
                        a{
                            display: block;
                            color: #0000b3;
                            font-size: $fsize-xsmall;
                            margin-top: 6px;
                            &:before{
                                content: "";
                                display: inline-block;
                                margin-right: 5px;
                                font-family: fontello;
                                font-weight: normal;
                                transition: all 0.25s ease-in-out 0s;
                            }
                            &:hover{
                                &:before {
                                    transform: translate(3px, 0);
                                }
                            }
                        }
                    }
                    .secondary_data {
                        color: #888;
                        i {
                            font-size: $fsize-small !important;
                            height: 12px !important;
                            margin-right: 0 !important;
                            width: 12px !important;
                        }
                    }
                }
                .review_data {
                    display: table;
                    float: right;
                    table-layout: fixed;
                    width: 520px;
                    li {
                        border-left: 1px solid $lightgray;
                        display: table-cell;
                        height: 60px;
                        text-align: center;
                        vertical-align: bottom;
                        a {
                            color: $darkgray;
                            display: block;
                            padding: 10px 0;
                            width: 100%;
                        }
                        .review_data_title {
                            display: block;
                            line-height: 1.2;
                        }
                        .review_data_value {
                            color: $blue;
                            font-size: $fsize-large;
                            font-weight: bold;
                            &::after {
                                content: " ";
                            }
                        }
                    }
                }
            }

            /* 企業概要 */
            .company_outline {
                border-top: 1px solid $lightgray;
                padding-top: 12px;
                .company_industry {
                    display: inline-block;
                    margin-right: 20px;
                    padding-left: 18px;
                    position: relative;
                    &::before {
                        @include font-awesome ("\f024", #7e7cd9, 0 0 0 -16px, 12px);
                        display: block;
                        position: absolute;
                    }
                }
                .company_address {
                    display: inline-block;
                    padding-left: 18px;
                    position: relative;
                    &::before {
                        @include font-awesome ("\f041", #7e7cd9, 0 2px 0 -14px, 14px);
                        display: block;
                        position: absolute;
                        top: -2px;
                    }
                }
                .company_review {
                    margin-top: 10px;
                    word-wrap: break-word;
                    word-break: break-all;

                    .company_review_title {
                        border-radius: 3px;
                        color: #fff;
                        display: table;
                        margin-bottom: 5px;
                        padding: 2px 3px;
                    }
                    .comment_theme_gold { //年収・給与
                        background: #F7BF30;
                    }
                    .comment_theme_lightgreen { //働く環境
                        background: #85DC84;
                    }
                    .comment_theme_lightpurple { //やりがい
                        background: #7E7CD9;
                    }
                    .comment_theme_lightorange { //出世
                        background: #FFAE00;
                    }
                    .comment_theme_lightred { //ライバル企業
                        background: #EF9E81;
                    }
                    .comment_theme_black { //残業・休日出勤
                        background: #606060;
                    }
                    .comment_theme_lightyellow { //長所・短所
                        background: #f0ce09;
                    }
                    .comment_theme_darkblue { //退職理由
                        background: #000082;
                    }
                    .comment_theme_skyblue { //転職後のギャップ
                        background: #18AAEB;
                    }
                    .comment_theme_lightpink { //女性の働きやすさ
                        background: #ea9396;
                    }
                    .comment_theme_lightblue { //経営者について
                        background: #88ABDA;
                    }
                    a {
                        color: $blue2;
                    }
                }
            }
        }
    }

}


/* 左サイドの検索カラム  */
.recommend {
    .recommend_navi {
        float: left;
        margin-bottom: 30px;
        width: 300px;

        /* 絞り込み検索  */
        .recommend_search_box {
            font-size: $fsize-small-middle;
            margin-bottom: 15px;

            /* 絞り込み検索 ボックス見出し  */
            .search_box_title {
                background: #1c2f8b;
                color: $white;
                font-size: $fsize-middle;
                font-weight: bold;
                padding: 8px 10px;
                position: relative;
                &::before {
                    color: $white;
                    font-family: "fontawesome";
                    font-size: $fsize-large;
                    font-weight: normal;
                    margin-right: 8px;
                }
                &.category_region::before {
                    content: "\f041";
                }
                &.category_industry::before {
                    content: "\f024";
                    font-size: $fsize-middle;
                }
                &.category_rating::before {
                    content: "\f006";
                }
                &.category_attribute::before {
                    content: "\f2bb";
                }
                &.category_change::before {
                    content: "\f00d";
                }
            }

            /* 絞り込み検索 リンク一覧  */
            .search_link {
                background: $white;
                border-color: $lightgray;
                border-style: solid;
                border-width: 0 1px 1px;
                letter-spacing: -.4em;
                padding: 10px 10px 5px;
                li {
                    display: inline-block;
                    letter-spacing: normal;
                    margin: 0 5px 5px 0;
                    &.selected {
                        background: #ffe680;
                        border: 1px solid #ffe680;
                        font-weight: bold;
                        padding: 4px 6px;
                    }
                    &.noresult {
                        border: 1px solid #eee;
                        color: #ccc;
                        padding: 4px 6px;
                    }
                    a {
                        border: 1px solid #c2daf2;
                        color: $blue2;
                        display: block;
                        padding: 4px 6px;
                        &:hover {
                            background: #72b9ff;
                            border: 1px solid #72b9ff;
                            color: $white;
                            text-decoration: none;
                        }
                        &.change_link {
                            background: #ffe680;
                            border: 1px solid #ffe680;
                            color: $darkgray;
                            font-weight: bold;
                            &:hover {
                                background: #fff5b3;
                                border: 1px solid #fff5b3;
                                color: $darkgray;
                            }
                            &::after {
                                content: "×";
                                font-size: $fsize-large;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }

            /* 絞り込み検索 アコーディオン  */
            .search_accordion {
                background: $white;
                border-color: $lightgray;
                border-style: solid;
                border-width: 0 1px 1px;
                li {
                    span {
                        border-top: 1px solid $lightgray;
                        cursor: pointer;
                        display: block;
                        font-weight: bold;
                        padding: 8px 30px 8px 10px;
                        position: relative;
                        width: 100%;
                        &::before {
                            background: #444;
                            content: "";
                            display: block;
                            height: 2px;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            width: 10px;
                        }
                        &::after {
                            background: #444;
                            content: "";
                            display: block;
                            height: 10px;
                            margin-top: -4px;
                            position: absolute;
                            right: 14px;
                            top: 50%;
                            width: 2px;
                        }
                        &.is_open::after {
                            display: none;
                        }
                        &:hover {
                            color: #999;
                        }
                        &:hover::before {
                            background: #999;
                        }
                        &:hover::after {
                            background: #999;
                        }
                    }
                    &:first-child > span {
                        border-top: none;
                    }
                    .search_link {
                        border: none;
                        display: none;
                        padding: 5px 10px;
                    }
                    .occupation_link {
                        border: none;
                        display: none;
                        li {
                            border-bottom: 1px solid $lightgray;
                            display: block;
                            line-height: 1.5;
                            margin: 0 3%;
                            position: relative;
                            width: 94%;
                            &::after {
                                border-color: transparent transparent transparent $blue2;
                                border-style: solid;
                                border-width: 4px 0 4px 5px;
                                content: "";
                                display: block;
                                height: 0;
                                left: 5px;
                                margin-top: -5px;
                                position: absolute;
                                top: 50%;
                                width: 0;
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                            a {
                                color: $blue2;
                                display: block;
                                height: 40px;
                                line-height: 40px;
                                padding: 0 5px 0 16px;
                                width: 100%;
                                &:hover {
                                    background: #f0f6fc;
                                }
                            }
                            &.selected {
                                border-bottom: 1px solid $lightgray;
                                display: block;
                                &::after {
                                    display: none;
                                }
                                div {
                                    background: #fff7b2;
                                    color: #fa0;
                                    padding: 5px;
                                }
                                a {
                                    border-top: 1px solid $lightgray;
                                    display: block;
                                    margin: 5px 0 0 0;
                                    padding: 0 0 0 30px;
                                    position: relative;
                                    &::before {
                                        content: "└";
                                        display: block;
                                        position: absolute;
                                        left: 10px;
                                        top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /* recommend_links_box  */
        .recommend_links_box {
            background: $white;
            border-color: $lightgray;
            border-style: solid;
            border-width: 0 1px 1px;
            font-size: $fsize-small-middle;
            margin-bottom: 15px;
            padding: 0 10px;

            .links_box_title {
                border-bottom: 1px solid $lightgray;
                color: $blue;
                font-size: $fsize-middle;
                font-weight: bold;
                margin: 0 -10px;
                padding: 10px 10px 7px;
                position: relative;
                &::after {
                    background: $blue;
                    content: "";
                    display: block;
                    height: 3px;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
            }

            li {
                border-top: 1px solid $lightgray;
                &:first-child {
                    border-top: none;
                }
                a {
                    display: block;
                    padding: 12px 0;
                    text-decoration: none;
                    width: 100%;
                    .links_title {
                        color: $darkgray;
                        font-weight: bold;
                    }
                    .links_text {
                        color: #7c7c7c;
                        font-size: $fsize-small;
                        word-break: break-all;
                        word-wrap: break-word;
                    }
                    .links_award_title {
                        color: $darkgray;
                        font-weight: normal;
                    }
                    &:hover .links_title,
                    &:hover .links_award_title {
                        text-decoration: underline;
                    }
                }
            }

            .links_more_link {
                border-top: 1px solid $lightgray;
                padding: 10px 0;
                text-align: right;
                &::before {
                    @include font-awesome ("\f138", $blue, 0 5px 0 0, 12px);
                }
                a {
                    color: $blue;
                }
            }

        }
    }
}
