@charset 'UTF-8';

// 口コミリストなどに出すCSR情報
.sub_page #column>li.column_2 h3,
.sub_page #column>li.column_2 .sec_ttl {
    &.csr-info-area__contents-title {
        border: 0;
        margin: 0;
        padding: 0;
    }
}

.adp-review-corpseq__disp {

    // アコーディオン開閉
    .js-accordion-open-close {
        border: 1px solid $border--gray;
        font-size: $fs--medium;
        font-weight: bold;
        line-height: 1.6;
        padding: 10px;
        position: relative;
        &::after {
            font-size: $fs--xsmall;
            font-weight: normal;
            height: 12px;
            line-height: 0.25;
            margin-top: 0;
            position: absolute;
            right: 0;
            top: 50%;
            width: 16px;
        }
        &:hover {
            cursor: pointer;
        }
        strong {
            font-size: $fs--xlarge;
            font-weight: 700;
        }
    }
    .js-accordion-open-close.is_close {
        border-bottom: 1px solid $border--gray;
        margin: 30px 0 10px 0;
        &::after {
            font-family: FontAwesome;
            margin: 0 0.4em 0.1em 0.2em;
            content: $fa-plus;
        }
    }
    .js-accordion-open-close.is_open {
        border-bottom: none;
        margin: 30px 0 0 0;
        &::after {
            font-family: FontAwesome;
            margin: 0 0.4em 0.1em 0.2em;
            content: $fa-minus;
        }
    }
    .js-accordion-open-close__inner {
        margin: 0 0 30px 0;
    }

    // テキスト情報
    .csr-info-area {
        background: $white;
        border: 1px solid $border--gray;
        line-height: 1.6;
        padding: 10px;

        .js-readmore-btn {
            color: $font--link--blue;
            display: inline;
            font-size: $fs--xsmall;
            margin: 0 0 10px 0;
            &:link, &:hover, &:active, &:visited {
                color: $font--link--blue;
            }
            &:hover {
                cursor: pointer;
            }
        }

        .csr-info-area__title {
            border-bottom: 1px solid $border--gray;
            font-size: $fs--medium;
            font-weight: bold;
            padding: 0 0 5px 0;
        }

        .csr-info-area__contents {
            font-size: $fs--xsmall;
            margin: 20px 0 0 0;
            mask-image: rgba(0,0,0,1);
        	mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 75%,rgba(0,0,0,0) 100%);
            &.js-readmore-area_opened {
                mask-image: rgba(0,0,0,1);
            	mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 75%,rgba(0,0,0,1) 100%);
            }
            p {
                word-wrap: break-word;
                word-break: break-all;
                &.mb10 {
                    margin-bottom: 10px;
                }
                &.mt10 {
                    margin-top: 10px;
                }
            }
            strong {
                color: $font--blue--review;
                display: block;
                font-weight: normal;
            }
            .separate1 {
                display: inline-block;
            }
            .separate2 {
                display: inline-block;
                &::before {
                    content: " ／ ";
                }
            }
            .separate3 {
                display: inline-block;
                margin: 0 0 10px 0;
                &::before {
                    content: " ／ ";
                }
            }
            .block1 {
                display: block;
            }
            .block2 {
                display: block;
                margin: 0 0 10px 0;
            }
        }

        .csr-info-area__contents-title {
            color: $font--blue--review;
            font-size: $fs--xsmall;
            font-weight: normal;
            margin: 10px 0 0 0;
        }

        .csr-info-area__contents-attention {
            color: $font--gray--light;
            font-size: $fs--xxsmall;
            line-height: 1.6;
            margin: 10px 5px 10px 0;
            padding-bottom: 10px;
        }

    }

    // 縦棒グラフ
    .vertical-graph-area {
        background: $white;
    }
    .vertical-graph-area__box {
        border: 1px solid $border--gray;
        padding: 10px;
    }
    .vertical-graph-area__title {
        font-size: $fs--xsmall;
        margin: 30px 0 0 0;
        text-align: center;
        width: 100%;
    }
    .vertical-graph-area__bar-chart {

    }
    .canvas-bar-graph__value {
        //width: 285px;
    }
    .canvas-bar-graph__value-table {
        margin: 10px 0 0 10%;
        width: 90%;
        th, td {
            padding: 0;
            text-align: center;
            vertical-align: middle;
            width: 50%;
        }
        th {
            font-size: $fs--xsmall;
            font-weight: normal;
            line-height: 1.6;
        }
        td {
            font-size: $fs--xlarge;
            font-weight: bold;
            line-height: 1;
            small {
                font-size: 12px;
            }
        }
    }
    .canvas-bar-graph__value-cc {
        color: $yellow;
    }
    .canvas-bar-graph__value-csr {
        color: $blue;
    }
    .vertical-graph-area__attention {
        color: $font--gray--light;
        font-size: $fs--xxxsmall;
        line-height: 1.6;
        margin: 20px 0 0 0;
        text-align: right;
    }
    .canvas-bar-graph__value-company-income {
        color: $yellow;
    }

}


.sub_page #column>li.column_2 .adp-graph-area {
    //height: 160px;
    //width: 320px;
    &::after {
        content: "";
        display: block;
        clear: both;
    }
    h3 {
        font-size: $fsize-small;
    }
    p {
        &:first-child {
            color: $yellow;
            span {
                background: $yellow;
                &:before, &:after {
                    background: $yellow;
                }
            }
        }
        &:nth-child(2) {
            color: $blue !important;
            span {
                background: $blue;
                &:before, &:after {
                    background: $blue;
                }
            }
        }

    }
    .column_outbox {
        border-top: none;
    }
}


/*
#12768 & #13220【キャリコネ】【Markeship】h1タグの修正【施策ID:1-4 & 施策ID:27-1】
---------------------------------------------------------------------------- */

.review {
    .review_intro {
        background: $white;
        .hgroup {
            h2 {
                height: 160px;
                font-size: 44px;
                color: #FFF;
                text-align: center;
                line-height: 55px;
                padding: 25px 0;
                text-shadow: 3px 3px 0 rgba(0,0,0,0.1);
            }
        }
    }
}

.review.sub_page {
    #column>li.column_2 {
        .column_outbox {
            .sec_ttl {
                color: #009;
            }
        }
    }
}

.sub_page {
    #column>li.column_2 {
        .column_outbox {
            .sec_ttl {
                font-size: 14px;
                font-weight: bold;
                text-align: left;
                letter-spacing: 0.5px;
                height: auto;
                line-height: 1.75em;
                padding-top: 15px;
                padding-bottom: 15px;
                overflow: visible;
                border-bottom: 1px solid #e3e3e3;
                z-index: 0;
            }
        }
    }
}

.button_box {
    .area_title {
        width: 460px;
        float: left;
        p {
            padding-bottom: 1px;
        }
        .area_title_text {
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 2px;
            line-height: 14px;
            height: 28px;
            overflow: hidden;
        }
    }
}

.agent {
    .agent-heading {
        padding: 10px 6px 7px;
        margin-bottom: 1px;
        color: #000;
        text-align: center;
        font-size: 14px;
        letter-spacing: 0;
    }
}

.footer_column_1704 {
    h3 {
        margin: 4px 0 8px 0;
        line-height: 1.4;
    }
}

.sub_page {
    #column>li.column_3 {
        .side_ttl, .sub_page #column>li.column_3 h3 {
            height: auto;
            line-height: 1.75em;
            padding: 10px 0;
        }
    }
}


// 各種口コミ投稿者情報共通

a.review-cassette-company,
a.review-cassette-company2 {
    display: block;
    font-size: $fsize-small-middle;
    font-weight: bold;
    color: #3f93c5;
    padding: 0 0 0 16px;
    text-decoration: underline;
    &:link,&:hover,&:active,&:visited {
        color: #3f93c5;
    }
}
div.review-cassette-company,
div.review-cassette-company2 {
    display: block;
    font-size: $fsize-small-middle;
    font-weight: bold;
    padding: 0 0 0 16px;
}
ul.reviewer-info-common1,
ul.reviewer-info-common2,
ul.reviewer-info-sub {
    width: 99%;
    margin: 0;
    padding: 0 0 0 16px;
    position: relative;

    li {
        display: inline-block;
        //color: #949494;
        font-size: $fsize-small-middle;
        font-weight: bold;

        a {
            color: #3f93c5;
            text-decoration: underline;
            &:link,&:hover,&:active,&:visited {
                color: #3f93c5;
                text-decoration: underline;
            }
        }

        &:before,
        &:after {
            display: inline-block;
        }

        &:first-child {
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                margin: 0;
                padding: 0;
                @include font-awesome($fa-user, $darkgray, 0 5px 0 0, $fsize-small);
            }
        }

        &:after {
            content: "、";
        }

        &:last-child {
            &:after {
                content: "";
            }

        }

    }

}

ul.reviewer-info-common1 {
    border-bottom: 1px solid $lightgray;
    margin: 0 0 10px 0;
    padding: 0 10% 10px 16px;
    .star {
        display: block;
        font-size: 0;
        height: 18px;
        i {
            margin-right: 1px;
            &:last-of-type {
                margin-right: 5px;
            }
        }
        span {
            color: #88ABDA;
            font-size: 15px !important;
        }
    }
}

ul.reviewer-info-common2 {
    margin: 0 0 6px 0;
    .star {
        display: block;
        font-size: 0;
        height: 18px;
        i {
            margin-right: 1px;
            &:last-of-type {
                margin-right: 5px;
            }
        }
        span {
            color: #88ABDA;
            font-size: 15px !important;
        }
    }
}

ul.reviewer-info-sub {
    margin: 0 0 10px 16px;
    padding: 0;

    li {
        font-size: $fsize-small;
        font-weight: normal;
        &:first-child {
            &:before {
                content: "";
            }
        }
    }

}

.com_comment_star {
    margin-bottom: 10px;
}

.com_comment_star,
.sgd_list_star {
    display: block;
    font-size: 0;
    height: 18px;
    i {
        margin-right: 1px;
        &:last-of-type {
            margin-right: 5px;
        }
    }
    span {
        color: #88ABDA;
        font-size: 15px !important;
    }
}

//ブラックホワイト・口コミサイト評価リンク
.whiteblack_compare_linkbox {
    border-bottom: none!important;
    clear: both;
    display: flex;
    justify-content: flex-end;
    padding: 0 0 4px 0;
    li{
        position: relative;
        &:nth-of-type(2) {
            margin-left: 20px;
            &:after{
                background: #ccc;
                content: "";
                display: inline-block;
                height: 18px;
                position: absolute;
                left: -10px;
                width: 1px;
            }
        }
        a{
            &:visited{
                color: $font--link--skyblue;
            }
        }
    }
}

.consultant_agent_all_img .consultant_agent_all .consultant_agent_box .companyname_logo_box .logo.doda img {
    margin: 0 0 0 8px;
    width: 80px;
}


@media (max-width: 640px) {

    .consultant_agent_all_img .consultant_agent_all .consultant_agent_box .companyname_logo_box .logo.doda img,
    .consultant_agent_all_img .consultant_agent_all .consultant_agent_box .companyname_logo_box .logo.pasona img,
    .consultant_agent_all_img .consultant_agent_all .consultant_agent_box .companyname_logo_box .logo.mynavi img,
    .consultant_agent_all_img .consultant_agent_all .consultant_agent_box .companyname_logo_box .logo.recruit img {
        height: 20px;
        margin: 3px 0 0 3px;
        width: auto;
    }

}
