@charset 'UTF-8';

@media (max-width: 640px) {
    .sub_page #column>li .column_2.review_sub.review_job.wide {
        margin-bottom: 30px;
        //求人詳細 - ヘッダー
        .job_header {
            margin-bottom: 10px;
            padding-top: 15px;
            .job_title {
                padding: 0;
            }
            .job_lead {
                font-size: 14px;
            }
        }
        .job_header_inner {
            order: 1;
        }
        .job_header_main {
            margin-bottom: 5px;
        }
        .job_header_sub {
            align-items: flex-start;
            flex-direction: column;
            margin: 0;
            .tag_group {
                margin-top: 5px;
            }
        }
        .job_header_company {
            font-size: 12px;
        }
        .job_header_logo {
            width: 50px;
            height: 50px;
            margin-right: 10px;
        }
        .job_header_meta {
            justify-content: space-between;
            order: 3;
            margin: 10px 0 0;
        }
        .job_header_update {
            flex-direction: column;
            font-size: 10px;
            li + li {
                margin-left: 0;
            }
        }
        .job_header_alliance {
            margin-left: 0;
            font-size: 10px;
            img {
                width: 80px;
            }
        }
        .job_header_photo {
            order: 4;
            margin-top: 10px;
            img {
                width: 100%;
            }
        }

        //求人詳細 - コンテンツ
        .content_title {
            display: none;
        }
        .jobinfo_table {
            border-bottom: none;
            tr:last-of-type {
                th, td {
                    border-bottom-style: none;
                }
            }
        }

        //求人詳細 - タブメニュー
        .tabs {
            display: flex;
            li {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                width: auto;
                float: none;
                padding: 5px 0;
                min-height: 40px;
            }
        }

        //求人詳細 - 会社紹介（スカウト求人のみ）
        .jobinfo_introduction {
            padding: 12px 12px 20px;
            border: none;
        }
        .jobinfo_introduction_title {
            padding-bottom: .5rem;
            font-size: 15px;
        }
        .jobinfo_introduction_lead {
            font-size: 14px;
        }
    }
}