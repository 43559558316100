@charset 'UTF-8';
/* #13602 ブラック／ホワイト
---------------------------------------------------------------------------- */
.black_white_box{
    .sec_ttl{
        color: $blue;
        margin-bottom: 30px;
        font-size: $fsize-middle;
        font-weight: bold;
        text-align: left;
        letter-spacing: 0.5px;
        height: auto;
        line-height: 1.75em;
        border-bottom: 1px solid #e3e3e3;
        padding: 15px 0;
    }
    .black_white_box_wrap{//コンテンツの枠
        border: 1px solid $lightgray;
        border-top: none;
        border-radius: 4px;
        background: $white;
        box-shadow: 0 0 0 $lightgray;
        position: relative;
        margin-bottom: 25px;
        h2{
            font-size: $fsize-middle;
            font-weight: bold;
            text-align: left;
            letter-spacing: 0.5px;
            height: auto;
            line-height: 1.75em;
            padding: 20px 0 15px 0;
            overflow: visible;
            border-bottom: 1px solid $lightgray;
            z-index: 0;
            padding-left: 1em;
            color: $blue;
        }
    }
    .blue_border:after{//青ボーダー
        background: $blue;
        width: 100%;
        height: 3px;
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
    }
    .evaluation_box{//星デザイン
        display: flex;
        align-items: baseline;
        margin-left: 12px;
        p{
            margin-bottom: 3px;
        }
        i{
            color: $lightgold;
            margin-left: -5px;
            font-size: $fsize-xlarge;
        }
        .marks{
            font-weight: bold;
            font-size: $fsize-large;
            color: $blue;
        }
    }
    .chart_box_wrap{//リーダーチャートコンテンツ
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 13px 21px;
        .left_box{
            margin: 15px 0 0 5px;
            .chart_evaluation{//チヤート評価
                border: 1px solid $lightgray;
                border-radius: 4px;
                box-shadow: 0 0 2px 0 rgba(0,0,0,0.07);
                padding: 17px 12px;
                margin-bottom: 10px;
                position: relative;
                width: 240px;
                .star-list{
                    margin: 0 0 0 10px;
                }
                .chart_unrated{
                    font-weight: bold;
                }
                .company_name{//会社評価
                    font-size: $fsize-small-middle;
                    margin: 0 0 7px 35px;
                    color: $yellow;
                    font-weight: bold;
                    h3{
                        display: inline-block;
                        &:before{
                            content: "";
                            display: inline-block;
                            width: 18px;
                            height: 18px;
                            background: $yellow;
                            margin-right: 5px;
                            position: absolute;
                            left: -22px;
                            top: 1px;
                        }
                    }
                }
                .industry_name{//業界評価
                    margin-bottom: 7px;
                    font-size: $fsize-small-middle;
                    margin-left: 36px;
                    h3{
                        color: #6a82b2;
                        display: inline-block;
                        font-weight: bold;
                        &:before{
                            content: "";
                            display: inline-block;
                            width: 18px;
                            height: 18px;
                            background: #6a82b2;
                            margin-right: 5px;
                            position: absolute;
                            left: -24px;
                            top: 1px;
                        }
                    }
                    p{
                        color: #6a82b2;
                        line-height: 1.2;
                        font-weight: bold;
                    }
                }
            }
        }//left_box
        .right_box{
            overflow: hidden;
            height: 280px;
            .chart_box_detail{
                position: relative;
                width: 355px;
                height: 355px;
                .radar_double,.radar_double_less{
                    position: absolute;
                    top: -42px;
                    left: 6px;
                }
            }
        }
        .hover_box{//hover表示時の評価BOX
            width: 38px;
            height: 44px;
            border: 1px solid $lightgray;
            border-radius: 2px;
            background: rgba(255, 255, 255, 0.92);
            font-weight: bold;
            text-align: center;
            line-height: 1.5;
            font-size: $fsize-small-middle;
            display: inline-block;
            .company{//会社
                margin-top: 3px;
                color: $blue;
                &:before {
                    content: "";
                    width: 9px;
                    height: 9px;
                    display: inline-block;
                    background: $blue;
                    margin-right: 3px;
                }
            }
            .industry{//業界
                color: $blue;
                &:before {
                    content: "";
                    width: 9px;
                    height: 9px;
                    display: inline-block;
                    background: $lightblack;
                    margin-right: 3px;
                }
            }
        }
    }//chart_box_wrap
    .bar_graph_box_wrap{//棒グラフコンテンツ
        margin: 13px 21px;
        &:after{
          content: "";
          clear: both;
          display: block;
        }
        .margin-right{
            margin-right: 8px;
        }
        .margin-bottom{
            margin-bottom: 13px;
        }
        .bar_graph_box{
            padding: 15px 25px;
            border: 1px solid $lightgray;
            border-radius: 4px;
            width: 294px;
            height: 230px;
            box-shadow: 0 0 2px 0 rgba(0,0,0,0.07);
            float: left;
            .evaluation_box{
                margin: 2px 0 10px 5px;
                p{
                    font-size: $fsize-small-middle;
                    .marks{
                        margin-right: 8px;
                    }
                }
            }
            .bar_graph_txt{
                margin: 0 auto;
                width: 280px;
                h3{
                  font-size: $fsize-middle;
                  font-weight: bold;
                  color: $black;
                  span{
                      font-weight: normal;
                      font-size: $fsize-small;
                  }
                }
            }
            .bar_graph{//グラフ仮置き
                width: 234px;
                height: 138px;
                background: $blue;
            }
            .bar_graph_unrated{//評価非表示
                width: 234px;
                height: 138px;
                text-align: center;
                font-weight: bold;
                line-height: 138px;
            }
            .bar_graph_img{
                position: relative;
                width: 294px;
                margin: 0 auto;
                img{
                    position: relative;
                    width: 198px;
                    left: 41px;
                    top: 5px;
                }
                .horizontal_bar_short{
                    position: absolute;
                    top: -8px;
                    left: -9px;
                }
            }
        }
    }//bar_graph_box_wrap
    .kuchikomi_box_wrap{//口コミコンテンツ
        margin: 8px 15px;
        .kuchikomi_box{
            display: flex;
            align-items: center;
            margin: 0 0 12px 0;
            img{
                width: 46px;
                height: 46px;
                margin-right: 11px;
            }
            a{
                color: $lightblue;
                &:hover{
                    .kuchikomi_fukidashi{
                        &:after{
                            border-right-color: #f0f4fc;
                        }
                    }
                }
            }
        }
        .kuchikomi_fukidashi{//ふきだし
            border: 1px solid $lightgray;
            padding: 8px 20px 6px 20px;
            border-radius: 4px;
            line-height: 1.5;
            width: 552px;
            position: relative;
            box-shadow: 0 0 2px 0 rgba(0,0,0,0.07);
            &:before{
                content: "";
                display: block;
                position: absolute;
                border: 12px solid transparent;
                border-right-color: $lightgray;
                border-left-width: 0;
                width: 0;
                left: -12px;
                top: 40px;
            }
            &:after{
                content: "";
                display: block;
                position: absolute;
                border: 13px solid transparent;
                border-right-color: $white;
                border-left-width: 0;
                width: 0;
                left: -11px;
                top: 39px;
            }
            .evaluation_box{//口コミ評価
                float: left;
                margin: 0 0 7px 0;
            }
            .kuchikomi_status{//口コミ投稿者情報
                color: $textlightgray;
                text-align: right;
                margin-top: 5px;
                font-size: $fsize-xsmall;
            }
            p{
                clear: both;
            }
            .comment_theme{
                border-radius: 3px;
                color: $white;
                margin-right: 15px;
                line-height: 23px;
                height: 22px;
                padding: 0 4px;
                display: inline-block;
                float: left;
                margin-top: 2px;
            }
            .comment_theme_gold {//年収・給与
                background: #F7BF30;
            }
            .comment_theme_lightgreen {//働く環境
                background: $lightgreen;
            }
            .comment_theme_lightpurple {//やりがい
                background: $lightpurple;
            }
            .comment_theme_lightorange {//出世
                background: $lightorange;
            }
            .comment_theme_lightred {//ライバル企業
                background: $lightred;
            }
            .comment_theme_black {//残業・休日出勤
                background: $lightblack;
            }
            .comment_theme_lightyellow {//長所・短所
                background: $lightyellow;
            }
            .comment_theme_darkblue {//退職理由
                background: $darkblue;
            }
            .comment_theme_skyblue {//転職後のギャップ
                background: $skyblue;
            }
            .comment_theme_lightpink {//女性のキャリア
                background: $lightpink;
            }
            .comment_theme_lightblue {//経営者について
                background: $lightblue;
            }
        }//kuchikomi_fukidashi
    }//kuchikomi_box_wrap
    .kuchikomi_link{//他の口コミをみる
        color: $blue;
        text-align: right;
        display: block;
        position: relative;
        top: -17px;
        &:before{
            content: "\e802";
            display: inline-block;
            margin-right: 5px;
            font-family: "fontello";
            font-weight: normal !important;
            transition: all .25s ease-in-out 0s;
        }
        &:hover{
            &:before{
                transform: translate(3px,0);
            }
        }
    }
    .table_wrap{//表コンテンツ
        border: 1px solid $lightgray;
        border-radius: 4px;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.07);
        width: 585px;
        padding: 15px 28px;
        margin: 15px auto;
        table{
            font-size: $fsize-small-middle;
            tr{
                border-bottom: 1px solid $lightgray;
                height: 52px;
                th{
                    padding-left: 24px;
                    width: 20%;
                    .attendance{
                        display: block;
                        line-height: 1.3;
                        width: 116px;
                    }
                }
                td{
                    color: $textlightgray;
                    width: 40%;
                    text-align: center;
                    font-size: 15px;
                }
                .table_company{//会社名
                    text-align: center;
                    font-weight: bold;
                    font-size: 15px;
                    padding-left: 0;
                }
                .table_industry{//業界名
                    text-align: center;
                    color: $textlightgray;
                    font-size: $fsize-small-middle;
                    line-height: 1.3;
                    padding-left: 0;
                    span{
                        display: block;
                    }
                }
                .table_bold{//太字
                     font-weight: bold;
                     font-size: $fsize-large;
                     color: $darkgray;
                }
                .table_unrated{//評価なし
                    font-size: $fsize-small!important;
                }
            }
        }
    }//table_wrap
}//black_white_box
