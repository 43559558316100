@charset 'UTF-8';

.category_list {
    margin: 20px 0 0 0;
    .tab_list {
        border: none;
        > li {
            border-radius: 4px;
            margin: 0 2px 8px 2px;
            border-bottom: solid 1px #D4D4D4;
            height: 27px;
            a {
                color: $black;
                line-height: 27px;
                &:hover,&:active {
                    border-radius: 4px;
                }
            }
        }
        > li.activate {
            height: 27px;
        }
        > li.data_none,
        span.data_none {
            border-radius: 4px;
            //border-bottom-right-radius: 0;
            //border-bottom-left-radius: 1em;
            border-bottom: solid 1px #D4D4D4;
        }
        > li.data_none {
            height: 27px;
        }
        span.data_none {
            height: 26px;
        }
    }
}
