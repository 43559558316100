@charset 'UTF-8';

/*
おすすめ一覧（2017.7.5）
---------------------------------------------------------------------------- */
@media (max-width: 640px) {

    .sub_page.biz #column>li.column_2 {

        /* 記事 */
        .column_outbox.single_wrap {
            padding: 15px 10px;
        }
        .single_body h2 {
            line-height: 1.5;
        }

        // 目次
        .biz-article__contents {
            padding: 0 10px 10px;
            dt {
                margin: 0 -10px 10px;
                padding: 7px 10px;
            }
        }

        // 表組み
        .biz-article__table-wrap {
            overflow-x: auto;
            .biz-article__table {
                width: 600px;
            }
        }
        .biz-article__table-wrap::-webkit-scrollbar {
            height: 10px;
        }
        .biz-article__table-wrap::-webkit-scrollbar-track {
            margin: 0 2px;
            background: #ccc;
            border-radius: 5px;
        }
        .biz-article__table-wrap::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: #666;
        }

        // 特選転職コラム　給与明細リスト
        .biz-special__income-title {
            margin-bottom: 10px;
        }
        .biz-special__income-txt {
            flex-basis: 100%;
            margin-bottom: 10px;
        }
        .biz-special__income-img {
            flex-basis: 100%;
        }

        // 特選転職コラム　CTA（グラフィック要素）
        .biz-special__cta-graphic-txt {
            padding: 0 10px;
        }
        .biz-special__cta-graphic-note {
            margin: 0 10px 20px;
        }

        // 特選転職コラム　CTA（セールスコピー）
        .biz-special__cta-salescopy {
            padding-bottom: 10px;
        }
        .biz-special__cta-salescopy-txt {
            strong, b {
                font-size: 25px;
            }
        }

        // 特選転職コラム　CTA（ボタン）
        .biz-special__cta-button {
            font-size: 18px;
            padding: 10px;
            &::before {
                font-size: 24px;
                margin-top: -12px;
                right: 5px;
            }
        }

        // 特選転職コラム　CTA（スキルアップ、キャリアアップ）
        .biz-special__cta-careerup-balloon {
            padding: 0 10px;
        }

        // 特選転職コラム　CTA（上段・下段のトーンが異なる場合）
        .biz-special__cta-graphic-txt--neutral,
        .biz-special__cta-graphic-txt--negative {
            padding: 20px 10px;
        }
    }
}
