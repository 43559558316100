@charset 'UTF-8';

.sub_page #column>li.column_large.award {

    //インデックスページ
    .award-index-area {
        margin: 0 0 25px;
        padding: 15px;
        border: 1px solid $lightgray;
        background: $white;
    }
    .award-index-area__title {
        margin: 0 0 15px;
        padding: 0 0 10px;
        border-bottom: 3px solid $blue;
        color: $blue;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.75;
    }
    .award-index-area__list {
    }
    .award-index-area__list-item {
        display: flex;
        padding: 20px;
        border-bottom: 1px solid $lightgray;
        font-size: $fs--large;
        line-height: 1.6;
        &:last-of-type {
            border-bottom: none;
        }
    }
    .award-index-area__list-date {
        width: 120px;
        color: $font--gray--light;
    }
    .award-index-area__list-title {
        width: calc(100% - 120px);
        a {
            color: $font--black;
            font-weight: bold;
        }
    }

    //ページャー
    .pagging {
        > li {
           &.prevnext {
               &:hover a {
                   background: $blue;
                   color: $white;
               }
           }
           &.pager ul li {
               &:hover a,
               span {
                   background: $blue;
                   color: $white;
               }
           }
       }
   }
    
    //記事ページ
    .award-article-area {
        margin: 0 0 25px;
        padding: 30px;
        border: 1px solid $lightgray;
        background: $white;
    }
    .award-article-area__header {
        margin: 0 0 40px;
    }
    .award-article-area__header-title {
        margin: 0 0 15px;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.75;
    }
    .award-article-area__header-date {
        color: $font--gray--light;
        font-size: $fs--medium;
    }
    .award-article-area__content {
        font-size: $fs--xlarge;
        line-height: 1.8;
        p {
            margin: 1.5em 0;
        }
        img {
            max-width: 100%;
        }
    }
    .award-article-area__btn {
        display: block;
        position: relative;
        width: fit-content;
        margin: 4em auto 0;
        padding: 15px 40px 15px 15px;
        border: 1px solid $lightgray;
        color: $font--black;
        font-size: $fs--xlarge;
        &::before {
            display: block;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            color: $font--gray--light;
            font-size: $fsize-middle;
            line-height: 1;
        }
        &:hover, &:active {
            background: $blue;
            color: $white;
            text-decoration: none;
            &::before {
                color: $white;
            }
        }
    }
}
