@charset 'UTF-8';

// ライトボックス風のモーダルウィンドウ（CSSのみで実装）
.modal {
    .checkbox{
        display: none;
    }

    .modal__overlay {
        background-color: rgba(0,0,0,0.6);
        display: flex;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transform: scale(1);
        transition: all 0.3s ease;
        width: 100%;
        z-index: -100;
        .modal__overlay-close {
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: -100;
        }
    }

    .modal__wrap {
        align-self: center;
        background-color: $white;
        border-radius: 4px;
        box-shadow: 0 6px 6px rgba(0,0,0,0.2);
        line-height: 1.6;
        margin: 0 auto;
        padding: 10px 25px 20px;
        position: relative;
        transition: all 0.5s ease;
        width: 640px;
        dt {
            border-bottom: 1px solid $border--gray;
            color: $blue;
            font-size: $fs--medium;
            font-weight: bold;
            margin-bottom: 15px;
            padding: 10px 0 10px 25px;
            position: relative;
            &::before {
                content: "\f059";
                font-family: FontAwesome;
                font-size: $fsize-xxlarge;
                font-weight: normal;
                height: 20px;
                line-height: 1;
                margin-top: -10px;
                position: absolute;
                left: 0;
                text-align: center;
                top: 50%;
                width: 20px;
            }
        }
        dd {
            font-size: $fsize-small-middle;
            .disc-list {
                list-style-type: disc;
                padding: 0 0 0 20px;
            }
            li {
                margin-bottom: 10px;
            }
        }
    }

    .button--modal-close {
        background: $gray--dark;
        border: 2px solid $white;
        border-radius: 16px;
        color: $white;
        cursor: pointer;
        display: table;
        font-size: 24px;
        height: 32px;
        position: absolute;
        right: -10px;
        text-align: center;
        top: -10px;
        width: 32px;
        &::before {
            @include font-cc($font-cc-cross);
            display: table-cell;
            vertical-align: middle;
        }
        &:hover {
            background: #555;
        }
    }

    input:checked {
        & ~ .modal__overlay {
            opacity:1;
            overflow: auto;
            transform: scale(1);
            z-index: 9997;
        }
        & ~ .modal__overlay-close {
            z-index: 9998;
        }
        & ~ .modal__overlay .modal__wrap {
            transform: translateY(0);
            z-index: 9999;
        }
    }
}


.info_panel_list_s {
    dl {
        a {
            color: $darkgray;
        }
    }
}

/*
#13447【キャリコネ】トップページや主要ＬＰ、ヘッダーなどにＮＯ．１ロゴを掲載する
---------------------------------------------------------------------------- */
#header .header_l {
    .inner_wrap {
        align-items: center;
        display: flex;
        #logo {
            padding: 0;
            position: relative;
        }
        .description {
            left: 0;
            margin-left: 20px;
            margin-top: 0;
            position: relative;
            top: 0;
        }
    }
}

header {
    .shoulder_copy {
        background: $white;
        border: 1px solid #ababe6;
        border-radius: 3px;
        color: #0f3d99;
        font-weight: bold;
        line-height: 1.4;
        margin-left: 15px;
        padding: 4px 5px;
        position: relative;
        &::before {
            background: $white;
            border-width: 0 0 1px 1px;
            border-style: solid;
            border-color: #ababe6;
            content: "";
            display: block;
            height: 10px;
            left: -6px;
            margin-top: -6px;
            position: absolute;
            top: 50%;
            transform: rotate(45deg);
            width: 10px;
        }
        span {
            position: relative;
        }
    }
}


/*
#12768 & #13220【キャリコネ】【Markeship】h1タグの修正【施策ID:1-4 & 施策ID:27-1】
---------------------------------------------------------------------------- */
.sub_page {
    #column>li.column_2 {
        .com_comment {
            .com_comment_inner {
                .com_comment_inner_title {
                    font-weight: bold;
                    font-size: 14px;
                    text-align: left;
                    background: transparent;
                    padding: 0;
                    margin-bottom: 10px;
                }
                .com_comment_info {
                    font-weight: bold;
                    font-size: 14px;
                    text-align: left;
                    background: transparent;
                    padding: 0;
                    margin-bottom: 10px;
                }
            }
        }
        &.sitemap {
            a {
                color: $darkgray;
            }
        }
    }
    #column>li.column_3 {
        .column_register {
            header {
                .header-title {
                    color: #FFF;
                    padding: 8px 35px 15px;
                    border-radius: 4px 4px 0 0;
                    background: none;
                    background: #1d1daf;
                    background: -moz-linear-gradient(top, #1d1daf 0%, #1d1daf 100%);
                    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1d1daf), color-stop(100%, #1d1daf));
                    background: -webkit-linear-gradient(top, #1d1daf 0%, #1d1daf 100%);
                    background: -o-linear-gradient(top, #1d1daf 0%, #1d1daf 100%);
                    background: -ms-linear-gradient(top, #1d1daf 0%, #1d1daf 100%);
                    background: linear-gradient(to bottom, #1d1daf 0%,#1d1daf 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$top', endColorstr='$bottom',GradientType=0 );
                    text-align: left;
                    font-size: 22px;
                    line-height: 1.3;
                    height: auto;
                    span {
                        font-size: 13px;
                        text-shadow: 0 0 2px #4545bf;
                    }
                }
            }
        }
    }
}

.column_2_footer {
    .column_2_footer_title {
        font-size: 13px;
        font-weight: bold;
        padding: 14px 0;
        border-top: 2px solid #E3E3E3;
        border-bottom: 2px solid #E3E3E3;
        margin: 1em 0;
    }
}

@media (min-width: 768px) {

    .pc-none {
        display: none !important;
    }

    #footer {

        /*
        #12768 & #13220【キャリコネ】【Markeship】h1タグの修正【施策ID:1-4 & 施策ID:27-1】
        ---------------------------------------------------------------------------- */
        .footer_row_1 {
            .footer_title {
                color: #2F2F2F;
                margin-bottom: 25px;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
            }
        }

        .footer_row_2 {
            .row_2_left {
                > ul {
                    > li.row_2_left_left {
                        width: 55% !important;
                    }
                    > li.row_2_left_right {
                        width: 45% !important;
                    }
                }
            }
            .row_2_right {
                p {
                    a {
                        color: #542189;
                        &:hover, &:active {
                            color: #e46746;

                        }
                    }
                }
                .row_2_right_title {
                    color: #2F2F2F;
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 15px;
                }
            }
        }

        .footer_row_3 {
            .company {
                .company_title {
                    position: absolute;
                    top: 0;
                    left: 0;
                    text-align: left;
                    color: #FFF;
                    font-weight: bold;
                }
            }
        }

    }
}


/*
#13262【キャリコネ】SP検索ボタンの位置（2017.7.13）
---------------------------------------------------------------------------- */
.top_search {
    .searchform_wrap {
        .searchform {
            .result-box {
                position: absolute;
                z-index: 10;
                left: 180px;
                top: 50px;
                width: 600px;
                padding: 15px;
                border-radius: 5px;
                background: #fff;
                background: rgba(255,255,255,0.95);
                box-shadow: 0 0 10px rgba(0,0,0,0.1);
            }
            .result-box ul {
                position: relative;
                z-index: 10;
                left: auto;
                top: auto;
                width: 100%;
                padding: 0;
                border-radius: none;
                background: #fff;
                background: none;
                box-shadow: none;
            }
        }
    }
}

.sub_page #column>li.column_1 .column_outbox {
    .nav_1 > ul > li.nav_top {
        a {
            color: $blue;
            &:hover {
                background: $blue;
                color: $white;
            }
        }
        &:hover {
            background: $white;
        }
    }
    .nav_1 > ul > li.nav_review > ul > li:first-child {
        border-top: none;
    }
}
