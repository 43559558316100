@charset 'UTF-8';

@media (max-width: 640px) {

    /* キャリコネとは - セクション */
    .about-section {
        margin-bottom: 40px;
    }
    .about-section__title {
        margin-bottom: 20px;
        font-size: 22px;
    }
    .about-section__lead {
        margin-bottom: 20px;
        font-size: 14px;
    }

    /* キャリコネとは - ローカルナビ */
    .about-local-nav__title {
        margin-bottom: 20px;
        font-size: 18px;
    }
    .about-local-nav__list {
        display: block;
        margin-bottom: 0;
    }
    .about-local-nav__list-item {
        height: 60px;
        margin-bottom: 12px;
    }

    /* キャリコネとは（トップ） - バルーン */
    .about-index-balloon {
        width: calc(100% - (8px * 2));
        margin: 0 8px 70px;
        &::before {
            border-radius: 30px;
        }
    }
    .about-index-balloon__inner {
        padding: 12px 20px 20px;
        border-radius: 30px;
    }

    .about-index-balloon__decoration-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
    }
    .about-index-balloon__decoration {
        position: relative;
        top: auto;
        transform: none;
        i {
            display: inline-block;
            font-size: 32px;
            &:nth-of-type(2) {
                margin: 0 8px;
            }
        }
        &.is-left {
            left: auto;
            margin-right: 8px;
            i:nth-of-type(1),
            i:nth-of-type(3) {
                margin-left: 0;
            }
        }
        &.is-right {
            right: auto;
            i:nth-of-type(2) {
                margin-left: 8px;
            }
        }
    }

    /* キャリコネとは（トップ） - バルーン テキスト */
    .about-index-balloon__title {
        margin: 0 0 12px;
        font-size: 22px;
    }
    .about-index-balloon__text {
        font-size: 14px;
        text-align: left;
    }

    /* キャリコネとは（トップ） - バルーン テイル */
    .about-index-balloon__tail {
        position: absolute;
        right: 60px;
        bottom: -18px;
        width: 40px;
        height: 35px;
    }
    .about-index-balloon__tail-shadow {
        right: calc(60px - 6px);
        bottom: calc(-18px - 6px);
        width: 40px;
        height: 35px;
    }

    /* キャリコネとは（トップ） - 口コミを閲覧するには */
    .about-index-permissions {
        display: block;
    }
    .about-index-permissions-item {
        margin-bottom: 20px;
    }
    .about-index-permissions-item__title {
        position: relative;
        margin-bottom: 16px;
        padding-left: 56px;
        text-align: left;
        i {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .about-index-permissions-item__title-sub {
        margin-top: 4px;
        font-size: 12px;
    }
    .about-index-permissions-item__content-main {
        margin-bottom: 16px;
    }
    .about-index-permissions-item__cta {
        margin-top: 16px;
    }

    /* キャリコネとは（トップ） - 会員特典 */
    .about-index-benefits {
        margin-bottom: 20px;
    }
    .about-index-benefits__inner {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }
    .about-index-benefits__table {
        width: 560px;
        td {
            padding: 12px 8px;
            border-left: none;
        }
    }
    .about-index-benefits__table-head {
        padding: 12px 8px;
        border-left: none;
    }
    .about-index-benefits__table-sticky {
        position: sticky;
        top: 0;
        left: 0;
        padding: 12px 8px;
        border-left: none;
        border-right: none;
        background: none;
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            border-width: 0 1px;
            border-style: solid;
            border-color: $border--gray2;
            background: $bg--gray;
            content: "";
        }
    }

    .about-index-benefits__note {
        font-size: 12px;
    }
    .about-index-benefits__hint {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        visibility: visible;
        padding: 4px 12px 8px;
        border-radius: 8px;
        background: rgba(0,0,0,.5);
        opacity: 1;
        color: $white;
        font-size: 12px;
        transition: all 0.3s ease;
        i {
            font-size: 48px;
        }
        &.is-hidden {
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
        }
    }

    .about-index-benefits__detail {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .about-index-benefits__detail-item {
        width: 100%;
        &:nth-of-type(n+2) {
            margin-top: 20px;
        }
    }

    /* キャリコネとは（トップ） - スカウト */
    .about-index-scout {
        margin-bottom: 20px;
    }
    .about-index-scout__title {
        font-size: 18px;
    }

    .about-index-scout-description {
        position: relative;
        min-height: auto;
        margin-bottom: 0;
    }
    .about-index-scout-description__image {
        display: block;
        position: relative;
        left: auto;
        bottom: auto;
        margin: 0 auto 20px;
        vertical-align: baseline;
    }
    .about-index-scout-description__text {
        padding: 0;
    }

    .about-index-scout-setting__item {
        padding: 20px 0;
        > dt {
            position: relative;
            margin-bottom: 16px;
            padding-left: 52px;
        }
        > dd {
            position: relative;
        }
        .about-button {
            position: relative;
            right: auto;
            top: auto;
            transform: none;
            width: 100%;
            margin-top: 16px;
        }
    }
    .about-index-scout-setting__item-num {
        width: 48px;
        height: 48px;
        font-size: 14px;
        &::before {
            font-size: 48px;
        }
    }
    .about-index-scout__cta {
        width: 100%;
    }

}