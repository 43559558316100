@charset 'UTF-8';

/*#13602 ブラック／ホワイト
---------------------------------------------------------------------------- */
@media (max-width: 640px) {
    .black_white_box{
        .chart_box_wrap{//リーダーチャートコンテンツ
            flex-direction: column-reverse;
            margin: 0;
            .left_box{
                width: 100%;
                align-items: start;
                display: flex;
                position: initial;
                padding: 0 6px;
                margin: 0;
                .chart_evaluation{//チヤート評価
                    width: 100%;
                    padding: 10px 0 10px 6px;
                    &:first-child{
                        margin-right: 6px;
                    }
                    .chart_evaluation_wrap{
                        width: 90%;
                        margin: 0 auto;
                        .industry_name{//業界
                            display: inline-block;
                            margin-left: 24px;
                            h3{
                                display: block;
                            }
                            p{
                                margin-left: 0;
                                text-align: left;
                            }
                        }
                        .evaluation_box{
                            text-align: left;
                            display: inline-block;
                            margin-left: 0;
                            p{
                                margin-bottom: 1px;
                            }
                            .star-list{
                                margin: 0 0 0 5px;
                            }
                        }
                        .company_name{//会社
                            margin-left: 0;
                            margin-bottom: 7px;
                            &:before{
                                top: 17px;
                                left: 14px;
                            }
                            h3{
                                margin-left: 22px;
                            }
                        }
                    }
                }
            }//left_box
            .right_box{
                width: 100%;
                .leader_chart{
                    width: 100%;
                }
                .chart_box_detail {
                    margin: 0 auto;
                    width: 100%;
                    height: 100%;
                    .radar_double,.radar_double_less{
                        left: 0;
                        top: 0;
                    }
                }
            }
        }//chart_box_wrap
        .bar_graph_box_wrap{//棒グラフコンテンツ
            margin: 13px 6px 0 6px;
            .bar_graph_box{
                width: 100%;
                float: none;
                height: 220px;
                &:first-child,&:nth-child(2){
                    margin: 0 auto 13px auto;
                }
                .evaluation_box {
                    margin: 4px 0 10px 5px;
                }
                .bar_graph {
                    width: 100%;
                }
                .bar_graph_unrated{//評価なし
                    width: 100%;
                }
                img{
                    width: 230px;
                    left: 41px;
                }
                .horizontal_bar_short{
                    position: relative;
                    top: -160px;
                }
            }
        }//bar_graph_box_wrap
        .kuchikomi_box_wrap{//口コミコンテンツ
            margin: 13px 6px 6px 6px;
            .kuchikomi_box{
                align-items: end;
                margin: 0 0 9px 0;
                img{
                    width: 40px;
                    height: 40px;
                }
                .kuchikomi_fukidashi{//ふきだし
                    width: 100%;
                    padding: 9px 9px 4px 9px;
                    p{
                        width: 100%
                    }
                    .comment_theme{
                        display: inline-block;
                        font-size: $fsize-xsmall;
                        margin-right: 7px;
                    }
                    .evaluation_box{//星デザイン
                        margin-bottom: 2px;
                        .star-list{
                            margin-left: 5px;
                        }
                    }
                    &:before{
                        top: 9px;
                    }
                    &:after{
                        top: 8px;
                    }
                }
            }
        }//kuchikomi_box_wrap
        .table_wrap{//表コンテンツ
            border: none;
            box-shadow: none;
            width: 100%;
            padding: 0;
            table{
                margin: 13px 6px;
                .table_industry{
                    font-weight: bold;
                }
                tr{
                    th{
                        padding-left: 12px;
                        width: 25%;
                        .attendance{
                            width: auto;
                        }
                        td{
                            width: 37.5%;
                        }
                    }
                }
                .table_unrated{//評価なし
                    font-size: $fsize-xsmall!important;
                }
            }
        }
    }
}
@media (max-width: 320px) {
    .black_white_box {
        .chart_box_wrap {
            .right_box {
                height: 220px;
            }
        }
    }
}
