@charset "UTF-8";

/*
#13213&#13365 内部リンク拡充
--------------------------------*/
.main_visual_inner {
    margin-top: -96px;
}

.top_page #main_visual .main_visual_inner {
    margin-top: -145px;
}

.sub_page #main_visual {
    height: 240px;
}

.main_visual_inner .searchform_wrap .searchform {
    margin: 0 0 10px 0;
}

.popular_word_wrap {
    width: 100% !important;
    margin: 0 0 10px 0 !important;
    height: auto !important;
    overflow: visible;

    .popular_word {

    }
    .word_list {
        width: 730px !important;
        height: auto !important;
    }
}

.main_visual_inner a.detail_search {
    color: #031096;
    font-size: $fsize-middle;
    text-decoration: underline;
}

.review .job_search {
    margin-top: 30px;
}

.review .biz_search {
    margin-top: 50px;
}
