@charset 'UTF-8';

/*
overview配下のローカルナビ

【変更履歴】
#12768 & #13220【キャリコネ】【Markeship】h1タグの修正【施策ID:1-4 & 施策ID:27-1】
#13643【キャリコネ】【実装】corpDetail廃止とoverviewへ企業情報追加
---------------------------------------------------------------------------- */
.company_list {
    .overview_localnavi {
        li {
            background: $white;
            border-top: none;
            display: table;
            font-weight: bold;
            float: left;
            width: 107px;
            a {
                border-top: 1px solid #d4d4d4;
                display: table-cell;
                height: 70px;
                line-height: 1.3;
                position: relative;
                text-align: center;
                vertical-align: middle;
                width: 100%;
                .overview_localnavi_name {
                    color: $darkgray;
                    display: inline-block;
                    font-size: $fsize-middle;
                    width: 100%;
                }
                .overview_localnavi_count {
                    color: #7c79d8;
                    font-size: $fsize-small;
                    margin-top: 4px;
                }
                &:hover {
                    background: #f0f4fc;
                }
                &::after {
                    background: #d4d4d4;
                    content: "";
                    display: block;
                    height: 30px;
                    position: absolute;
                    right: 0;
                    top: 20px;
                    width: 1px;
                }
            }
            &.activate a {
                background: $blue;
                &::after {
                    display: none;
                }
                .overview_localnavi_name,
                .overview_localnavi_count {
                    color: $white;
                }
            }
            &:first-child {
                a {
                    border-left: 1px solid #d4d4d4;
                }
            }
            &:last-child {
                width: 105px;
                a {
                    border-right: 1px solid #d4d4d4;
                    &::after {
                        display: none;
                    }
                }
                .overview_localnavi_nocount {
                    border-right: 1px solid #d4d4d4;
                    &::after {
                        display: none;
                    }
                }
            }
            a:hover,
            &.activate {
                &:first-child a {
                    border-left: none;
                }
                &:last-child a {
                    border-right: none;
                }
            }
            .overview_localnavi_nocount {
                border-top: 1px solid #d4d4d4;
                color: #ddd;
                display: table-cell;
                font-size: $fsize-middle;
                height: 70px;
                line-height: 1.3;
                position: relative;
                text-align: center;
                vertical-align: middle;
                width: 100%;
                &::after {
                    background: #d4d4d4;
                    content: "";
                    display: block;
                    height: 30px;
                    margin-top: -15px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    width: 1px;
                }
            }
            &.recruiting .overview_localnavi_nocount {
                display: none;
            }
        }
        &.no-link {
            .recruiting {
                a {
                    display: none;
                }
                .overview_localnavi_nocount {
                    display: table-cell;
                }
            }
        }
    }
}

.company_information { // #13717 reviewディレクトリの各企業配下のスマホページのUI改善
    width: 100%;
    .company_information-heading {
        font-size: 16px;
        color: #000;
        font-weight: bold;
        margin-top: 0px;
        padding: 0;
        .company_name_y {
            color: $black;
            &:link, &:hover, &:active, &:visited {
                color: $black;
            }
        }
    }
    .rating_total {
        margin: 10px 0 0 0;
        .star2 {
            a.favorite {
                display: inline-block;
                margin: 0 0 0 20px;
                color: #7c79d8;
                font-size: 12px;
                font-weight: bold;
                &:hover, &:active {
                    text-decoration: underline;
                }
            }
        }
    }
}


.agent {
    .agentlogo_area {
        img {
            width: 310px;
        }
    }
}
