@charset 'UTF-8';

/*
業績情報（2018.2.19）
---------------------------------------------------------------------------- */
.sub_page #column>li.column_2 {

    // 業績情報コンテンツ
    .performance-graph-area {
        margin-top: 20px;

        // リスト見出し
        > dt {
            background: $white;
            border: 1px solid $lightgray;
            line-height: 1.5;
            padding: 12px 15px 8px;
            position: relative;
            &::after {
                background: $blue;
                width: 100%;
                height: 2px;
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
            }
            .title-company {
                display: block;
                font-size: $fsize-small;
                line-height: 1.2;
             }
            strong {
                color: $blue;
                font-size: $fsize-middle;
                font-weight: bold;
            }

            // データの読み方ボタン
            .button--modal-supplement {
                color: $blue;
                font-size: $fsize-small;
                line-height: 1;
                margin-top: -6px;
                position: absolute;
                right: 15px;
                top: 50%;
                &::before {
                    content: "\f059";
                    display: inline-block;
                    font-family: FontAwesome;
                    font-size: $fsize-middle;
                    margin-right: 3px;
                }
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

        // リストコンテンツ
        > dd {
            background: $white;
            border-color: $lightgray;
            border-style: solid;
            border-width: 0 1px 1px;
            margin-bottom: 15px;
            padding: 20px 15px;
        }

        .performance-graph-area__graph {
            position: relative;
        }

        .performance-graph-area__no-data {
            align-items: center;
            background: rgba(255, 255, 255,0.5);
            display: flex;
            font-size: $fsize-xsmall;
            font-weight: bold;
            height: 100%;
            justify-content: center;
            left: 0;
            line-height: 1.5;
            position: absolute;
            text-align: center;
            text-shadow: 0 0 10px $white;
            top: 0;
            width: 100%;
        }

        //業績状況のタブ
        .tab-change {
            display: table;
            margin: 0 auto 20px;
            li {
                background: $white;
                color: $blue;
                cursor: pointer;
                display: table-cell;
                font-size: $fs--medium;
                font-weight: bold;
                line-height: 1;
                padding: 10px;
                position: relative;
                text-align: center;
                width: 140px;
                &:nth-of-type(1) {
                    border-color: $border--blue;
                    border-style: solid;
                    border-width: 1px 0 1px 1px;
                    border-radius: 4px 0 0 4px;
                }
                &:nth-of-type(2) {
                    border: 1px solid $border--blue;
                    border-radius: 0 4px 4px 0;
                }
                &:hover {
                    background: $bg--link--blue--light;
                    border-color: $border--blue;
                    color: $font--link--blue;
                }
                &.current {
                    background: $blue;
                    border-color: $blue;
                    color: $white;
                    cursor: default;
                    &::after {
                        background: $blue;
                        border-color: $blue;
                        border-style: solid;
                        border-width: 0 0 1px 1px;
                        content: "";
                        display: block;
                        height: 8px;
                        left: 50%;
                        margin-left: -4px;
                        position: absolute;
                        bottom: -5px;
                        transform: rotate(-45deg);
                        width: 8px;
                    }
                }
            }
        }

        .tab-change-contents__content {
            display: flex;
            flex-wrap:wrap;
            justify-content: space-between;
        }

    }

    //平均年収のコンテンツ
    .performance-graph-area__income {
        display: flex;
        flex-wrap:wrap;
        justify-content: space-between;
    }
    .performance-graph-area__income-list {
        flex-basis: 290px;
        > dt {
            font-weight: bold;
            margin-bottom: 10px;
        }
        &:nth-of-type(1) {
            > dt {
                margin-bottom: 36px;
            }
        }
        > dd {
            position: relative;
        }
    }
    .performance-graph-area__text {
        flex-basis: 580px;
        > dt {
            font-weight: bold;
            margin: 15px 0 5px 0;
        }
    }

    //業績状況リスト
    .performance-graph-area__performance-list {
        border: 1px solid $border--gray;
        width: 190px;
        dt {
            background: $bg--gray;
            border-bottom: 1px solid $border--gray;
            font-size: $fs--medium;
            font-weight: bold;
            line-height: 1.5;
            padding: 7px;
            text-align: center;
        }
        dd {
            font-size: $fs--xxxsmall;
            line-height: 1.5;
            padding: 10px;
            position: relative;
        }
    }
    .performance-graph-area__performance-value {
        text-align: right;
        strong {
            font-size: $fs--xxxlarge;
            font-weight: 700;
            small {
                font-size: $fs--xsmall;
            }
        }
    }
    .performance-graph-area__performance-value-up,
    .performance-graph-area__performance-value-down {
        font-size: $fsize-middle;
        small {
            font-size: $fs--xxxsmall;
        }
    }
    .performance-graph-area__performance-value-up {
        color: $font--red--review;
    }
    .performance-graph-area__performance-value-down {
        color: $font--blue--review;
    }
    .performance-graph-area__performance-graph {
        text-align: center;
    }

    .performance-graph-area__attention {
        color: $font--gray--light;
        font-size: $fs--xxsmall;
        line-height: 1.6;
        margin-bottom: 20px;
        padding: 0 10px;
    }

    // 業績情報コンテンツの但し書き
    .performance-graph-area__attention {
        color: #8c8c8c;
        font-size: $fsize-xsmall;
    }
}
