@charset 'UTF-8';

.sub_page #column .column_3 .column_outbox .column_inbox a {
    color: $darkgray;
}

/*
overview配下の右サイドバー

【変更履歴】
#13216 【キャリコネ】【Markeship】(/review/配下)関連リンク拡充【施策ID:24-1、優先度:A】
#13643【キャリコネ】【実装】corpDetail廃止とoverviewへ企業情報追加
---------------------------------------------------------------------------- */
.region_industry_company_list {
    .side_ttl {
        span {
            display: inline-block;
            vertical-align: middle;
            font-weight: bold;
            color: $darkgray;
            background: #ffe680;
            margin: 0 5px;
            padding: 0px 6px;
        }
    }
    .column_inbox {
        .company_list_ri {
            > li {
                border-bottom: 1px solid #E3E3E3;
                margin-bottom: 0;
                a {
                    display: block;
                    padding: 10px 0;
                    width: 100%;
                    &:hover {
                        text-decoration: none;
                        p.company_list_name {
                            text-decoration: underline;
                        }
                    }
                }
                p.company_list_name {
                    color: $darkgray;
                    font-size: $fsize-middle;
                    font-weight: bold;
                }
                p.star {
                    i {
                        width: 18px;
                        height: 18px;
                        margin-right: 2px;
                        font-size: $fsize-xlarge;
                    }
                    span {
                        font-size: $fsize-large;
                        margin-left: 10px;
                    }
                }
                ul.link_list_ri {
                    border-bottom: 1px solid $lightgray;
                    padding: 5px 0 10px 0;
                    li {
                        display: inline-block;
                        margin-right: 5px;
                        padding-right: 5px;
                        border-right: 1px solid $lightgray;
                        a {
                            color: #3f93c5;
                            &:hover,&:active {
                                text-decoration: underline;
                            }
                        }
                        &:last-child {
                            border-right: none;
                            margin-right: 0;
                            padding-right: 0;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

}

#aside_company_info_box,
.aside_company_perfprmance_box {
    .column_inbox {
        padding: 15px 10px 5px;
        dl {
            display: flex;
            flex-wrap: wrap;
            dt {
                border-bottom: 1px solid $lightgray;
                color: #262680;
                display: flex;
                flex-basis: 28%;
                padding: 5px 0;
            }
            dd {
                border-bottom: 1px solid $lightgray;
                display: flex;
                flex-basis: 72%;
                padding: 5px 0;
                word-break: break-all;
                word-wrap: break-word;
            }
        }
        .more_link {
            color: $blue !important;
            margin-top: 10px;
        }
    }
}
#aside_company_info_box {
    .column_inbox {
        dt:last-of-type,
        dd:last-of-type {
            border-bottom: none;
        }
        .aside_company_info_box_col01 {
            flex-basis: 28%;
        }
        .aside_company_info_box_col02 {
            flex-basis: 25%;
        }
        .aside_company_info_box_col03 {
            flex-basis: 19%;
        }
        .aside_company_info_box_col04 {
            flex-basis: 28%;
        }
    }
}
