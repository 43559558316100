@charset 'UTF-8';

* {

}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: $font--placeholder;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
    color: $font--placeholder;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
    color: $font--placeholder;
    opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: $font--placeholder;
}

#column .review_sub .pan li:first-child span,
#column #search_y .pan li:first-child span {
    width:initial;
    height:initial;
    display: block;
}

.mb20 {
    margin-bottom: 20px;
}
