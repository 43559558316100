@charset 'UTF-8';

.sub_page.biz #column>li.column_2 {

    /* 記事 */
    .column_outbox.single_wrap {
        border: 1px solid $lightgray;
        border-radius: 0;
    }
    .column_outbox_imp {
        border: 1px solid $lightgray;
        border-radius: 0;
    }

    .single_body blockquote::after {
        font-family: '游明朝', 'Yu Mincho', serif;
    }
    .single_body .wp-caption {
        width: auto!important; // Wordpress側でstyle属性値に入ってくるため上書き用important
        margin: 10px auto;
    }
    .biz_repo_frame {
        margin: 0 20px 20px 0;
    }

    // 目次
    .biz-article__contents {
        border: 1px solid $lightgray;
        border-radius: 4px;
        margin-bottom: 20px;
        padding: 0 20px 10px;
        dt {
            border-bottom: 1px solid $lightgray;
            color: $green;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.5;
            margin: 0 -20px 10px;
            padding: 7px 20px;
        }
        dd {
            border-bottom: 1px solid $lightgray;
            font-size: 14px;
            line-height: 1.5;
            padding: 7px 0;
            &:last-of-type {
                border-bottom: none;
            }
        }
    }

    // 表組み
    .biz-article__table-wrap {
        margin-bottom: 20px;
    }
    .biz-article__table,
    .biz-article__ranking-table {
        border-collapse: collapse;
        font-size: 14px;
        line-height: 1.5;
        width: 100%;
        th ,td {
            border: 1px solid $lightgray;
            padding: 4px 8px;
        }
    }
    .biz-article__table {
        th {
            background: #f5f5f5;
        }
        thead th {
            background: #eff9ec;
            color: $green;
            font-weight: bold;
            text-align: center;
        }
    }
    .biz-article__table--col5 {
        th ,td {
            width: 20%;
        }
    }

    .biz-article__ranking-table {
        margin-bottom: 20px;
        th {
            font-weight: bold;
            text-align: center;
            white-space: nowrap;
        }
        thead th {
            background: #eff9ec;
            color: $green;
            font-weight: bold;
            text-align: center;
        }
    }
    .biz-article__ranking-table-rank01 {
        background: #fcde63;
    }
    .biz-article__ranking-table-rank02 {
        background: #ddd;
    }
    .biz-article__ranking-table-rank03 {
        background: #eb956a;
    }
    .biz-article__ranking-table-income {
        text-align: right;
        white-space: nowrap;
    }
    .biz-article__ranking-table-th01 {
        width: 16%;
    }
    .biz-article__ranking-table-th02 {
        width: 42%;
    }
    .biz-article__ranking-table-th03 {
        width: 42%;
    }

    // 注意書き
    .biz-article__note,
    .biz-article__note--bdbottom,
    .biz-article__note--bdtop {
        font-size: 13px;
        line-height: 1.5;
        word-break: break-all;
        word-wrap: break-word;
    }
    .biz-article__note {
        margin-bottom: 30px;
        text-align: right;
    }
    .biz-article__note--bdbottom {
        border-bottom: 1px solid $lightgray;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    .biz-article__note--bdtop {
        border-top: 1px solid $lightgray;
        margin-top: 20px;
        padding-top: 20px;
    }

    // 特選転職コラム　給与明細リスト
    .biz-special__income-list {
        display: flex;
        flex-wrap: wrap;
        font-size: 16px;
        justify-content: space-between;
        line-height: 1.8;
        margin-bottom: 30px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .biz-special__income-title {
        border-bottom: 1px solid #e3e3e3;
        flex-basis: 100%;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .biz-special__income-txt {
        flex-basis: 285px;
    }
    .biz-special__income-img {
        flex-basis: 285px;
        img {
            display: block;
        }
    }
    .biz-special__income-link {
        color: #20660A;
        display: block;
        font-size: 13px;
        margin-top: 7px;
        position: relative;
        text-align: right;
        &::after {
            content: "\e802";
            display: inline-block;
            margin-left: 5px;
            font-family: "fontello";
        }
    }

    // 特選転職コラム　CTA
    .biz-special__cta {
        margin: 40px 0;
    }
    .biz-special__cta--positive {
        .biz-special__cta-graphic {
            background: #fff6e5;
            &::after {
                border-color: #fff6e5 transparent transparent;
            }
        }
    }
    .biz-special__cta--negative {
        .biz-special__cta-graphic {
            background: #ebf0fa;
            &::after {
                border-color: #ebf0fa transparent transparent;
            }
        }
    }

    // 特選転職コラム　CTA（グラフィック要素）
    .biz-special__cta-graphic {
        margin-bottom: 40px;
        padding-bottom: 20px;
        position: relative;
        &::after {
            border-style: solid;
            border-width: 60px;
            bottom: -90px;
            content: "";
            display: block;
            height: 120px;
            left: 50%;
            margin-left: -60px;
            position: absolute;
            transform: scaleY(0.5);
            width: 120px;
        }
        dt {
            color: $white;
            font-size: 18px;
            font-weight: bold;
            line-height: 1.8;
            padding: 12px 20px;
            text-align: center;
        }
        dd {
            font-size: 16px;
            line-height: 1.8;
            &:last-of-type {
                margin: 0;
            }
        }
        img {
            display: block;
            backface-visibility: hidden;
        }
        strong {
            color: #e61739;
            font-weight: bold;
        }
        + .biz-special__cta-button {
            margin-top: 50px;
        }
    }
    .biz-special__cta-graphic-title--positive {
        background: #ffaa00;
    }
    .biz-special__cta-graphic-title--negative {
        background: #364692;
    }
    .biz-special__cta-graphic-title--neutral {
        background: #20660a;
    }
    .biz-special__cta-graphic-img {
        margin: 20px 0;
        img + img {
            margin-top: 20px;
        }
    }
    .biz-special__cta-graphic-txt {
        margin: 20px 0;
        padding: 0 20px;
    }
    .biz-special__cta-graphic-note {
        border-bottom: 1px solid #d8d8d8;
        display: block;
        font-size: 13px;
        margin: 0 20px 20px;
        padding: 15px 0;
        text-align: right;
    }

    // 特選転職コラム　CTA（セールスコピー）
    .biz-special__cta-salescopy {
        margin-bottom: 0;
        padding: 10px 0 35px;
        position: relative;
        &::before {
            color: #ffeacc;
            font-size: 180px;
            font-weight: normal;
            left: 50%;
            margin-left: -90px;
            position: absolute;
            top: -30px;
            z-index: 1;
        }
    }
    .biz-special__cta-salescopy-txt {
        display: block;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.4;
        padding-bottom: 20px;
        position: relative;
        text-align: center;
        z-index: 2;
        strong {
            color: #ffaa00;
            font-size: 28px;
        }
        b {
            color: #2f2f2f;
            font-size: 28px;
        }
    }
    .biz-special__cta-saleslead-txt,
    .biz-special__cta-saleslead-txt--center {
        b {
            color: #e61739;
            font-weight: bold;
        }
    }
    .biz-special__cta-saleslead-txt--center {
        text-align: center;
    }

    // 特選転職コラム　CTA（ボタン）
    .biz-special__cta-button {
        background: #ffaa00;
        border-radius: 6px;
        color: $white;
        display: block;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.4;
        padding: 22px 10px;
        position: relative;
        text-align: center;
        width: 100%;
        &:hover {
            background: #ffcf4c;
            text-decoration: none;
        }
        &::before {
            font-size: 28px;
            font-weight: normal;
            line-height: 1;
            margin-top: -14px;
            position: absolute;
            right: 10px;
            top: 50%;
        }
    }

    // 特選転職コラム　CTA（スキルアップ、キャリアアップ）
    .biz-special__cta-careerup-balloon {
        margin: 20px 0;
        padding: 0 20px;
        p {
            background: $white;
            box-shadow: 0 6px 6px #ffe6b7;
            border-radius: 0 0 5px 5px;
            margin-bottom: 0;
            padding: 10px 15px;
        }
        b {
            color: #e61739;
        }
    }

    // 特選転職コラム　CTA（上段・下段のトーンが異なる場合）
    .biz-special__cta-graphic-txt--neutral,
    .biz-special__cta-graphic-txt--negative {
        padding: 20px;
        p:last-of-type {
            margin: 0;
        }
    }
    .biz-special__cta-graphic-img--neutral,
    .biz-special__cta-graphic-img--negative {
        margin: 0 0 50px;
        padding: 0 0 20px;
        position: relative;
        img {
            position: relative;
            z-index: 2;
        }
    }
    .biz-special__cta-graphic-arrow--neutral,
    .biz-special__cta-graphic-arrow--negative {
        bottom: 21px;
        font-size: 0;
        left: 50%;
        margin-left: -42px;
        position: absolute;
        transform: scaleY(0.5);
        z-index: 1;
        &::after {
            border-style: solid;
            border-width: 0 2px 2px 0;
            content: "";
            display: block;
            height: 86px;
            position: absolute;
            transform: rotate(45deg);
            width: 86px;
        }
    }

    // 特選転職コラム　CTA（ノウハウ系）
    .biz-special__cta-graphic-txt--neutral {
        background: #eff9ec;
    }
    .biz-special__cta-graphic-img--neutral {
        background: #eff9ec;
        box-shadow: 0 1px 0 #bcccb8;
    }
    .biz-special__cta-graphic-arrow--neutral {
        &::after {
            background: #eff9ec;
            border-color: #bcccb8;
        }
    }

    // 特選転職コラム　CTA（産休）
    .biz-special__cta-graphic-txt--negative {
        background: #ebf0fa;
    }
    .biz-special__cta-graphic-img--negative {
        background: #ebf0fa;
        box-shadow: 0 1px 0 #c8d1e1;
    }
    .biz-special__cta-graphic-arrow--negative {
        &::after {
            background: #ebf0fa;
            border-color: #c8d1e1;
        }
    }
}
