@charset 'UTF-8';

/*
評価・労働環境一覧（2017.7.5）
---------------------------------------------------------------------------- */
.category_rating {
    /* h1見出し */
    h1 {
        background: url(../img/add_2017/review/bg_rating_title.jpg) right center no-repeat;
        span::before {
            @include font-awesome ("\f006", $white, -18px 0 0 0, 36px);
        }
    }

    /* ページ内アンカー */
    .category_rating_anchor {
        margin: 0 0 30px 1px;
        &::after {
            content: " ";
            clear: both;
            display: block;
        }
        li {
            border: 1px solid $lightgray;
            float: left;
            margin: -1px 0 0 -1px;
            width: 214px;
            a {
                background: $white;
                color: $black;
                display: block;
                line-height: 1.5;
                padding: 8px;
                text-align: center;
                width: 100%;
                &::after {
                    color: $blue;
                    content: "\e800";
                    display: block;
                    font-family: "fontello";
                    font-size: $fsize-small;
                    height: 12px;
                }
                &:hover {
                    background: #f0f4fc;
                    text-decoration: none;
                }
            }
        }
    }

    /* 企業一覧 */
    .category_rating_list {
        background: $white;
        border-color: $lightgray;
        border-style: solid;
        border-width: 0 1px 1px 1px;
        margin-bottom: 15px;
        padding: 15px 15px 20px;
        position: relative;
        h2 {
            margin: -15px -16px 15px;
        }
        .rating_more_link {
            right: 15px;
            position: absolute;
            top: 18px;
            a {
                color: $blue;
            }
            &::before {
                @include font-awesome ("\f138", $blue, 0 5px 0 0, 12px);
            }
        }
        > a {
            border-bottom: 1px solid $lightgray;
            color: $darkgray;
            display: block;
            padding: 15px;
            position: relative;
            width: 100%;
            &:last-child {
                border-bottom: none;
            }
            &:hover {
                background: #f0f4fc;
                text-decoration: none;
            }
            h3 {
                font-size: $fsize-large;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .rating_data {
                color: #ffae00;
                display: inline-block;
                font-size: $fsize-large;
                width: 330px;
                .rating_data_title {
                    color: $darkgray;
                    font-size: $fsize-small;
                    margin-right: 8px;
                }
                .rating_data_title2 {
                    color: $darkgray;
                    font-size: $fsize-small;
                }
                i {
                    font-size: $fsize-xxlarge !important;
                    height: 20px !important;
                    margin-right: 1px !important;
                    width: 20px !important;
                    &::before {
                        color: #ffae00;
                        font-size: $fsize-xxlarge;
                    }
                }
                .rating_data_value {
                    color: #ffae00;
                    font-size: $fsize-xxlarge;
                    font-weight: bold;
                    margin-left: 8px;
                }
                .rating_data_title + .rating_data_value {
                    margin-left: 0;
                }
            }
            .review_data {
                display: inline-block;
                .review_data_title {
                    margin-right: 8px;
                    &:before {
                        color: #7e7cd9;
                        content: "\e80d";
                        font-family: "fontello";
                        font-size: $fsize-large;
                        margin-right: 5px;
                    }
                }
                .review_data_value {
                    color: $blue;
                    font-size: $fsize-xxlarge;
                    font-weight: bold;
                    margin-right: 5px;
                }
            }
        }
    }
}
