@charset 'UTF-8';


@media (max-width: 767px) {

    /* ▼▼▼　2018-01 mfi対策で新たに追加されたvariables　▼▼▼ */

    // SP-レポート-共通フッタ-求人情報
    .sp-report-footer-job {
        border-color: $border--gray;
        border-style: solid;
        border-width: 1px 0;
        margin-bottom: 6px;
        .cassette-area {
            margin-top: -20px;
            padding-bottom: 20px;
        }
        .button--more-link {
            border-bottom: 1px solid $border--gray;
            margin: 0;
        }
    }
    .sp-report-footer__list-job {
        background: $white;
        li {
            border-bottom: 1px solid $border--gray;
            &:last-child {
                border-bottom: none;
            }
        }
    }
    .sp-report-footer__list-job-link {
        color: $font--black;
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        position: relative;
        &::after {
            @include font-cc($font-cc-arrow);
            font-size: 20px;
            margin-top: -10px;
            position: absolute;
            right: 10px;
            top: 50%;
        }
        img {
            width: 100%;
        }
        .sp-report-footer__list-job-image {
            margin: 0 10px 0 0;
            width: 23%;
        }
        .sp-report-footer__list-job-text {
            width: 65%;
        }
        .sp-report-footer__list-job-text-title {
            color: #429dc9;
            line-height: 1.6em;
        }
        .i--font-cc-company,
        .i--font-cc-address {
            color: $font--black;
            font-size: $fs--xsmall;
            line-height: 1.6;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            white-space: nowrap;
            &::before {
                font-size: 20px;
                margin: 0 5px 0 0;
                vertical-align: bottom;
            }
        }
    }

}
