@charset "UTF-8";

@media (max-width: 767px) {

    /*
    #13213&#13365 内部リンク拡充
    --------------------------------*/

    .main_visual_inner {
        margin-top: -105px !important;
    }

    .top_page #main_visual .main_visual_inner {
        margin-top: -82.5px !important;
    }

    #main_visual .main_visual_inner {
        margin-top: -145px !important;
    }

    .sub_page #main_visual {
        height: 300px;
    }

    #main_visual .main_visual_inner .searchform_wrap a.detail_search {
        font-size: $fsize-small;
        background: rgba(255,255,255,0.8);
        padding: 5px 0;
        margin: 20px auto 0 auto;
        border-radius: 4px;
        height: 30px;
    }

    #main_visual .main_visual_inner .job_search .searchform_wrap a.detail_search {
        margin: 0 auto 0 auto;
    }

    #main_visual .main_visual_inner .job_search .searchform_wrap .searchform {
        height: 190px;
    }

    .review .job_search {
        margin-top: 0;
    }

    .review .biz_search {
        margin-top: 0;
    }

}
