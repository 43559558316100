@charset 'UTF-8';

/*
総合トップ
---------------------------------------------------------------------------- */

#column {
    li.top_page {
        display: flex;
        flex-wrap: wrap;
        float: none;
        justify-content: space-between;
        width: 100%;

        //h2の見出し
        .toppage_intro {
            background: $white;
            border: 1px solid #e3e3e3;
            box-shadow: 0 2px 2px rgba(0,0,0,0.05);
            color: #626262;
            font-size: 12px;
            line-height: 1.6;
            margin-bottom: 27px;
            padding: 16px 20px;
            position: relative;
            h2 {
                font-size: 22px;
                margin-bottom: 10px;
                &::before {
                    display: inline-block;
                    font-family: "fontello";
                    font-weight: normal;
                    margin-right: 8px;
                }
            }
            &::after {
                background: #FFF;
                border-width: 0 1px 1px 0;
                border-style: solid;
                border-color: #e3e3e3;
                bottom: -10px;
                box-shadow: 2px 2px 2px rgba(0,0,0,0.05);
                content: "";
                display: block;
                height: 20px;
                left: 50%;
                margin-left: -10px;
                position: absolute;
                transform: rotate(45deg);
                width: 20px;
            }
        }

        //カセットのベース
        .toppage_base_post {
            color: #2f2f2f;
            display: block;
            line-height: 1.6;
            padding: 17px 20px;
            position: relative;
            width: 100%;
            h4 {
                font-size: 15px;
                font-weight: bold;
                margin-bottom: 4px;
            }
            .toppage_postmeta {
                position: relative;
            }
            .toppage_rating_data {
                align-items: center;
                display: flex;
                margin-bottom: 5px;
                .rating_data_title {
                    display: inline-block;
                    line-height: 1;
                    margin-right: 6px;
                }
                i {
                    color: $lightorange;
                    font-size: $fsize-large;
                    height: 18px;
                    line-height: 1;
                    margin-right: -0.2em;
                    width: 16px;
                }
                .rating_data_value {
                    color: $lightorange;
                    display: inline-block;
                    line-height: 1;
                    margin-left: 8px;
                }
            }
            .toppage_profile {
                color: #2f2f2f;
                font-size: 0;
                margin-bottom: 10px;
                dt {
                    display: inline-block;
                    font-size: 12px;
                    font-weight: bold;
                    &::after {
                        content: "：";
                    }
                }
                dd {
                    display: inline-block;
                    font-size: 12px;
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        //他の口コミを見るボタン
        .toppage_more_link {
            a {
                border-top: 1px solid #e3e3e3;
                color: #2f2f2f;
                display: block;
                font-size: 13px;
                font-weight: bold;
                padding: 15px;
                position: relative;
                text-align: center;
                width: 100%;
                &::after {
                    content: "\e806";
                    display: block;
                    font-family: "fontello";
                    font-size: 10px;
                    line-height: 1;
                    margin: -5px 0 0;
                    position: absolute;
                    right: 20px;
                    top: 50%;
                }
            }
        }

        ////////////////////////////
        //口コミを探すセクション
        ////////////////////////////
        .toppage_review_section {
            flex-basis: 530px;
            .toppage_review_intro {
                h2 {
                    color: #009;
                    &::before {
                        content: "\e812";
                    }
                }
            }
            h3 {
                font-size: 16px;
            }

            //口コミカセット
            .toppage_review_post {
                & + .toppage_review_post {
                    border-top: 1px solid #e3e3e3;
                }
                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .toppage_review_comment {
                    color: #2f2f2f;
                    margin-bottom: 10px;
                    word-break: break-all;
                    word-wrap: break-word;
                    a {
                        color: #5290cd;
                    }
                }
                .toppage_review_date {
                    text-align: right;
                }
                .com_comment_theme {
                    margin-top: 0;
                    right: -30px;
                    top: 0;
                }
            }

            //テーマ別のカラー設定
            .comment_theme_blue {//地域、業種
                h4 a { color: #194cb2; }
                .com_comment_theme {
                    color: white;
                    background: #1a4db2;
                    text-shadow: 1px 1px 1px #062f80;
                    &::after {
                        border-color: #062f80 transparent transparent transparent;
                        border-style: solid;
                        border-width: 10px 10px 0 0;
                        bottom: -10px;
                        content: "";
                        height: 0;
                        position: absolute;
                        right: 0;
                        width: 0;
                    }
                }
            }
            .comment_theme_gold {//年収・給与
                h4 a { color: #F7BF30; }
            }
            .comment_theme_lightgreen {//働く環境
                h4 a { color: #85DC84; }
            }
            .comment_theme_lightpurple {//やりがい
                h4 a { color: #7E7CD9; }
            }
            .comment_theme_lightorange {//出世
                h4 a { color: #FFAE00; }
            }
            .comment_theme_lightred {//ライバル企業
                h4 a { color: #EF9E81; }
            }
            .comment_theme_black {//残業・休日出勤
                h4 a { color: #606060; }
            }
            .comment_theme_lightyellow {//長所・短所
                h4 a { color: #f0ce09; }
            }
            .comment_theme_darkblue {//退職理由
                h4 a { color: #000082; }
            }
            .comment_theme_skyblue {//転職後のギャップ
                h4 a { color: #18AAEB; }
            }
            .comment_theme_lightpink {//女性のキャリア
                h4 a { color: #ea9396; }
            }
            .comment_theme_lightblue {//経営者について
                h4 a { color: #88ABDA; }
            }

            //他の条件から口コミを探す
            .topepage_review_search_list_box {
                border-top: 1px solid #e3e3e3;
                padding: 17px 20px 14px 20px;
                h4 {
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
                .topepage_review_search_first_list {
                    font-size: 0;
                    position: relative;
                    > li {
                        border-bottom: 1px solid #eee;
                        font-size: 12px;
                        line-height: 1;
                        display: flex;
                        padding: 4px 0;
                        span {
                            display: block;
                            flex-basis: 90px;
                            line-height: 1.5;
                        }
                        a {
                            color: #5290cd;
                        }
                        &:last-of-type {
                            border-bottom: none;
                            & > a::after {
                                display: none;
                            }
                        }
                        &.topepage_review_search_first_list_second_line {
                            .topepage_review_search_second_list {
                                top: 64px;
                            }
                        }
                    }
                }
                .topepage_review_search_second_list {
                    flex-basis: 398px;
                }
                .topepage_review_search_second_list,
                .topepage_review_search_rating_list {
                    font-size: 0;
                    line-height: 1;
                    > li {
                        display: inline-block;
                        font-size: $fsize-small;
                        margin-right: 0.4em;
                        a {
                            color: #5290cd;
                            line-height: 1.5;
                            &:hover {
                                text-decoration: underline;
                            }
                            &::after {
                                color: #ccc;
                                content: "|";
                                margin-left: 0.4em;
                            }
                        }
                        &:last-of-type {
                            a::after {
                                display: none;
                            }
                        }
                    }
                }
            }

            //給与明細カセット
            .toppage_salary_post {
                h4 a {
                    color: #194cb2;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .toppage_profile {
                    margin-bottom: 15px;
                }
                .nonlogin_kyuuyo {
                    a {
                        align-items: center;
                        background: url(/assets/img/add_2015/review/reviews_salary.png) left top no-repeat;
                        background-size: contain;
                        color: $black;
                        display: flex;
                        justify-content: center;
                        height: 327px;
                        margin: 0 -10px;
                        position: relative;
                        width: 508px;
                        span {
                            background: $white;
                            border: 2px solid #808080;
                            border-radius: 11px;
                            box-shadow: 0px 0px 5px rgba(73,73,73,0.45);
                            display: block;
                            font-size: 15px;
                            font-weight: bold;
                            padding: 14px;
                            text-align: center;
                            z-index: 2;
                            width: 80%;
                        }
                        &:hover {
                            span {
                                border-color: #262680;
                                color: #262680;
                            }
                            &::before {
                                background: url(/assets/img/add_2015/review/reviews_salary_over.png) left top no-repeat;
                                background-size: cover;
                                content: "";
                                display: block;
                                height: 100%;
                                left: 0;
                                position: absolute;
                                top: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            //他の口コミを見るボタン
            .toppage_more_link {
                a {
                    &:hover {
                        background: #f0f4fc;
                    }
                    &::after {
                        color: #009;
                    }
                }
            }
        }

        ////////////////////////////
        //右カラム
        ////////////////////////////
        .toppage_right_column {
            flex-basis: 530px;
        }

        ////////////////////////////
        //仕事を探すセクション
        ////////////////////////////
        .toppage_job_section {
            .toppage_job_intro {
                h2 {
                    color: #cc0000;
                    &::before {
                        content: "\e811";
                    }
                }
            }
            h3 {
                font-size: 16px;
            }

            //人気求人ランキング
            .toppage_ranking_list {
                li {
                    .toppage_ranking_post {
                        position: relative;
                        .toppage_ranking_num {
                            color: #cc0000;
                            font-size: 28px;
                            font-family: "Bebas Neue", sans-serif;
                            left: 20px;
                            line-height: 1;
                            margin-top: -14px;
                            position: absolute;
                            top: 50%;
                            &.toppage_ranking_01::after {
                                color: #DAA520;
                                content: "\e813";
                                font-size: 28px;
                                font-family: "fontello";
                                height: 28px;
                                left: -7px;
                                opacity: .5;
                                position: absolute;
                                text-align: center;
                                top: -28px;
                                width: 28px;
                            }
                        }
                        dl {
                            overflow: hidden;
                            width: 488px;
                            dt {
                                font-size: 15px;
                                font-weight: bold;
                                margin-bottom: 4px;
                                padding-left: 30px;
                            }
                            dd {
                                color: #626262;
                                overflow: hidden;
                                padding-left: 30px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                &.toppage_ranking_list_location {
                                    &::before {
                                        font-family: "FontAwesome";
                                        content: "\f041";
                                        margin: 0 5px 0 2px;
                                    }
                                }
                            }
                        }
                        &:hover {
                            background: #fcebeb;
                        }
                    }
                    & + li {
                        .toppage_ranking_post {
                            border-top: 1px solid #e3e3e3;
                        }
                    }
                }
            }

            //転職ガイド
            .toppage_jobguide_list {
                li {
                    .toppage_jobguide_post {
                        dl {
                            line-height: 1.5;
                            position: relative;
                            dt {
                                font-size: 15px;
                                font-weight: bold;
                                margin-bottom: 10px;
                                word-break: break-all;
                                word-wrap: break-word;
                            }
                            .toppage_jobguide_summary {
                                min-height: 100px;
                                padding-left: 115px;
                                position: relative;
                                word-break: break-all;
                                word-wrap: break-word;
                                .toppage_jobguide_photo {
                                    height: 100px;
                                    left: 0;
                                    position: absolute;
                                    top: 0;
                                    width: 100px;
                                    img {
                                        width: 100%;
                                        vertical-align: bottom;
                                    }
                                }
                                span {
                                    color: #5290cd;
                                }
                            }
                            .toppage_jobguide_category {
                                background: #fcebeb;
                                bottom: 0;
                                display: inline-block;
                                color: #cc0000;
                                left: 0;
                                padding: 2px 5px;
                                position: absolute;
                            }
                            .toppage_jobguide_date {
                                margin-top: 15px;
                                text-align: right;
                            }
                        }
                        &:hover {
                            background: #fcebeb;

                        }
                    }
                    & + li {
                        .toppage_jobguide_post {
                            border-top: 1px solid #e3e3e3;
                        }
                    }
                }
            }

            //転職面接
            .toppage_interview_post {
                h4 a {
                    color: #d1591c;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .toppage_interview_subtitle {
                    font-size: 12px;
                    font-weight: bold;
                    margin-bottom: 15px;
                }
                .com_comment_theme {
                    color: white;
                    background: #d1591c;
                    margin-top: 0;
                    right: -30px;
                    text-shadow: 1px 1px 1px #8e3c13;
                    top: 0;
                    &::after {
                        border-color: #8e3c13 transparent transparent transparent;
                        border-style: solid;
                        border-width: 10px 10px 0 0;
                        bottom: -10px;
                        content: "";
                        height: 0;
                        position: absolute;
                        right: 0;
                        width: 0;
                    }
                }
                .toppage_interview_data {
                    border-color: #f1a178;
                    border-style: solid;
                    border-width: 1px 1px 0 1px;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 20px;
                    dt {
                        align-items: center;
                        background: #ffeee5;
                        border-color: #f1a178;
                        border-style: solid;
                        border-width: 0 0 1px 0;
                        display: flex;
                        flex-basis: 24%;
                        position: relative;
                        span {
                            align-items: center;
                            display: flex;
                            height: 100%;
                            padding: 3px 5px;
                            width: 100%;
                        }
                        &::after {
                            background: #f1a178;
                            content: "";
                            display: block;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 1px;
                        }
                    }
                    dt:nth-of-type(2n)::before {
                        background: #f1a178;
                        content: "";
                        display: block;
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 1px;
                    }
                    dd {
                        align-items: center;
                        border-bottom: 1px solid #f1a178;
                        display: flex;
                        flex-basis: 26%;
                        span {
                            align-items: center;
                            display: flex;
                            height: 100%;
                            padding: 3px 5px;
                            width: 100%;
                        }
                    }
                }
                .toppage_interview_qa {
                    dt {
                        color: #d1591c;
                        font-weight: bold;
                        span {
                            color: #2f2f2f;
                        }
                    }
                    dd {
                        margin-bottom: 10px;
                        word-break: break-all;
                        word-wrap: break-word;
                    }
                }
                .nonlogin_interview {
                    a {
                        align-items: center;
                        background: url(/assets/img/add_2015/review/reviews.png) left top no-repeat;
                        background-size: contain;
                        color: $black;
                        display: flex;
                        justify-content: center;
                        height: 118px;
                        margin: 0 -5px;
                        position: relative;
                        width: 498px;
                        span {
                            background: $white;
                            border: 2px solid #808080;
                            border-radius: 11px;
                            box-shadow: 0px 0px 5px rgba(73,73,73,0.45);
                            display: block;
                            font-size: 15px;
                            font-weight: bold;
                            padding: 14px;
                            text-align: center;
                            z-index: 2;
                            width: 80%;
                        }
                        &:hover {
                            background-color: #fcebeb;
                            span {
                                border-color: #cc0000;
                                color: #cc0000;
                            }
                        }
                    }
                }
            }

            //他の口コミを見るボタン
            .toppage_more_link {
                a {
                    &:hover {
                        background: #fcebeb;
                    }
                    &::after {
                        color: #cc0000;
                    }
                }
            }

            .column_red {
                .icon_comment {
                    &::after {
                        background-position: 0 -372px;
                    }
                }
            }
        }

        ////////////////////////////
        //企業に関するニュースセクション
        ////////////////////////////
        .toppage_biz_section {
            margin-bottom: 30px;
            .toppage_biz_intro {
                h2 {
                    color: #20660a;
                    &::before {
                        content: "\e80d";
                    }
                }
            }
            .toppage_biz_post {
                background: $white;
                border-color: #e3e3e3;
                border-style: solid;
                border-width: 1px 1px 0;
                padding: 20px 20px 17px;
                position: relative;
                &::before {
                    background: #20660a;
                    content: "";
                    display: block;
                    height: 3px;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
                dl {
                    line-height: 1.5;
                    position: relative;
                    dt {
                        font-size: 15px;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }
                    .toppage_biz_summary {
                        word-break: break-all;
                        word-wrap: break-word;
                        span {
                            color: #5290cd;
                        }
                    }
                    .toppage_biz_category {
                        background: #eaf3e8;
                        bottom: 0;
                        color: #20660a;
                        display: inline-block;
                        left: 0;
                        padding: 2px 5px;
                        position: absolute;
                    }
                    .toppage_biz_date {
                        margin-top: 15px;
                        text-align: right;
                    }
                }
                &:hover {
                    background: #eaf3e8;
                }
            }
            .toppage_biz_more_link {
                position: relative;
                text-align: right;
                a {
                    background: $white;
                    border: 1px solid #e3e3e3;
                    &::after {
                        color: #20660a;
                    }
                    &:hover {
                        background: #eaf3e8;
                    }
                }
            }
        }
    }
}
