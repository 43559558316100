@charset 'UTF-8';

/*
おすすめ一覧（2017.7.5）
---------------------------------------------------------------------------- */
@media (max-width: 640px) {

    .sub_page.biz #column>li.column_2 {

        /* カテゴリーリスト */
        .biz-list {
            padding: 15px 10px;

            h3 {
                font-size: 15px;
                min-height: auto;
            }
        }
    }
}
