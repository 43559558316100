@charset 'UTF-8';

.sub_page.biz #column>li.column_2 {

    /* インデックス */
    .biz-index {
        background: $white;
        border: 1px solid $lightgray;
        padding: 15px;
        margin-bottom: 25px;
        & > h1 {
            color: $green;
            font-size: $fsize-xxlarge;
            font-weight: bold;
            margin-bottom: 15px;
        }
    }

    // カテゴリー一覧
    .biz-cat-area {
        margin-bottom: 0;
    }
    .biz-cat-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
    }
    .biz-cat-item {
        border-top: 1px solid $lightgray;
        flex-basis: 50%;
        margin-top: 5px;
        padding-top: 12px;
        &:nth-of-type(1),
        &:nth-of-type(2) {
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }
        &:nth-of-type(odd) {
            .biz-cat-item__body {
                padding-right: 10px;
            }
        }
        &:nth-of-type(even) {
            .biz-cat-item__body {
                padding-left: 10px;
            }
        }
    }
    .biz-cat-item__body {
        height: 100%;
        padding-bottom: 40px;
        position: relative;
    }
    .biz-cat-item__header {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .biz-cat-item__header-title {
        color: $green;
    }
    .biz-cat-item__post {
        align-content: space-between;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .biz-cat-item__post-thumb {
        flex-basis: 25%;
        img {
            width: 100%;
        }
    }
    .biz-cat-item__post-title {
        flex-basis: 70%;
        a {
            display: block;
            color: $darkgray;
            font-size: 15px;
            font-weight: bold;
        }
    }
    .biz-cat-item__post-sns {
        bottom: 45px;
        position: absolute;
        width: 294px;
    }
    .biz-cat-item__more-link {
        bottom: 0;
        display: table;
        height: 40px;
        position: absolute;
        width: 294px;
        a {
            color: $green;
            display: table-cell;
            position: relative;
            text-align: right;
            vertical-align: middle;
            &::after {
                content: "\e802";
                display: inline-block;
                margin-left: 5px;
                font-family: "fontello";
            }
        }
    }
    .biz_thumbnail {
        aspect-ratio: 1;
        overflow: hidden;
    }
}
