///
// job/recruit 配下
///
@media (max-width: 640px) {
    .pageRecruit--apply {
        width: auto;
        margin: 0;
        padding: 0 10px;
    }

    /// ヘッダー注記部
    .recruitApplyHeader__notice {
        font-size: 12px;
        line-height: 1.5;
    }
    .recruitApplyHeader__onSp {
        display: block;
    }

    /// 求人情報概要部
    .recruitJobOutline__logo {
        display: none;
    }
    .recruitJobOutline__jobTitle {
        font-size: 14px;
    }

    /// CTA部
    .recruitApplyCtaBtns {
        flex-direction: column;
    }

    /// アドバイス部
    .recruitApplyTips {
        width: 100%;
        padding: 20px;
    }

    /// キャリシート情報部
    .recruitApplyCslist__data {
        tr {
            display: flex;
            flex-direction: column;

            &:last-of-type {
                td {
                    border-bottom: 1px solid $border--gray2;
                }
            }
        }
        th, td {
            border-bottom: none;
        }
    }
}
