@charset "UTF-8";

@mixin content-bg-contain {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
}

@mixin font-awesome ($char, $color, $margin, $font-size) {
    font-family: FontAwesome;
    content: $char;
    color: $color;
    margin: $margin;
    font-size: $font-size;
}

/* clearFix */
@mixin clearfix {
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

@mixin sp_link_arrow {
    color: #009;
    content: "\e806";
    display: block;
    font-family: "fontello";
    font-size: 10px;
    line-height: 1;
    margin: -5px 0 0;
    position: absolute;
    right: 10px;
    top: 50%;
}

/* ▼▼▼　2018-01 mfi対策で新たに追加されたmixins　▼▼▼ */

@mixin cf {
    &::after {
        content: "";
        display: block;
        clear: both;
    }
}

@mixin font-cc ($char) {
    font-family: font-cc;
    content: $char;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin transition($property: all, $duration: 0.25s, $timing: ease-in-out, $delay: 0s) {
    transition: $property $duration $timing $delay;
}

@mixin translate($posx, $posy) {
    transform: translate($posx, $posy);
}

@mixin sp-link-arrow-before {
    @include awesome($fa-chevron-right);
    color: $blue;
    display: block;
    font-size: $fs--xxxsmall;
    line-height: 1;
    margin: -5px 0 0 0;
    position: absolute;
    left: 10px;
    top: 50%;
}

@mixin sp-link-arrow-after {
    @include font-cc($font-cc-arrow);
    color: $font--link--skyblue;
    display: block;
    font-size: 20px;
    line-height: 1;
    margin: -10px 0 0 0;
    position: absolute;
    right: 10px;
    top: 50%;
}

@mixin text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
