@charset 'UTF-8';

@media (max-width: 640px) {
    .sub_page #column>li.column_large.award {

        //インデックスページ
        .award-index-area {
            padding: 15px 10px;
        }
        .award-index-area__title {
            margin: 0;
            font-size: 15px;
        }
        .award-index-area__list-item {
            flex-direction: column;
            padding: 15px 0;
            font-size: 15px;
        }
        .award-index-area__list-date {
            width: 100%;
            margin: 0 0 4px;
            font-size: $fsize-small;
        }
        .award-index-area__list-title {
            width: 100%;
        }

        //記事ページ
        .award-article-area {
            padding: 15px 10px 30px;
        }
        .award-article-area__header {
            margin: 0 0 20px;
        }
        .award-article-area__header-title {
            margin: 0 0 10px;
            font-size: 20px;
            line-height: 1.6;
        }
        .award-article-area__btn {
            margin-top: 2em;
            padding: 12px 40px 12px 12px;
            font-size: $fs--small;
        }
    }
}