@charset 'UTF-8';

/*
評価・労働環境一覧（2017.7.5）
---------------------------------------------------------------------------- */
@media (max-width: 640px) {

    .category_rating {
        /* ページ内アンカー */
        .category_rating_anchor li {
            width: 50%;
            a::after {
                font-size: $fsize-xxsmall;
            }
        }

        /* 企業一覧 */
        .category_rating_list {
            background: transparent;
            border: none;
            margin-top: -60px;
            padding-top: 60px;
            h2 {
                margin: -15px -15px 0;
            }
            .rating_more_link {
                margin: 0 -15px;
                position: relative;
                right: auto;
                top: auto;
                width: auto;
                a {
                    background: $white;
                    border-color: $lightgray;
                    border-style: solid;
                    border-width: 0 1px 1px 1px;
                    display: block;
                    padding: 13px;
                    text-align: center;
                    width: 100%;
                }
                &::before {
                    @include sp_link_arrow;
                }
            }
            > a {
                background: $white;
                border-color: $lightgray;
                border-style: solid;
                border-width: 0 1px 1px 1px;
                margin: 0 -15px;
                padding: 10px 20px 10px 10px;
                width: auto;
                &::after {
                    @include sp_link_arrow;
                }
                h3 {
                    font-size: $fsize-small-middle;
                    margin-bottom: 6px;
                }
                .rating_data {
                    font-size: $fsize-small;
                    width: 48%;
                    .rating_data_title {
                        display: none;
                    }
                    .rating_data_title2 {
                        font-size: $fsize-xxsmall;
                    }
                    i {
                        font-size: $fsize-middle !important;
                        height: 14px !important;
                        margin-right: 0 !important;
                        width: 14px !important;
                        &::before {
                            font-size: $fsize-middle;
                        }
                    }
                    .rating_data_value {
                        font-size: $fsize-small-middle;
                        margin-left: 5px;
                    }
                }
                .review_data {
                    .review_data_title {
                        font-size: $fsize-xxsmall;
                        margin-right: 5px;
                        &::before {
                            display: none;
                        }
                    }
                    .review_data_value {
                        font-size: $fsize-small-middle;
                    }
                }
            }
        }
    }
}
