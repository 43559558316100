@charset 'UTF-8';

// 転職面接レポート

.sub_page {

    // first area
    #column>li.column_2.review_sub {
        .interview_data {
            .data_top,
            .interview_graph {
                float: none;
                width: 49%;
                display: inline-block;
                vertical-align: top;
            }
            .data_top {
                width: 40%;
                margin: 15px 0 0 0;
            }
            .interview_graph {
                width: 59%;
                dd {
                    .graph_box {
                        width: 100%;
                        canvas,
                        .transitive_graph.transitive_graph_bar {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .transitive_graph.transitive_graph_bar {
                            margin: 0;
                            text-align: center;
                        }
                    }
                    .on_graph {
                        position: absolute;
                        left: 30%;
                        top: 24%;
                        font-weight: bold;
                        font-size: 16px;
                        padding: 15px 20px;
                        border: #2F2F2F 1px solid;
                        width: 210px;
                        text-align: center;
                    }
                }
            }
        }
    }

    // second area
    #column>li.column_2 {
        .com_comment {
            .com_comment_innerL {
                .com_comment_text {
                    clear: both;
                }
            }
        }

        .transitive_graph {
            p {
                &:first-child {
                    margin-right: 10px;
                }
                &:nth-child(2) {
                    color: $yellow;
                }
                span {
                    background: $yellow;
                    opacity: 1.0;
                    &:before, &:after {
                        background: $yellow;
                        opacity: 1.0;
                    }
                }
            }
        }

    }

}


/*
.sub_page #column>li.column_2.review_sub .interview_data .interview_graph dd .graph_box {
    width: 100px;
    //width: 500px;
    margin: 0 auto;
    position: relative;
}


.sub_page #column>li.column_2.review_sub .interview_data .interview_graph dd .graph_box canvas {
    width: 140px !important;
    //width: 440px !important;
    height: 190px !important;
    margin: 0 auto;
    display: block;
}


.sub_page #column>li.column_2.review_sub .interview_data .interview_graph dd .graph_box .transitive_graph {
    //margin: -20px 0 0 40px;
    //text-align: center;
    width: 100px;
}
*/
