@charset 'UTF-8';

/*
業界一覧（2017.7.5）
---------------------------------------------------------------------------- */
.category_study_occupation {
    /* h1見出し */
    h1 {
        background: url(../img/review/content/bg_study_occupation_title.jpg) left center no-repeat;
        span::before {
            @include font-awesome ("\f02d", $white, -16px 0 0 0, 32px);
        }
    }

    /* 業界から探す */
    .category_study_occupation_navi {
        background: $white;
        border-color: $lightgray;
        border-style: solid;
        border-width: 0 1px 1px 1px;
        padding: 0 15px 20px 15px;
        dl {
            display: table;
            position: relative;
            width: 100%;
            &::after {
                background: $lightgray;
                bottom: 0;
                content: "";
                display: block;
                height: 1px;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }
        dt {
            display: table-cell;
            font-size: $fsize-small-middle;
            font-weight: bold;
            padding-top: 12px;
            vertical-align: top;
            width: 128px;
        }
        dd {
            display: table-cell;
            ul::after {
                content: " ";
                clear: both;
                display: block;
            }
            li {
                border-bottom: 1px solid #f5f5f5;
                display: table;
                float: left;
                line-height: 1.5;
                position: relative;
                width: 33.333%;
                &::after {
                    border-color: transparent transparent transparent #3d32db;
                    border-style: solid;
                    border-width: 5px 0 5px 6px;
                    content: "";
                    display: block;
                    height: 0;
                    left: 10px;
                    margin-top: -6px;
                    position: absolute;
                    top: 50%;
                    width: 0;
                }
                a {
                    color: $black;
                    display: table-cell;
                    height: 40px;
                    padding: 0 10px 0 24px;
                    vertical-align: middle;
                    width: 100%;
                    &:hover {
                        background: #f0f4fc;
                    }
                }
            }
        }
    }
}
