@charset 'UTF-8';

/* Colors */
$bgcolor: #FAFAFA; // 背景色
$white: #FFF; // 白
$black: #000; // 黒
$gray: #626262; // 灰色
$lightgray: #E3E3E3; // 薄い灰色
$textlightgray: #949494; // 薄い灰色（テキスト用）
$darkgray: #2F2F2F; // 濃い灰色
$blue: #000099; // 青
$blue2: #5290cd;
$yellow: #fa0; // 黄色
// $red  : #A64311; // 赤
//$red  : #b64207; // 赤
$red: #c00; // 赤
$darkred: #900; // 濃い赤　22行目
$darkgold: #b8860b; // 暗い金
$green: #20660A; // 緑
$purple: #26267F; // 紫
$lightpurple: #7E7CD9; // 薄い紫
$darkpurple: #2F2F59; // 濃い紫
$orange: #D1591C; // オレンジ
$mint: #1597B7; // ミント (マイページ)
$lightblue: #88ABDA; // 水色
$lightred: #EF9E81; // ピンク
$lightgreen: #85DC84; // 黄緑

//テーマのために追加
$lightorange: #FFAE00;
$lightpink: #ea9396;
$lightblack: #606060;
$lightyellow: #f0ce09;
$lightorange: #FFAE00;
$darkblue : #000082; // 濃い青
$lightgold: #FFAE00; // 明るい金
$gold: #DAA520; // 金
$silver: #C0C0C0; // 銀
$bronze: #B6603D; // 銅
$skyblue: #18AAEB; // 水色

// font size
$fsize-xxsmall: 10px;
$fsize-xsmall :11px;
$fsize-small: 12px;
$fsize-small-middle: 13px;
$fsize-middle: 14px;
$fsize-large: 16px;
$fsize-xlarge: 18px;
$fsize-xxlarge: 20px;
$fsize-xxxlarge: 22px;
$fsize-xxxxlarge: 24px;
$fsize-xxxxxlarge: 26px;

// img path
//$user-inc-fg-pc-img-path: '/assets/img/jobseeker/';
//$user-inc-sp-img-path: '/assets/img/smartphone/';

// Font-Awesome
$fa-heart-o: '\f08a';
$fa-check-square-o: '\f046';

/* ▼▼▼　2018-01 mfi対策で新たに追加されたvariables　▼▼▼ */

// カラー
$blue--light: #00f;
$gray--dark: #2f2f2f;
$gray--light: #e3e3e3;

// フォント--カラー
$font--black: #333; // テキスト(黒)
$font--black--light: #666; // テキスト(薄い黒)
$font--gray--light: #999; // テキスト(薄い灰色)
$font--blue--review: #1142a6; // reviewディレクトリの青色テキスト
$font--red--review: #e61739; // reviewディレクトリの赤色テキスト
$font--link--skyblue: #3d9ccb; // リンクの水色テキスト
$font--link--blue: #1142a6; // リンクの青色テキスト
$font--link--blue--hover: #3071f1; // リンクの青色テキスト（ホバー時）
$font--placeholder: #bbb; // プレースホルダー

// 背景--カラー
$bg--gray: #f5f5f5; // サイト全体の背景色
$bg--blue: #859dcc; // 青色の背景色
$bg--blue--light: #edeffa; // 薄い青色の背景色
$bg--blue--light--hover: #f5f6fc; // 薄い青色の背景色（ホバー時）
$bg--yellow--light: #fffbd9; // 薄い黄色の背景色
$bg--red--light: #fff2f4; // 薄い赤色の背景色
$bg--link--skyblue--light: #edf6fa; // 薄い水色の背景色（リンク）
$bg--link--blue--light: #edeffa; // 薄い青色の背景色（リンク）
$bg--link--blue: #5782d9; // 青色の背景色（リンク）
// #19361 「キャリコネとは」で使用するため、古いvariablesにも追加
$bg--link--regist: #ffcc00; // 黄色の背景色（リンク）
$bg--link--regist--hover: #ffdd00; // 黄色の背景色（リンクのホバー時）

// ボーダー--カラー
$border--gray: #e5e5e5; // 枠線の灰色
$border--gray2: #ccc; // 枠線の灰色
$border--blue: #cfd6e5; // 枠線の青色
$border--blue--hover: #dee3ec; // 枠線の青色（ホバー時）
$border--yellow: #f1ddb5; // 枠線の黃色
$border--red: #e5cfd2; // 枠線の赤色
$border--link--skyblue: #89c6e5; // 枠線の水色（リンク）
// #19361 「キャリコネとは」で使用するため、古いvariablesにも追加
$border--link--regist: #e5a800; // 枠線の黄色（リンク）
$border--link--regist--hover: #e5b800; // 枠線の黄色（ホバー時）

// フォント--サイズ（9px以下、19px以上は個別指定）
$fs--xxxlarge: 18px;
$fs--xxlarge: 17px;
$fs--xlarge: 16px;
$fs--large: 15px;
$fs--medium: 14px;
$fs--small: 13px;
$fs--xsmall: 12px;
$fs--xxsmall: 11px;
$fs--xxxsmall: 10px;

// Font Awesome
$fa-angle-right: "\f105";
$fa-angle-left: "\f104";
$fa-arrow-down: "\f063";
$fa-arrow-left: "\f060";
$fa-arrow-right: "\f061";
$fa-arrow-up: "\f062";
$fa-bars: "\f0c9";
$fa-bell-o: "\f0a2";
$fa-book: "\f02d";
$fa-briefcase: "\f0b1";
$fa-building-o: "\f0f7";
$fa-caret-right: "\f0da";
$fa-chevron-circle-down: "\f13a";
$fa-chevron-circle-left: "\f137";
$fa-chevron-circle-right: "\f138";
$fa-chevron-circle-up: "\f139";
$fa-chevron-down: "\f078";
$fa-chevron-left: "\f053";
$fa-chevron-right: "\f054";
$fa-chevron-up: "\f077";
$fa-close: "\f00d";
$fa-comment-o: "\f0e5";
$fa-envelope: "\f0e0";
$fa-exclamation-circle: "\f06a";
$fa-flag: "\f024";
$fa-history: "\f1da";
$fa-home: "\f015";
$fa-lightbulb-o: "\f0eb";
$fa-list-alt: "\f022";
$fa-map-marker: "\f041";
$fa-male: "\f183";
$fa-minus: "\f068";
$fa-pencil: "\f040";
$fa-plus: "\f067";
$fa-suitcase: "\f0f2";
$fa-search: "\f002";
$fa-shield: "\f132";
$fa-star-o: "\f006";
$fa-times-circle: "\f057";
$fa-times: "\f00d";
$fa-thumbs-o-up: "\f087";
$fa-thumbs-up: "\f164";
$fa-user: "\f007";

// Font CC（ソートはIcoMoonの仕様に合わせる）
$font-cc-arrow: "\e900";
$font-cc-review: "\e901";
$font-cc-job: "\e902";
$font-cc-search: "\e903";
$font-cc-star: "\e904";
$font-cc-star_half: "\e905";
$font-cc-star_empty: "\e906";
$font-cc-interest: "\e907";
$font-cc-post: "\e908";
$font-cc-good: "\e909";
$font-cc-url: "\e90a";
$font-cc-industry: "\e90b";
$font-cc-address: "\e90c";
$font-cc-theme_salary: "\e90d";
$font-cc-theme_welfare: "\e90e";
$font-cc-theme_worth: "\e90f";
$font-cc-theme_success: "\e910";
$font-cc-theme_rival: "\e911";
$font-cc-theme_overtime: "\e912";
$font-cc-theme_strengths: "\e913";
$font-cc-theme_reason: "\e914";
$font-cc-theme_gap: "\e915";
$font-cc-theme_woman: "\e916";
$font-cc-theme_manager: "\e917";
$font-cc-acdopen: "\e918";
$font-cc-acdclose: "\e919";
$font-cc-diagnosis: "\e91a";
$font-cc-application: "\e91b";
$font-cc-cross: "\e91c";
$font-cc-question: "\e91d";
$font-cc-circle: "\e91e";
$font-cc-company: "\e91f";
$font-cc-menu: "\e920";
$font-cc-pagetop: "\e921";
$font-cc-arrow_salary: "\e922";
$font-cc-ranking: "\e923";
$font-cc-home: "\e924";
$font-cc-news: "\e925";
$font-cc-login: "\e926";
$font-cc-logout: "\e927";
$font-cc-mypage: "\e928";
$font-cc-twitter: "\e929";
$font-cc-facebook: "\e92a";
$font-cc-equal: "\e92b";
$font-cc-stress: "\e92c";
$font-cc-calendar: "\e92d";
$font-cc-smileman: "\e92e";
$font-cc-smilewoman: "\e92f";
$font-cc-sadman: "\e930";
$font-cc-sadwoman: "\e931";
$font-cc-attribute: "\e932";
