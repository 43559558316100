@charset 'UTF-8';

/*
投稿者属性一覧（2018.1.30）
---------------------------------------------------------------------------- */
@media (max-width: 640px) {

    .category_attribute {
        /* h1見出し */
        h1 {
            span::before {
                font-size: $fsize-xxlarge;
                height: 20px;
                margin-top: -10px;
                width: 20px;
            }
        }

        .category_attribute_navi {
            background: #f5f5f5;
            padding: 0;
            dl {
                display: block;
                margin-top: -1px;
                &::after {
                    display: none;
                }
            }
            dt {
                background: $white;
                border-top: 1px solid $lightgray;
                display: block;
                min-height: 44px;
                padding: 14px 30px 14px 10px;
                position: relative;
                width: 100%;
                &::before {
                    background: #444;
                    content: "";
                    display: block;
                    height: 2px;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    width: 10px;
                }
                &::after {
                    background: #444;
                    content: "";
                    display: block;
                    height: 10px;
                    margin-top: -4px;
                    position: absolute;
                    right: 14px;
                    top: 50%;
                    width: 2px;
                }
                &.is_open::after {
                    display: none;
                }
            }
            dd {
                border-top: 1px solid $lightgray;
                display: none;
                ul {
                    width: 100%;
                }
                li {
                    border-bottom: none;
                    width: 50%;
                    &::after {
                        display: none;
                    }
                    a {
                        background: $white;
                        border-bottom: 1px solid $lightgray;
                        color: $darkgray;
                        display: table-cell;
                        height: 44px;
                        padding: 0 24px 0 10px;
                        position: relative;
                        vertical-align: middle;
                        width: 100%;
                        &::after {
                            @include sp_link_arrow;
                        }
                    }
                    &:nth-child(odd) a {
                        border-right: 1px solid $lightgray;
                    }
                }
            }
        }
    }
}
