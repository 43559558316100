@charset 'UTF-8';

.sub_page {
    #column>li.column_2 {
        .footer_column_1704 {
            .footer_inner {
                h3 {
                    a {
                        font-weight: bold;
                        font-size: 13px;
                        color: #000;
                        position: static;
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
