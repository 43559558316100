@charset "UTF-8";


/*
#13213&#13365 内部リンク拡充
--------------------------------*/
.sub_page #column>li.column_3 .access_rank_title {
    height: auto;
    line-height: 1.75em;
    padding: 10px 0;
}
