@charset 'UTF-8';

/*
企業トップ overview
---------------------------------------------------------------------------- */

.sub_page {
    #column {
        > li.column_2 {

            //平均データ
            .avg_data {
                padding-top: 10px;
                .avg_left {
                    height: 240px;
                    overflow: hidden;
                    width: 350px;
                    .chart_box_detail {
                        height: 320px;
                        position: relative;
                        width: 320px;
                        canvas {
                            margin-top: -50px;

                        }
                        .rate_num {
                            color: $blue;
                            font-size: $fsize-middle;
                            font-weight: bold;
                            left: 0;
                            position: absolute;
                            top: 0;
                            .rate_num_data {
                                position: absolute;
                                width: 30px;
                            }
                            .rate_num_1 {
                                left: 85px;
                                text-align: center;
                                top: -5px;
                            }
                            .rate_num_2 {
                                left: 178px;
                                text-align: left;
                                top: 55px;
                            }
                            .rate_num_3 {
                                left: 178px;
                                text-align: left;
                                top: 155px;
                            }
                            .rate_num_4 {
                                left: 85px;
                                text-align: center;
                                top: 198px;
                            }
                            .rate_num_5 {
                                left: -8px;
                                text-align: right;
                                top: 155px;
                            }
                            .rate_num_6 {
                                left: -8px;
                                text-align: right;
                                top: 55px;
                            }
                        }
                        .no_rate {
                            align-items: center;
                            background: rgba(250, 250, 250,0.5);
                            display: flex;
                            font-size: $fsize-small;
                            font-weight: bold;
                            height: 320px;
                            justify-content: center;
                            left: -5px;
                            line-height: 1.5;
                            position: absolute;
                            text-align: center;
                            text-shadow: 0 0 10px $white;
                            top: 0;
                            width: 320px;
                        }
                    }
                }
                .avg_right {
                    float: right;
                    width: 290px;
                    .income_data {
                        background: $white;
                        border: 1px solid $lightgray;
                        border-radius: 4px;
                        margin-bottom: 15px;
                        padding: 10px;
                        .avg_income_data {
                            border-bottom: 1px solid $lightgray;
                            font-size: 0;
                            font-weight: bold;
                            line-height: 1;
                            padding-bottom: 10px;
                            text-align: center;
                            dt {
                                display: inline-block;
                                font-size: $fsize-large;
                                line-height: 1.5;
                                margin-right: 5px;
                            }
                            .icon-wallet {
                                color: $lightpurple;
                            }
                            dd {
                                display: inline-block;
                                font-size: $fsize-large;
                                line-height: 1.5;
                                span {
                                    color: $blue;
                                    font-size: 28px;
                                }
                            }
                        }
                        .highest_income_data {
                            display: flex;
                            font-size: $fsize-small;
                            margin-top: 11px;
                            dt {
                                color: $lightpurple;
                                flex-basis: 25%;
                                font-weight: bold;
                                line-height: 1.5;
                                text-align: center;
                            }
                            dd {
                                border-left: 1px solid $lightgray;
                                flex-basis: 25%;
                                line-height: 1.5;
                                text-align: center;
                                .highest_income_age {
                                    display: block;
                                }
                                a {
                                    color: $lightpurple;
                                }

                            }
                        }
                    }
                    .overtime_data ,
                    .holiday_work_data {
                        float: left;
                        font-size: 0;
                        line-height: 1;
                        font-weight: bold;
                        margin-bottom: 5px;
                        position: relative;
                        width: 50%;
                        &::before {
                            background-image: url(/assets/img/parts-retina-s5c8ea66d51.png);
                            background-repeat: no-repeat;
                            background-size: 170px auto;
                            bottom: 2px;
                            content: "";
                            display: block;
                            height: 20px;
                            left: 10px;
                            position: absolute;
                            width: 20px;
                        }
                        dt {
                            font-size: $fsize-small;
                            line-height: 1.5;
                            margin: 0 0 5px;
                            position: relative;
                            padding: 0 10px;
                            text-align: center;
                        }
                        dd {
                            font-size: $fsize-small;
                            line-height: 20px;
                            padding: 0 10px;
                            text-align: center;
                            span {
                                color: $blue;
                                font-size: $fsize-xlarge;
                            }
                        }
                    }
                    .overtime_data {
                        border-right: 1px solid $lightgray;
                        &::before {
                            background-position: 0 -202px;
                        }
                    }
                    .holiday_work_data::before {
                        background-position: 0 -182px;
                    }
                }
            }

            //口コミをテーマ・職種から探す
            .overview_themes_box {
                .overview_category_list {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 40px;
                    dt {
                        flex-basis: 10%;
                        font-weight: bold;
                        padding-top: 4px;
                        &::after {
                            content: "：";
                        }
                    }
                    dd {
                        flex-basis: 90%;
                        ul {
                            font-size: 0;
                            li {
                                border: 1px solid #d4d4d4;
                                border-radius: 4px;
                                display: inline-block;
                                font-size: $fsize-small;
                                margin: 0 4px 4px 0;
                                text-align: center;
                                &.activate a {
                                    background: #2a2184;
                                    color: $white;
                                }
                                &.mod_nodata {
                                    background: #eee;
                                    color: #c2c2c2;
                                    padding: 2px 10px;
                                }
                            }
                        }
                        .overview_category_themes_list {
                            margin-bottom: 16px;
                        }
                        a {
                            background: $white;
                            border-radius: 4px;
                            color: #333;
                            display: block;
                            font-weight: normal;
                            padding: 2px 10px;
                            &:hover {
                                background: #2a2184;
                                color: $white;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            //口コミカセット
            .com_comment {
                .com_comment_inner {
                    .com_comment_text {
                        word-wrap: break-word;
                        word-break: break-all;
                    }
                }
            }

            //職種別口コミ
            .comment_job_box {
                .comment_job_blue {
                    &::before {
                        content: "";
                        display: block;
                        clear: both;
                    }
                    .com_comment_theme {
                        color: white;
                        background: #194cb2;
                        text-shadow: 1px 1px 1px #062f80;
                        &::after {
                            content: "";
                            position: absolute;
                            right: 0;
                            bottom: -10px;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 10px 10px 0 0;
                            border-color: #062f80 transparent transparent transparent;
                        }
                    }
                    .com_comment_inner {
                        border-top: #1a4db2 2px solid !important;
                    }
                    .com_comment_info {
                        span {
                            a {
                                color: #194cb2 !important;
                            }
                        }
                    }
                    .com_comment_text {
                        a {
                            color: #194cb2 !important;
                        }
                    }
                }
                .more_link {
                    margin: 15px 0 25px;
                    text-align: right;
                    a::before {
                        content: "\e802";
                        display: inline-block;
                        margin-right: 5px;
                        font-family: "fontello";
                        font-weight: normal !important;
                        transition: all .25s ease-in-out 0s;
                    }
                    a:hover::before {
                        transform:translate(3px, 0);
                    }
                }
            }

            //企業情報の導入文
            .overview_company_intro {
                margin: 20px 0 17px;
                .overview_company_intro_txt {
                    height: 42px;
                    overflow: hidden;
                    visibility: visible;
                }
                .overview_company_intro_toggle {
                    color: #5290cd;
                    display: none;
                }
            }

            //社員の状況などの企業情報
            .overview_company_info_box {
                margin-top: 20px;
                > li {
                    margin-bottom: 10px;
                    h3 {
                        background: $white;
                        border: 1px solid $lightgray;
                        color: $darkgray;
                        cursor: pointer;
                        font-size: $fsize-middle;
                        font-weight: bold;
                        line-height: 1.5;
                        padding: 7px 10px;
                        position: relative;
                        span {
                            padding-left: 10px;
                            position: relative;
                            &::before {
                                background: $lightpurple;
                                content: "";
                                display: block;
                                height: 16px;
                                left: 0;
                                margin-top: -8px;
                                position: absolute;
                                top: 50%;
                                width: 3px;
                            }
                        }
                        &::before {
                            background: #444;
                            content: "";
                            display: block;
                            height: 2px;
                            margin-top: -1px;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            width: 10px;
                        }
                        &::after {
                            background: #444;
                            content: "";
                            display: block;
                            height: 10px;
                            left: auto;
                            margin-top: -5px;
                            position: absolute;
                            right: 14px;
                            top: 50%;
                            width: 2px;
                        }
                        &.is_open::after {
                            display: none;
                        }
                        &:hover {
                            background: #eff4fd;
                        }
                    }
                    .overview_company_info_list {
                        background: $white;
                        border-color: $lightgray;
                        border-style: solid;
                        border-width: 0 1px 1px;
                        padding: 5px 15px;
                        dl {
                            display: -ms-flexbox;
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                            dt {
                                align-items: center;
                                border-top: 1px solid $lightgray;
                                color: #262680;
                                flex-basis: 25%;
                                font-weight: bold;
                                padding: 8px 0;
                                &:first-of-type {
                                    border: none;
                                }
                                span {
                                    display: inline-block;
                                    padding-right: 10px;
                                }
                            }
                            dd {
                                align-items: center;
                                border-top: 1px solid $lightgray;
                                flex-basis: 75%;
                                padding: 8px 0;
                                &:first-of-type {
                                    border: none;
                                }
                            }
                            &.overview_company_info_employee {
                                dt {
                                    display: -ms-flexbox;
                                    display: flex;
                                }
                                dd {
                                    display: -ms-flexbox;
                                    display: flex;
                                    flex-basis: 24%;
                                }
                                dt:nth-of-type(even) {
                                    margin-left: 1%;
                                }
                                dd:nth-of-type(odd) {
                                    margin-right: 1%;
                                }
                                dt:nth-of-type(1),
                                dt:nth-of-type(2),
                                dd:nth-of-type(1),
                                dd:nth-of-type(2) {
                                    border-top: none;
                                }
                            }
                            &.overview_company_info_recruit_new,
                            &.overview_company_info_recruit_career,
                            &.overview_company_info_recruit_mid,
                            &.overview_company_info_recruit_others {
                                margin-top: 8px;
                                dt, dd {
                                    border-top: none;
                                    padding: 8px 0 0;
                                    &:first-of-type {
                                        border-top: 1px solid $lightgray;
                                    }
                                }
                                &:first-of-type {
                                    margin-top: 0 !important;
                                    dt, dd {
                                        border-top: none !important;
                                    }
                                }
                                &:last-of-type {
                                    margin-bottom: 8px;
                                }
                            }
                        }
                    }
                }
            }
            .overview_company_info_note {
                color: #8c8c8c;
                font-size: $fsize-xsmall;
            }

            //業績情報
            .overview_performance_box {
                background: $white;
                border: 1px solid $lightgray;
                padding: 0 15px 20px;
                .sec_ttl {
                    margin: 0 -20px 10px;
                    padding-left: 15px;
                }
                .overview_performance_inbox {
                    justify-content: space-between;
                    display: flex;
                    margin-bottom: 10px;
                    dl {
                        display: flex;
                        flex-basis: 294px;
                        flex-wrap: wrap;
                    }
                    dt {
                        align-items: center;
                        border-bottom: 1px solid $lightgray;
                        color: #262680;
                        flex-basis: 40%;
                        font-weight: bold;
                        padding: 8px 0;
                        span {
                            display: inline-block;
                            padding-right: 10px;
                        }
                    }
                    dd {
                        align-items: center;
                        border-bottom: 1px solid $lightgray;
                        flex-basis: 60%;
                        padding: 8px 0;
                    }
                }
                .overview_company_performance_note {
                    color: #8c8c8c;
                    font-size: $fsize-xsmall;
                }
                .btn {
                    display: block;
                    margin: 10px auto 0;
                    width: 300px;
                }
            }

            //関連記事
            .overview_media_box {
                background: $white;
                border: 1px solid $lightgray;
                padding: 0 20px 20px;
                .sec_ttl {
                    margin: 0 -20px 20px !important;
                    padding-left: 15px;
                }
                .overview_media_list {
                    border: 1px solid $lightgray;
                    display: table;
                    flex-wrap: wrap;
                    width: 100%;
                    li {
                        display: table-cell;
                        font-size: $fsize-middle;
                        font-weight: bold;
                        text-align: center;
                        width: 20%;
                        &.mod_nodata {
                            background: #f5f5f5;
                            border-left: 1px solid $lightgray;
                            color: #c2c2c2;
                            padding: 12px 0;
                        }
                        a {
                            border-left: 1px solid $lightgray;
                            color: $darkgray;
                            display: block;
                            padding: 12px 0;
                            width: 100%;
                            &:hover {
                                background: #eff4fd;
                                text-decoration: none;
                            }
                        }
                        &:nth-of-type(1) {
                            &.mod_nodata, a {
                                border-left: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
