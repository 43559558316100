@charset 'UTF-8';

/*
overview配下のローカルナビ

【変更履歴】
#12768 & #13220【キャリコネ】【Markeship】h1タグの修正【施策ID:1-4 & 施策ID:27-1】
#13643【キャリコネ】【実装】corpDetail廃止とoverviewへ企業情報追加
---------------------------------------------------------------------------- */

@media (max-width: 640px) {

    .company_list {
        .list_2 {
            li {
                p {
                    &:last-child {
                        display: block;
                        margin-top: -13px;
                        line-height: 1.25em;
                    }
                }
                p.kensuu-num {
                    display: none;
                }
            }
        }
    }

    .company_information { // #13717 reviewディレクトリの各企業配下のスマホページのUI改善
        .rating_total {
            .star2 {
                .marks, .max_marks, .data_none {
                    display: none;
                }
                .point {
                    color: #006;
                    font-weight: bold;
                    font-size: 16px;
                    margin: 0 -0.2em;
                }
            }
        }
    }

}


@media (max-width: 767px) {

    .company_information { // #13717 reviewディレクトリの各企業配下のスマホページのUI改善

        h1 {
            padding: 0;
        }

        .company_information-heading {
            padding: 0;
        }

        .rating_total {
            margin: 10px 0;
            .star2 {
                .all_raring {
                    margin-right: 2px;
                }
                i {
                    margin-left: -4px;
                    font-size: 14px;
                }
                a.favorite {
                    margin: 0 0 0 5px;
                }
            }
            ul.company_list2 {
                display: flex;
                margin: 8px 0 0 0;
                li {
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3){
                        width: 30%;
                        flex-grow: 1;
                        padding: 0;
                        a {
                            padding: 4px;
                        }
                    }
                    &:nth-child(1){
                        margin: 0 1% 0 4%;
                    }
                    &:nth-child(2){
                        padding: 0 0 0 2%;
                    }
                    &:nth-child(2),
                    &:nth-child(3){
                        margin: 0 1%;
                    }

                }
            }
        }

    }

    .content_all {
        min-height: 60px;
        margin: 0 0 5px 0;
        .company_list {
            position: relative;
            .list_2 {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                table-layout: auto;
                li {
                    &:nth-child(1) {
                        display: none;
                    }
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5){
                        display: block !important;
                        width: 25%;
                        flex-grow: 1;
                        a {
                            padding: 0;
                            p.list_2_inbox {
                                line-height: 1.25em;
                            }
                        }
                    }
                    &:nth-child(5){
                        border-left: 1px solid #D4D4D4;
                    }
                }
            }

            .overview_localnavi {
                li {
                    width: 33.333333333%;
                    &.overtime_work,
                    &.recruiting  {
                        display: table !important;
                    }
                    &:nth-of-type(3) {
                        a {
                            border-right: 1px solid #d4d4d4;
                            &::after {
                                display: none;
                            }
                        }
                        .overview_localnavi_nocount {
                            border-right: 1px solid #d4d4d4;
                            &::after {
                                display: none;
                            }
                        }
                    }
                    &:nth-of-type(4) {
                        a {
                            border-left: 1px solid #d4d4d4;
                        }
                        .overview_localnavi_nocount {
                            border-left: 1px solid #d4d4d4;
                        }
                    }
                    &.activate {
                        &:nth-of-type(3) a {
                            border-right: none;
                        }
                        &:nth-of-type(4) a {
                            border-left: none;
                        }
                    }
                }
            }
        }
    }

    .agent {
        margin: 0 0 10px 0;
        h1 {
            padding: 3px 6px;
        }
    }

    .review {
        .kyuuyo_sort {
            margin: 20px 0 10px 0;
        }
    }

}
