///
// job/recruit 配下
///

.pageRecruit--apply {
    width: 900px;
    margin: 0 auto;
}

.recruitApplyHeading {
    padding-bottom: 8px;
    margin-bottom: 20px;
    border-bottom: 2px solid $blue;
    font-size: $fsize-xxlarge;
    font-weight: bold;
}

.recruitApplyCtaBtn {
    display: block;
    width: 300px;
    padding: 20px 0;
    text-align: center;
    border: 1px solid darken($color: $skyblue, $amount: 1.5);
    background-color: $skyblue;
    border-radius: 4px;
    color: $white!important; // 既存CSSの詳細度対策
    line-height: 1;
    font-weight: bold;
    transition: opacity .1s linear;

    &:hover {
        text-decoration: none!important; // 既存CSSの詳細度対策;
        opacity: .8;
    }
}
.recruitApplyCtaBtn--secondary {
    background-color: $bg--link--regist;
    border-color: $border--link--regist;
    color: $black!important; // 既存CSSの詳細度対策;
}
.recruitApplyCtaBtn--default {
    background-color: $border--gray;
    border-color: $border--gray2;
    color: $black!important; // 既存CSSの詳細度対策;
}
.recruitApplyCtaBtn--center {
    margin-left: auto;
    margin-right: auto;
}

.recruitApplySubHeading {
    padding-bottom: 6px;
    margin-bottom: 10px;
    border-bottom: 2px solid $border--gray;
    font-size: $fs--large;
    font-weight: bold;
}
.recruitApplySubHeading--nobd {
    padding-bottom: 0;
    border-bottom: none;
}

/// ヘッダー注記部
.recruitApplyHeader {
    margin: 0 0 20px 0;
}
.recruitApplyHeader__notice {
    text-align: center;
    font-size: 14px;
}
.recruitApplyHeader__onSp {
    display: none;
}

/// 求人情報概要部
.recruitJobOutline {
    display: flex;
    margin-bottom: 20px;
    padding: 20px 0;
    border-top: 2px solid $blue;
    border-bottom: 2px solid $blue;
}
.recruitJobOutline__logo {
    width: 140px;
    padding-right: 20px;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}
.recruitJobOutline__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.recruitJobOutline__jobTitle {
    margin: 0;
    line-height: 1.4;
    font-size: 24px;
    font-weight: bold;
}

.recruitJobOutlineTags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}
.recruitJobOutlineTags__item {
    padding: 4px 8px;
    border: 1px solid $border--gray;
    font-size: 10px;
    line-height: 1;
}
.recruitJobOutlineTags__item--strong {
    color: $orange;
    border-color: $orange;
}
.recruitJobOutline__location {
    display: flex;
    align-items: center;
    gap: 2px;
    line-height: 1;

    &::before {
        @include font-cc($font-cc-address);
        font-size: 18px;

    }
}
.recruitJobOutline__date {
    font-size: 12px;
    color: $textlightgray;
}

/// CTA部
.recruitApplyCta {
    margin: 20px 0;
}
.recruitApplyCta__note {
    font-size: 14px;
    text-align: center;
}

.recruitApplyCtaBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 20px 0;
}

/// アドバイス部
.recruitApplyTips {
    width: 80%;
    margin: 40px auto;
    padding: 20px 40px;
    border: 1px solid $border--gray;
}
.recruitApplyTips__title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin-bottom: 20px;
    line-height: 1;
    font-size: 18px;
    font-weight: bold;

    &::before {
        font-family: "fontawesome";
        content: $fa-lightbulb-o;
        color: $gold;
        font-size:21px;
        font-weight: normal;
    }
}
.recruitApplyTips__note {
    margin-bottom: 20px;
    text-align: center;
}
.recruitApplyTips__container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}
.recruitApplyTips__tips {
    display: flex;
    flex-direction: column;
    gap: 6px;
    list-style: decimal;
    list-style-position: inside;
}
.recruitApplyTips__cta {
    display: block;
    width: 300px;
    padding: 20px 0;
    text-align: center;
    border: 1px solid darken($color: $skyblue, $amount: 1.5);
    background-color: $skyblue;
    border-radius: 4px;
    color: $white!important; // 既存CSSの詳細度対策
    line-height: 1;
    font-weight: bold;
    transition: opacity .1s linear;

    &:hover {
        text-decoration: none!important; // 既存CSSの詳細度対策;
        opacity: .8;
    }
}

/// キャリシート情報部
.recruitApplyCs {
    margin-bottom: 20px;
}
.recruitApplyCslist__item {
    margin-bottom: 20px;
}
.recruitApplyCslist__data {
    width: 100%;

    th, td {
        padding: 10px;
        border: 1px solid $border--gray2;
        border-collapse: collapse;
    }

    th {
        background-color: $border--gray;
        vertical-align: top;
        white-space: nowrap;
        font-weight: bold;
    }

    ul, ol {
        list-style-position: inside;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }
}

/// フッター部
.pageRecruit__footer {
    margin: 40px 0;
}
