@charset 'UTF-8';

/*
おすすめ一覧（2017.7.5）
---------------------------------------------------------------------------- */
@media (max-width: 640px) {

    .sub_page.biz #column>li.column_2 {
        padding: 0 5px;

        /* インデックス */
        .biz-index {
            padding: 15px 10px;
        }

        // カテゴリー一覧
        .biz-cat-list {
            margin-top: 0;
        }
        .biz-cat-item {
            flex-basis: 100%;
            margin-bottom: 5px;
            &:nth-of-type(odd) {
                .biz-cat-item__body {
                    padding-right: 0;
                }
            }
            &:nth-of-type(even) {
                .biz-cat-item__body {
                    padding-left: 0;
                }
            }
        }
        .biz-cat-item__header {
            margin-bottom: 0;
        }
        .biz-cat-item__header-title {
            display: block;
            padding: 10px 0;
            position: relative;
        }
        .biz-cat-item__post-sns {
            width: 100%;
        }
        .biz-cat-item__more-link {
            width: 100%;
        }
    }
}
