@charset 'UTF-8';

@media (max-width: 640px) {

    /*
    企業トップ overview
    ---------------------------------------------------------------------------- */

    .sub_page {
        #column {
            > li.column_2 {

                //平均データ
                .avg_data {
                    padding: 0;
                    .avg_left {
                        height: 200px;
                        width: 100%;
                        .chart_box_detail {
                            height: auto;
                            margin: 0 auto;
                            width: 300px;
                            canvas {
                                transform: none;
                                position: static;
                                top: 0;
                                left: 0;
                                margin: -50px auto 0 !important;
                                display: block;
                            }
                            .rate_num {
                                font-size: $fsize-small;
                                height: 160px;
                                left: 50%;
                                margin-left: -80px;
                                position: absolute;
                                top: 0;
                                width: 160px;
                                .rate_num_data {
                                    position: absolute;
                                    width: 30px;
                                }
                                .rate_num_1 {
                                    left: 65px;
                                    text-align: center;
                                    top: -3px;
                                }
                                .rate_num_2 {
                                    left: 142px;
                                    text-align: left;
                                    top: 46px;
                                }
                                .rate_num_3 {
                                    left: 142px;
                                    text-align: left;
                                    top: 126px;
                                }
                                .rate_num_4 {
                                    left: 65px;
                                    text-align: center;
                                    top: 160px;
                                }
                                .rate_num_5 {
                                    left: -10px;
                                    text-align: right;
                                    top: 126px;
                                }
                                .rate_num_6 {
                                    left: -10px;
                                    text-align: right;
                                    top: 46px;
                                }
                            }
                            .no_rate {
                                height: 300px;
                                width: 300px;
                            }
                        }
                        .whiteblack_link{
                            left: -3px;
                            top: -70px;
                            text-align: right;
                            display: block;
                        }
                    }
                    .avg_right {
                        float: none;
                        height: auto;
                        margin-top: 15px;
                        width: 100%;
                    }
                }

                //口コミをテーマ・職種から探す
                .overview_themes_box {
                    .overview_category_list {
                        display: block;
                        dt {
                            border-left: 3px solid #2a2184;
                            flex-basis: auto;
                            margin-bottom: 8px;
                            padding: 0 0 0 6px;
                            line-height: 1.2;
                            &::after {
                                content: "";
                            }
                        }
                        dd {
                            flex-basis: auto;
                            ul {
                                li {
                                    a {
                                        padding: 10px;
                                    }
                                    &.mod_nodata {
                                        padding: 10px;
                                    }
                                }
                            }
                        }
                    }
                }

                //企業情報の導入文
                .overview_company_intro {
                    .overview_company_intro_txt {
                        height: 36px;
                    }
                }

                //社員の状況などの企業情報
                .overview_company_info_box {
                    > li {
                        h3 {
                            min-height: auto;
                            padding: 10px;
                        }
                        .overview_company_info_list {
                            display: none;
                            dl {
                                display: block;
                                dt {
                                    padding: 8px 0 3px 0;
                                }
                                dd {
                                    border-top: none;
                                    padding: 0 0 8px;
                                }
                                &.overview_company_info_employee {
                                    display: -ms-flexbox;
                                    display: flex;
                                    flex-wrap: wrap;
                                    dt {
                                        display: -ms-flexbox;
                                        display: flex;
                                        flex-basis: 50%;
                                        padding: 8px 10px 8px 0;
                                    }
                                    dd {
                                        border-top: 1px solid $lightgray;
                                        display: -ms-flexbox;
                                        display: flex;
                                        flex-basis: 50%;
                                        padding: 8px 0;
                                    }
                                    dt:nth-of-type(even) {
                                        margin-left: 0;
                                    }
                                    dd:nth-of-type(odd) {
                                        margin-right: 0;
                                    }
                                    dt:nth-of-type(2),
                                    dd:nth-of-type(2) {
                                        border-top: 1px solid $lightgray;
                                    }
                                }
                                &.overview_company_info_recruit_new,
                                &.overview_company_info_recruit_career,
                                &.overview_company_info_recruit_mid,
                                &.overview_company_info_recruit_others {
                                    margin: 0;
                                    dt {
                                        padding: 0 0 3px;
                                        &:first-of-type {
                                            padding: 8px 0 3px;
                                        }
                                    }
                                    dd {
                                        padding: 0 0 8px;
                                        &:first-of-type {
                                            border-top: none;
                                        }
                                    }
                                    &:last-of-type {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                //関連記事
                .overview_media_box {
                    padding: 0 10px 10px;
                    .sec_ttl {
                        margin: 0 -10px 10px !important;
                    }
                    .overview_media_list {
                        background: $white;
                        display: flex;
                        li {
                            flex-basis: 50%;
                            &.mod_nodata, a {
                                border-bottom: 1px solid $lightgray;
                                border-left: none;
                                border-right: 1px solid $lightgray;
                            }
                            &:nth-of-type(2) {
                                &.mod_nodata, a {
                                    border-right: none;
                                }
                            }
                            &:nth-of-type(4) {
                                &.mod_nodata, a {
                                    border-right: none;
                                }
                            }
                            &:nth-of-type(5) {
                                &.mod_nodata, a {
                                    border-bottom: none;
                                    border-right: 1px solid $lightgray;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
