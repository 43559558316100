@charset 'UTF-8';

/*
総合トップ
---------------------------------------------------------------------------- */

@media (max-width: 640px) {

    .top_page {
        #main_visual {
            height: 280px;
            .top_search {
                margin-top: -45px;
            }
            .main_visual_inner {
                .searchform_wrap {
                    .popular_word_wrap {
                        display: block !important;
                        margin: 0 !important;
                        .popular_word {
                            background: #71ccff;
                            border-radius: 3px;
                            color: $white;
                            display: table;
                            font-size: $fsize-xxsmall;
                            height: auto;
                            line-height: 1;
                            margin: 10px 0 5px;
                            padding: 4px 5px;
                            width: auto;
                        }
                        .word_list {
                            font-size: 0;
                            height: 104px !important;
                            line-height: 1;
                            margin-left: 0;
                            overflow: hidden;
                            width: auto !important;
                            li {
                                background: transparent;
                                height: auto;
                                line-height: 1;
                                margin: 0 4px 4px 0;
                                a {
                                    background: rgba(255,255,255,0.5);
                                    font-size: $fsize-xxsmall;
                                    line-height: 1;
                                    padding: 6px 5px;
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        #main {
            margin-top: 20px;
        }
    }

    #main_visual {
        .top_search {
            .searchform_wrap {
                .searchform {
                    height: auto;
                    position: relative;
                    .form_text {
                        border-radius: 4px 0 0 4px !important;
                        margin: 0 !important;
                        width: 85% !important;
                    }

                    input[type=submit]{
                        border-radius: 0 4px 4px 0 !important;
                        color: #26267F;
                        float: right;
                        min-width: 15% !important;
                        width: 15% !important;
                        color: $white;
                        font-size: $fsize-large !important;
                        font-family: "FontAwesome";
                    }

                    #SUGGEST_LIST::before {
                        content: " ";
                        display: block;
                        clear: both;
                    }
                    .result-box {
                        position: absolute;
                        z-index: 10;
                        left: 0;
                        top: 82px;
                        width: 85%;
                        padding: 15px;
                        border-radius: 5px;
                        background: #fff;
                        background: rgba(255,255,255,0.95);
                        box-shadow: 0 0 10px rgba(0,0,0,0.1);
                        li {
                            line-height: 1.3;
                        }
                    }
                    &::after {
                        content: " ";
                        display: block;
                        clear: both;
                    }
                }
            }
        }
    }

    #column {
        li.top_page {
            display: block;

            //h2の見出し
            .toppage_intro {
                padding: 10px 15px;
                h2 {
                    margin-bottom: 5px;
                }
                p {
                    font-size: 11px;
                }
            }

            //カセットのベース
            .toppage_base_post {
                padding: 10px 15px;
                .toppage_profile {
                    display: flex;
                    flex-wrap: wrap;
                    dt {
                        flex-basis: 15%;
                    }
                    dd {
                        flex-basis: 85%;
                        margin-right: 0;
                        &.toppage_profile_job {
                            flex-basis: 85%;
                        }
                    }
                }
            }

            //他の口コミを見るボタン
            .toppage_more_link {
                a {
                    &::after {
                        right: 15px;
                    }
                }
            }

            ////////////////////////////
            //口コミを探すセクション
            ////////////////////////////
            .toppage_review_section {
                flex-basis: auto;
                width: 100%;

                //口コミカセット
                .toppage_review_post {
                    .com_comment_theme {
                        right: -25px;
                    }
                }

                //他の条件から口コミを探す
                .topepage_review_search_list_box {
                    background: #FFF;
                    border-bottom: none;
                    font-size: 13px;
                    height: 100%;
                    left: -100%;
                    margin-bottom: 0;
                    overflow-y: auto;
                    padding: 0 0 60px;
                    position: fixed;
                    top: 0;
                    transition: all .25s ease-in-out 0s;
                    width: 100%;
                    z-index: 100;
                    &.is_open {
                        transform: translate(100%, 0);
                    }
                    h4 {
                        background: #1c2f8b;
                        color: #FFF;
                        font-size: 14px;
                        font-weight: bold;
                        margin: 0;
                        padding: 14px 10px;
                        position: relative;
                        .close_modal_btn {
                            background: $white;
                            border-radius: 14px;
                            display: table !important;
                            height: 28px;
                            margin-top: -14px;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            width: 28px;
                            &::before {
                                background: $blue;
                                content: "";
                                display: block;
                                height: 2px;
                                position: absolute;
                                right: 9px;
                                top: 50%;
                                width: 10px;
                                transform: rotate(45deg);
                            }
                            &::after {
                                background: $blue;
                                content: "";
                                display: block;
                                height: 10px;
                                margin-top: -4px;
                                position: absolute;
                                right: 13px;
                                top: 50%;
                                width: 2px;
                                transform: rotate(45deg);
                            }
                        }
                    }
                    &#modal_region h4 {
                        &::before {
                            @include font-awesome ("\f041", $white, 0 8px 0 0, 16px);
                        }
                    }
                    &#modal_industry h4 {
                        &::before {
                            @include font-awesome ("\f024", $white, 0 8px 0 0, 16px);
                        }
                    }
                    &#modal_attribute h4 {
                        &::before {
                            @include font-awesome ("\f2bb", $white, 0 8px 0 0, 16px);
                        }
                    }
                    .topepage_review_search_first_list {
                        border-bottom: 1px solid #E3E3E3;
                        > li {
                            border-bottom: none;
                            display: block;
                            margin-top: -1px;
                            padding: 0;
                            span {
                                border-top: 1px solid #E3E3E3;
                                color: #2f2f2f;
                                display: block;
                                font-size: 13px;
                                font-weight: bold;
                                line-height: 1.5rem;
                                min-height: 44px;
                                padding: 14px 30px 14px 10px;
                                position: relative;
                                width: 100%;
                                &:hover {
                                    background: $white;
                                    color: #2f2f2f;
                                    &::after {
                                        display: block;
                                    }
                                }
                                &::before {
                                    background: #444;
                                    content: "";
                                    display: block;
                                    height: 2px;
                                    position: absolute;
                                    right: 10px;
                                    top: 50%;
                                    width: 10px;
                                }
                                &::after {
                                    background: #444;
                                    content: "";
                                    display: block;
                                    height: 10px;
                                    margin-top: -4px;
                                    position: absolute;
                                    right: 14px;
                                    top: 50%;
                                    width: 2px;
                                }
                                &.is_open::after {
                                    visibility: hidden;
                                }
                            }
                            &:hover {
                                background: $white;
                                > span {
                                    color: #2f2f2f;
                                }
                            }
                            &.topepage_review_search_first_list_second_line {
                                .topepage_review_search_second_list {
                                    top: auto;
                                }
                            }
                        }
                    }
                    .topepage_review_search_second_list {
                        background: #f5f5f5;
                        border-color: #e3e3e3;
                        border-style: solid;
                        border-width: 1px 0 0 0;
                        box-shadow: none;
                        display: none;
                        font-size: 0;
                        left: auto;
                        min-width: 40%;
                        opacity: 1;
                        padding: 0;
                        position: relative;
                        top: auto;
                        visibility: visible;
                        z-index: auto;
                        > li {
                            display: table;
                            float: left;
                            margin-right: 0;
                            position: relative;
                            width: 50%;
                            a {
                                background: #FFF;
                                border-bottom: 1px solid #E3E3E3;
                                color: #2F2F2F;
                                display: table-cell;
                                height: 44px;
                                line-height: 1.5;
                                padding: 0 24px 0 10px;
                                position: relative;
                                vertical-align: middle;
                                width: 100%;
                                &::after {
                                    background: #fff;
                                    width: auto;
                                    @include sp_link_arrow;
                                }
                            }
                            &:last-of-type {
                                a::after {
                                    display: block;
                                }
                            }
                            &:nth-child(odd) {
                                a {
                                    border-right: 1px solid #E3E3E3;
                                }
                            }
                        }
                        &::after {
                            content: " ";
                            clear: both;
                            display: block;
                        }
                    }
                    .topepage_review_search_rating_list {
                        li {
                            border-bottom: none;
                            display: table;
                            position: relative;
                            width: 100%;
                            a {
                                background: #fff;
                                border-bottom: 1px solid #E3E3E3;
                                color: #2F2F2F;
                                display: table-cell;
                                height: 44px;
                                padding: 0 24px 0 10px;
                                position: relative;
                                vertical-align: middle;
                                width: 100%;
                                &::after {
                                    @include sp_link_arrow;
                                }
                            }
                            &:last-of-type {
                                a::after {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                //給与明細カセット
                .toppage_salary_post {
                    .toppage_profile {
                        margin-bottom: 5px;
                    }
                    .kyuuyo_table {
                        margin-top: 15px;
                        .table {
                            th, td {
                                font-size: $fsize-small;
                                padding: 5px 2px;
                                vertical-align: middle;
                            }
                        }
                    }
                    .nonlogin_kyuuyo {
                        a {
                            background: url(/assets/img/add_2015/review/reviews_salary_sp.png) left top no-repeat;
                            background-size: cover;
                            height: 0;
                            padding-top: 106%;
                            margin: 0 -3%;
                            width: 106%;
                            span {
                                font-size: 13px;
                                margin: -100% auto 0 auto;
                                padding: 10px 0;
                            }
                        }
                    }
                }
            }

            ////////////////////////////
            //右カラム
            ////////////////////////////
            .toppage_right_column {
                flex-basis: auto;
            }

            ////////////////////////////
            //仕事を探すセクション
            ////////////////////////////
            .toppage_job_section {
                width: 100%;

                //人気求人ランキング
                .toppage_ranking_list {
                    li {
                        .toppage_ranking_post {
                            dl {
                                width: 100%;
                            }
                        }
                    }
                }

                //転職ガイド
                .toppage_jobguide_list {
                    li {
                        .toppage_jobguide_post {
                            padding: 10px 15px 15px;
                            dl {
                                .toppage_jobguide_summary {
                                    min-height: 80px;
                                    padding-left: 0;
                                    .toppage_jobguide_photo {
                                        float: left;
                                        height: 80px;
                                        margin-right: 10px;
                                        position: relative;
                                        width: 80px;
                                    }
                                }
                                .toppage_jobguide_category {
                                    bottom: auto;
                                    left: auto;
                                    margin-top: 10px;
                                    position: relative;
                                }
                                .toppage_jobguide_date {
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }

                //転職面接
                .toppage_interview_post {
                    .com_comment_theme {
                        right: -25px;
                    }
                    .toppage_interview_data {
                        dt {
                            flex-basis: 40%;
                        }
                        dt:nth-of-type(2n)::before {
                            display: none;
                        }
                        dd {
                            flex-basis: 60%;
                        }
                    }
                    .nonlogin_interview {
                        a {
                            background: url(/assets/img/add_2015/review/reviews-sp.png) left top no-repeat;
                            background-size: cover;
                            height: 0;
                            padding-top: 47.8%;
                            margin: 0 -1.5%;
                            width: 103%;
                            span {
                                font-size: 13px;
                                margin: -50% auto 0 auto;
                                padding: 10px 0;
                            }
                        }
                    }
                }
            }

            ////////////////////////////
            //企業に関するニュースセクション
            ////////////////////////////
            .toppage_biz_section {
                .toppage_biz_post {
                    padding: 13px 15px 15px;
                    dl {
                        .toppage_biz_category {
                            bottom: auto;
                            left: auto;
                            margin-top: 10px;
                            position: relative;
                        }
                        .toppage_biz_date {
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }
}
