@charset 'UTF-8';

// 転職面接レポート

@media (max-width: 767px) {

    .sub_page {

        // first area
        #column>li.column_2.review_sub {
            .interview_data {
                .data_top,
                .interview_graph {
                    //float: none;
                    width: 100%;
                    display: block;
                    //vertical-align: top;
                }
                .data_top {
                    width: 100%;
                    margin: 0;
                }
                .interview_graph {
                    width: 100%;
                    margin: 10px 0 20px 0;
                    dd {
                        .graph_box {
                            //width: 100%;
                            canvas,
                            .transitive_graph.transitive_graph_bar {
                                //width: 100% !important;
                                //height: auto !important;
                            }
                            .transitive_graph.transitive_graph_bar {
                                margin: 10px 0 0;
                                //text-align: center;
                            }
                        }
                        .on_graph {
                            left: auto;
                            right: 4%;
                            top: 28%;
                            font-size: 12px;
                            padding: 8px;
                            width: 68%;
                        }
                    }
                }
            }
        }

        // second area
        #column>li.column_2 {
            .com_comment {
                .com_comment_innerL {
                    .com_comment_text {
                        //clear: both;
                    }
                }
            }
            .transitive_graph {
                p {
                    &:first-child {
                        //margin-right: 10px;
                    }
                }
            }
        }

    }

}
