@charset 'UTF-8';

/*
#13447【キャリコネ】トップページや主要ＬＰ、ヘッダーなどにＮＯ．１ロゴを掲載する
---------------------------------------------------------------------------- */
@media (max-width: 640px) {
    #header .header_l {
        .inner_wrap {
            justify-content: center;
            #logo {
                left: 0;
                margin: 0;
                padding: 0;
                position: relative;
                top: 0;
            }
        }
    }
    .review .login_box {
        align-items: center;
        display: flex !important;
        margin-bottom: 8px;
        padding-top: 55px;
        .login_box_logo {
            float: none;
            padding-left: 10px ;
            img {
                vertical-align: bottom;
            }
        }
        ul {
            float: none;
            margin-left: auto;
            padding-top: 0;
            padding-right: 10px;
        }
    }
    header {
        .shoulder_copy {
            float: none !important;
            font-size: 10px;
            line-height: 1.2;
            margin-left: 15px;
            padding: 2px 4px !important;
            position: relative;
            width: auto !important;
        }
    }
}

/*
フッターの社名文字色を変更（2017.10.30）
---------------------------------------------------------------------------- */
@media (max-width: 640px) {
    #footer {
        .footer_row_3 {
            .company_title {
                color: $white;
                text-align: center;
            }
        }
    }
}

/*
#19361 キャリコネとは・ヘルプにコンテンツ追加
---------------------------------------------------------------------------- */
@media (max-width: 640px) {
    // ライトボックス風のモーダルウィンドウ（CSSのみで実装）
    .modal {
        .modal__wrap {
            width: 84% !important;
            height: 90%;
            > dl {
                display: flex;
                flex-direction: column;
                height: 100%;
                > dd {
                    overflow: auto;
                    height: 100%;
                }
            }
        }
    }
}

@media (max-width: 767px) {

    .sp-none {
        display: none !important;
    }

    /* ▼▼▼　2018-01 mfi対策で新たに追加されたvariables　▼▼▼ */

    // 見出し
    .second-title {
        background: $white;
        border-top: 1px solid $blue;
        border-bottom: 1px solid $border--gray;
        font-size: $fs--xxxsmall;
        font-weight: normal;
        line-height: 1.4;
        padding: 8px 10px;
        .title-company {
            color: $font--blue--review;
        }
        strong {
            display: block;
            font-size: $fs--xlarge;
            font-weight: bold;
        }
    }

    // ボタン（もっと見る）
    .button--more-link {
        background: $bg--gray;
        border-top: 1px solid $border--gray;
        border-bottom: 1px solid $border--gray;
        color: $font--link--blue;
        display: block;
        font-size: $fs--medium;
        font-weight: bold;
        line-height: 1.6;
        margin: 0 0 20px 0;
        padding: 10px 30px;
        position: relative;
        text-align: center;
        width: auto;
        &::after {
            @include sp-link-arrow-after;
            color: $font--link--blue;
            font-weight: normal;
        }
        .button--more-link-small {
            background: $bg--link--blue;
            border-radius: 9px;
            color: $white;
            display: inline-block;
            font-size: $fs--xxxsmall;
            line-height: 1;
            margin-left: 0.5em;
            padding: 4px 8px;
            text-align: center;
            vertical-align: middle;
        }
    }

    .review.sub_page #column>li.column_2 .column_outbox .sec_ttl {
        clear: both;
        margin: 20px 0;
    }

}
