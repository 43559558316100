@charset 'UTF-8';

.NewJob {
    .login_box {
        div.login_box_logo {
            float: left;
            width: 100px;
            padding-left: 15px;
            a {
                img {
                    width: 100%;
                }
            }
        }
    }
}
