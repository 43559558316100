@charset 'UTF-8';

.sub_page #column>li .column_2.review_sub.review_job.wide {
    margin-bottom: 60px;
    //求人詳細 - ヘッダー
    .job_header {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 40px;
        .job_title {
            margin: 0;
            line-height: 1.5;
        }
        .job_lead {
            margin-top: 5px;
            font-size: 15px;
            font-weight: bold;
            line-height: 1.5;
        }
        .tag_group {
            margin-bottom: 0;
        }
        .company_info {
            margin-top: 5px;
            margin-bottom: 0;
        }
        .button_group {
            order: 2;
            margin-top: 10px;
            margin-bottom: 0;
            .button_review {
                height: 39px;
                margin-top: 0;
                padding-top: 0;
                line-height: 39px;
            }
        }
    }
    .job_header_inner {
        display: flex;
        order: 2;
    }
    .job_header_text {
        flex: 1;
        order: 2;
    }
    .job_header_main {
        margin-bottom: 10px;
    }
    .job_header_sub {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .tag_group {
            .tag_item {
                margin-bottom: 0;
            }
        }
    }
    .job_header_company {
        margin-right: 12px;
        font-size: 13px;
    }
    .job_header_logo {
        display: flex;
        align-items: center;
        justify-content: center;
        order: 1;
        width: 100px;
        height: 100px;
        margin-right: 20px;
        background: #fff;
        img {
            max-width: 80%;
            max-height: 80%;
        }
    }
    .job_header_meta {
        display: flex;
        align-items: center;
        justify-content: right;
        order: 1;
        margin: -30px 0 20px;
    }
    .job_header_update {
        display: flex;
        color: $font--gray--light;
        font-size: 11px;
        line-height: 1.5;
        li + li {
            margin-left: 2em;
        }
    }
    .job_header_alliance {
        display: flex;
        align-items: center;
        margin-left: 2em;
        color: $font--gray--light;
        font-size: 11px;
        line-height: 1.5;
        img {
            display: block;
            width: 110px;
        }
    }

    //求人詳細 - コンテンツ
    .content_title {
        display: inline-flex;
        width: auto;
        min-width: 160px;
        height: auto;
        padding: 5px 16px 3px;
        line-height: 1.75rem;
    }
    .company_pr {
        .pr_text_box {
            border-bottom-style: solid;
        }

        .pr_text_box_item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            &:after {
                display: block;
                content: "";
                clear: both;
            }
        }
    }
    .jobinfo_table {
        tr:last-of-type {
            th, td {
                border-bottom-style: solid;
            }
        }
    }
    .sections {
        li:last-of-type {
            .section_box {
                margin-bottom: 0;
            }
        }
    }

    //求人詳細 - 会社紹介（スカウト求人のみ）
    .jobinfo_introduction {
        padding: 20px 30px 30px;
        border: 1px solid #d7d7d7;
        border-bottom: none;
        line-height: 1.5;
    }
    .jobinfo_introduction_unit {
        &:nth-of-type(n+2) {
            margin-top: 30px;
        }
    }
    .jobinfo_introduction_title {
        margin-bottom: 1.5rem;
        padding-bottom: .75rem;
        border-bottom: 1px dotted #d7d7d7;
        font-size: 20px;
        font-weight: bold;
    }
    .jobinfo_introduction_lead {
        margin-bottom: 1.5rem;
        font-size: 18px;
    }
    .jobinfo_introduction_body {
        font-size: 13px;
    }

    .recommend_box {
        margin-top: 60px;
    }

    // CCDR用
    .jobofferContent {
        display: flex;

        .jobofferContentImg {
            img {
                display: block;
                width: 230px;
                height: auto;
                margin-left: 40px;
            }
        }

        .pr_text_box_item {
            h2, p {
                width: auto;
                float: none;
            }
        }
    }
}

