@charset 'UTF-8';

// 年収・給与明細レポート

.sub_page {
    .com_kyuuyo {
        .kyuuyo_detail {
            .table{
                th, td {
                    //vertical-align: middle;
                }
            }
        }
    }
}

// #13548 【キャリコネ】給与明細の表示統一
.review {
        .kyuuyo_detail {
                .table {
                    th, td {
                        vertical-align: middle;
                    }
                }
        }
}
