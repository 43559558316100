@charset 'UTF-8';

/*
おすすめ系コンテンツ
---------------------------------------------------------------------------- */
@media (max-width: 640px) {

    /* メインカラム  */
    .recommend_main {
        float: none;
        margin: 0 0 30px 0;
        padding: 0 10px;
        width: auto;

        /* h1見出し */
        h1 {
            background: $white;
            border-top: 3px solid $blue;
            font-size: $fsize-xxlarge;
            margin: 0 -15px 15px;
            padding: 15px;
        }

        /* リード文 */
        .recommend_lead {
            background: none;
            margin-bottom: 15px;
            padding: 0;
            .recommend_lead_text_02 {
                display: none;
            }
            .sp_accordion_toggle {
                color: #5290cd;
                cursor: pointer;
                display: block;
            }
        }

        /* アンカー（条件を変更） */
        .search_anchor {
            margin-bottom: 10px;
            position: relative;
            text-align: right;
            &::before {
                color: #000082;
                content: "\e800";
                font-size: $fsize-xxsmall;
                font-family: "fontello";
                margin-right: 5px;
            }
            a {
                color: $blue;
            }
        }

        /* 企業一覧 */
        .recommend_list {
            margin-bottom: 30px;
            > li {
                margin-bottom: 15px;
                padding: 0 10px 15px;

                /* 企業名 */
                .recommend_list_title_rank {
                    margin: 0 -10px;
                    padding: 3px 0 0;
                    .button-follow {
                        right: 10px;
                        bottom: -40px;
                        top: auto;
                        transform: none;
                    }
                    .button-follow__tooltip {
                        &.is-show {
                            visibility: hidden;
                            opacity: 0;
                        }
                    }
                }
                h2 {
                    background: #f0f4fc;
                    font-size: $fsize-large;
                    padding: 0 0 0 10px;
                    a {
                        display: block;
                        padding: 10px 30px 10px 0;
                        width: 100%;
                    }
                    &::after {
                        @include sp_link_arrow;
                    }
                    .recommend_list_title_rank_no1,
                    .recommend_list_title_rank_no2,
                    .recommend_list_title_rank_no3 {
                        left: 10px;
                    }
                }

                /* 評価と件数表示 */
                .recommend_list_detail {
                    display: block;
                    padding: 6px 0 10px;
                    .rating_data {
                        display: block;
                        margin-bottom: 10px;
                        width: auto;
                        .primary_data {
                            font-size: $fsize-middle;
                            .rating_data_title {
                                display: inline-block;
                                font-size: $fsize-small;
                            }
                            i {
                                font-size: $fsize-large !important;
                                height: 16px !important;
                                margin-right: -3px !important;
                                width: 16px !important;
                                &::before {
                                    font-size: $fsize-large;
                                }
                            }
                            a{
                                width: fit-content;
                            }
                            .rating_data_value {
                                font-size: $fsize-large;
                            }
                        }
                        .secondary_data {
                            font-size: $fsize-small;
                            margin-top: 4px;
                            i {
                                font-size: $fsize-small !important;
                                height: 12px !important;
                                margin-right: 0 !important;
                                width: 12px !important;
                            }
                        }
                    }
                    .review_data {
                        float: none;
                        width: 100%;
                        li {
                            height: 48px;
                            &:first-child {
                                border-left: none;
                            }
                            a {
                                display: block;
                                padding: 10px 0;
                                width: 100%;
                            }
                            .review_data_title {
                                font-size: $fsize-xxsmall;
                            }
                            .review_data_value {
                                font-size: $fsize-middle;
                                &::after {
                                    content: " ";
                                }
                            }
                        }
                    }
                }

                /* 企業概要 */
                .company_outline  {
                    .company_industry {
                        margin-bottom: 4px;
                        width: 100%;
                    }
                    .company_address {
                        width: 100%;
                    }
                }
            }
        }
    }

    /* 検索カラム  */
    .recommend {
        .recommend_navi {
            float: none;
            margin-bottom: 30px;
            padding: 0 15px;
            width: auto;

            /* モーダルウィンドウを開くメニュー  */
            #sp_modal_menu {
                font-size: $fsize-small-middle;
                margin-top: -60px;
                margin-bottom: 15px;
                padding-top: 60px;
                .sp_modal_menu_title {
                    background: #1c2f8b;
                    color: $white;
                    font-size: $fsize-middle;
                    font-weight: bold;
                    padding: 8px 10px 8px 15px;
                    &::before {
                        @include font-awesome ("\f002", $white, 0 8px 0 0, 16px);
                    }
                }
                a {
                    background: $white;
                    border-color: $lightgray;
                    border-style: solid;
                    border-width: 0 1px 1px;
                    color: $darkgray;
                    display: block;
                    font-weight: bold;
                    padding: 15px 25px 15px 38px;
                    position: relative;
                    width: 100%;
                    &:before {
                        color: $blue;
                        display: block;
                        font-family: "fontawesome";
                        font-size: $fsize-large;
                        font-weight: normal;
                        position: absolute;
                    }
                    &.category_region:before {
                        content: "\f041";
                        margin-left: -24px;
                    }
                    &.category_industry:before {
                        content: "\f024";
                        font-size: $fsize-middle;
                        margin-left: -26px;
                    }
                    &.category_rating:before {
                        content: "\f006";
                        margin-left: -26px;
                    }
                    &.category_attribute:before {
                        content: "\f2bb";
                        margin-left: -26px;
                    }
                    &:after {
                        @include sp_link_arrow;
                    }
                }
            }

            /* 絞り込み検索  */
            #modal_region,
            #modal_industry,
            #modal_rating,
            #modal_attribute {
                background: $white;
                border-bottom: none;
                font-size: $fsize-small-middle;
                height: 100%;
                left: -100%;
                margin-bottom: 0;
                overflow-y: auto;
                padding-bottom: 60px;
                position: fixed;
                top: 0;
                transition: all .25s ease-in-out 0s;
                width: 100%;
                z-index: 100;
                &.is_open {
                    -webkit-transform: translate(100%, 0);
                    -ms-transform: translate(100%, 0);
                    transform: translate(100%, 0);
                }
            }
            .recommend_search_box {
                /* 絞り込み検索 ボックス見出し  */
                .search_box_title {
                    padding: 14px 10px;
                    &.category_change {
                        padding: 8px 10px;
                    }
                    .close_modal_btn {
                        background: $white;
                        border-radius: 14px;
                        display: table !important;
                        height: 28px;
                        margin-top: -14px;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        width: 28px;
                        &::before {
                            background: $blue;
                            content: "";
                            display: block;
                            height: 2px;
                            position: absolute;
                            right: 9px;
                            top: 50%;
                            width: 10px;
                            transform: rotate(45deg);
                        }
                        &::after {
                            background: $blue;
                            content: "";
                            display: block;
                            height: 10px;
                            margin-top: -4px;
                            position: absolute;
                            right: 13px;
                            top: 50%;
                            width: 2px;
                            transform: rotate(45deg);
                        }
                    }
                }

                /* 絞り込み検索 アコーディオン  */
                .search_accordion {
                    border-color: $lightgray;
                    border-style: solid;
                    border-width: 0 0 1px;
                    li > span {
                        min-height: 44px;
                        padding: 14px 30px 14px 10px;
                    }
                }

                /* 絞り込み検索 リンク一覧  */
                .search_link {
                    li {
                        margin: 0 8px 8px 0;
                        a {
                            padding: 12px;
                        }
                        &.selected {
                            padding: 12px;
                        }
                        &.noresult {
                            padding: 12px;
                        }
                    }
                }
            }
            #modal_region ,#modal_industry {
                padding-bottom: 60px;
            }
            #modal_rating .search_link {
                border-width: 0 0 1px;
            }

            /* recommend_links_box */
            .recommend_links_box {
                padding: 0;

                .links_box_title {
                    margin: 0;
                }

                li {
                    a {
                        padding: 10px 25px 10px 10px;
                        position: relative;
                        &:after {
                            @include sp_link_arrow;
                        }
                    }
                }

                .js-more-content {
                    li {
                        border-top: 1px solid $lightgray;
                        &:first-child {
                            border-top: 1px solid $lightgray;
                        }
                        &:last-child {
                            border-top: none;
                        }
                    }
                }

                .links_more_link {
                    border-top: 1px solid $lightgray;
                    padding: 0;
                    position: relative;
                    text-align: center;
                    &::before {
                        @include sp_link_arrow;
                        margin-right: 0;
                    }
                    a {
                        display: block;
                        padding: 13px;
                        width: 100%;
                    }
                }

                .links_plus {
                    background: $bg--gray;
                    border-top: 1px solid $border--gray;
                    color: $font--link--blue;
                    display: block;
                    font-size: $fs--medium;
                    font-weight: bold;
                    line-height: 2;
                    margin: 0;
                    padding: 10px 30px;
                    position: relative;
                    text-align: center;
                    width: 100%;
                    &::after {
                        @include font-cc($font-cc-acdclose);
                        display: block;
                        font-size: 20px;
                        margin: -10px 0 0 0;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                    }
                }

            }
        }
    }
}

@media (max-width: 320px) {
    .recommend_main {
        .recommend_list>li{
            .recommend_list_detail{
                .rating_data{
                    .primary_data{
                        a{
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}
