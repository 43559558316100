@charset 'UTF-8';

/*
口コミサイト比較
---------------------------------------------------------------------------- */
.sub_page #column>li.column_2 {

    .compare-contents-area {
        background: $white;
        border: 1px solid $gray--light;
        padding: 10px 20px;
        margin: 0 0 25px 0;
        width: 100%;

        // ビジュアル
        .compare-contents-area__visual {
            display: table;
            height: 94px;
            margin: 0 0 15px 0;
            width: 600px;
            .balloon {
                background: #e9d14d;
                border-radius: 20px;
                color: $white;
                font-size: $fs--xsmall;
                height: auto;
                margin: 0 0 0 172px;
                padding: 2px 10px;
                position: relative;
                width: fit-content;
                &::before {
                    content: "";
                    position: absolute;
                    bottom: -14px;
                    left: 10px;
                    width: 0;
                    height: 0;
                    //border-top: 13px solid #C52D2F;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                }
                &::after {
                    content: "";
                    position: absolute;
                    bottom: -8px;
                    left: 10px;
                    width: 0;
                    height: 0;
                    border-top: 12px solid #e9d14d;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                }
            }
            h3 {
                border: none;
                font-size: $fs--xlarge;
                font-weight: bold;
                margin: 0 15px 0 170px;
                padding: 0;
                line-height: 1.4;
                strong {
                    color: #f8ae10;
                }
            }
            &.ptn-matome {
                background: url(../img/review/corp/compare/matome-img.jpg) left top no-repeat;
                background-size: contain;
                h3 {
                    display: table-cell;
                    font-size: $fsize-small-middle;
                    padding: 0 15px 0 170px;
                    vertical-align: middle;
                }
                strong, b {
                    font-size: $fs--xlarge;
                }
            }
            &.ptn-kyuyo {
                background: url(../img/review/corp/compare/kyuyo-img.jpg) left top no-repeat;
                background-size: contain;
            }
            &.ptn-shintyaku {
                background: url(../img/review/corp/compare/shintyaku-img.jpg) left top no-repeat;
                background-size: contain;
            }
            &.ptn-black {
                background: url(../img/review/corp/compare/black-img.jpg) left top no-repeat;
                background-size: contain;
            }
        }

        // desc
        .compare-contents-area__lead {
            border-bottom: 1px solid #eee;
            color: $gray;
            line-height: 1.5em;
            margin: 0 0 15px 0;
            padding: 0 0 15px 0;
        }

        // 比較表
        .compare-contents-area__table-ptn1 {
            width: 100%;
            th, td {
                font-weight: bold;
                width: 20%;
                strong {
                    font-size: $fsize-xxlarge;
                }
            }
            th {
                padding: 8px 10px 8px 0;
                text-align: right;
            }
            td {
                padding: 10px 0;
                text-align: center;
            }
            .cc {
                color: $blue;
            }
            .td-border {
                border-bottom: 1px solid #eee;
                a strong{
                    text-decoration: underline;
                }
            }
            a.border {
                border: 1px solid #d4d4d4;
                box-shadow: 0px 2px 0px 0px #cacaca;
                border-radius: 4px;
                color: $blue;
                display: block;
                padding: 7px 0;
                width: 80%;
                margin: 0 auto;
                &:hover, &:active {
                    background: #e8f1ff;
                    border: 1px solid #e8f1ff;
                    text-decoration: none;
                }
            }
            .fs13 {
                font-size: $fsize-small-middle;
                 position: relative;
                i{
                    left: 3px;
                    position: relative;
                }
                .site_name{
                    &:hover{
                        cursor: pointer;
                        span{
                            background: $purple;
                            border-radius: 5px;
                            bottom: 40px;
                            box-shadow:  2px 2px 2px 0px rgba(0,0,0,0.3);
                            color: $white;
                            display: block;
                            font-size: $fsize-small;
                            height: 34px;
                            left: -35px;
                            line-height: 34px;
                            position: absolute;
                            text-align: center;
                            width: 190px;
                        }
                    }
                    span{
                        display: none;
                    }
                }
            }
            .fs14 {
                font-size: $fsize-middle;
                strong {
                    font-size: $fsize-xxxxlarge;
                }
            }
            .crown {
                display: inline-block;
                height: 13px;
                margin: 0 5px 0 0;
            }
        }

        // リンクエリア
        .compare-contents-area__link-area {
            display: block;
            padding: 6px;
            &:link, &:active, &:hover, &:focus, &:visited {
                color: $gray--dark;
                text-decoration: none;
            }
            &:hover, &:active {
                background: #e8f1ff;
                padding: 6px;
                .com_comment_inner{
                    background: #e8f1ff;
                }
            }
            .lightblue_text{
                color: $lightblue;
                text-align: right;
            }
        }

        // リンクボタン
        .compare-contents-area__link-btn {
            background: $purple;
            border-radius: 4px;
            color: $white;
            font-size: $fs--medium;
            font-weight: bold;
            display: block;
            margin: 10px auto;
            padding: 15px 30px;
            text-align: center;
            width: fit-content;
            &:hover, &:active {
                opacity: 0.8;
                text-decoration: none;
            }
        }

        // 取得データについて
        .button--modal-supplement-frame {
            padding: 10px 0 0 0;
            text-align: right;
            width: 100%;
        }
        .button--modal-supplement {
            color: $blue;
            &::before {
                content: "\f059";
                display: inline-block;
                font-family: FontAwesome;
                font-size: $fsize-middle;
                margin-right: 3px;
            }
            &:hover, &:active {
                cursor: pointer;
                text-decoration: underline;
            }
        }

    }

    //４つの口コミサイトの特徴について
    .compare-contents-area__company-chara {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px 20px;
    }
    .compare-contents-area__company-chara-lead {
        flex-grow: 1;
        margin-bottom: 15px;
    }
    .compare-contents-area__company-chara-list {
        flex-basis: 48.5%;
        margin-bottom: 20px;
        dt {
            color: $white;
            font-size: $fsize-middle;
            padding: 6px 15px;
            text-align: center;
        }
        dd {
            font-size: $fsize-small-middle;
            padding: 10px 15px;
        }
    }
    .compare-contents-area__company-chara-cc {
        background: #edeffa;
        dt {
            background: #26267f;
        }
    }
    .compare-contents-area__company-chara-vo {
        background: #edf2f6;
        dt {
            background: #24548a;
        }
    }
    .compare-contents-area__company-chara-jt {
        background: #edf9ed;
        dt {
            background: #4dc452;
        }
    }
    .compare-contents-area__company-chara-en {
        background: #e4f2ff;
        dt {
            background: #1ab1e6;
        }
    }

    .compare-contents-area__cta {
        margin: 45px auto 60px;
    }
    .compare-contents-area__cta-txt {
        font-size: $fsize-xxlarge;
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 12px;
        text-align: center;
    }
    .compare-contents-area__cta-button {
        background: $purple;
        border-radius: 6px;
        color: $white;
        display: block;
        font-size: $fsize-xxlarge;
        font-weight: bold;
        line-height: 1.5;
        padding: 25px 30px;
        position: relative;
        text-align: center;
        width: 100%;
        &::before {
            font-size: 28px;
            font-weight: normal;
            line-height: 1;
            margin-top: -14px;
            position: absolute;
            right: 10px;
            top: 50%;
        }
        &:hover, &:active {
            opacity: 0.8;
            text-decoration: none;
        }
    }
}
