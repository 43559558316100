@charset 'UTF-8';

/*
REVIEWトップ
---------------------------------------------------------------------------- */
@media (max-width: 640px) {
    /*
    #13262【キャリコネ】SP検索ボタンの位置（2017.7.13）
    ---------------------------------------------------------------------------- */
    .sub_page {
        #main_visual {
            .top_search {
                margin-top: 22px;
            }
        }
    }
}
