@charset 'UTF-8';

/*
地域、業界、評価・労働環境、投稿者属性一覧で共通（2018.1.30更新）
---------------------------------------------------------------------------- */
.category {
    margin-bottom: 30px;
    position: relative;

    /* h1見出し */
    h1 {
        display: table;
        height: 140px;
        width: 100%;
        span {
            color: $white;
            display: table-cell;
            font-size: 28px;
            line-height: 1.4;
            padding-left: 65px;
            position: relative;
            text-shadow: 0 0 10px rgba(0, 22, 128, 1);
            vertical-align: middle;
            &::before {
                font-weight: normal;
                height: 32px;
                left: 20px;
                line-height: 1;
                position: absolute;
                top: 50%;
                width: 32px;
            }
        }
    }

    /* リード文 */
    .category_lead {
        background: $white;
        font-size: $fsize-small;
        line-height: 1.6;
        margin-bottom: 30px;
        padding: 15px;
    }

    /* h2見出し */
    h2 {
        background: $white;
        border-color: $lightgray;
        border-style: solid;
        border-width: 0 1px 1px 1px;
        color: $blue;
        font-size: $fsize-xlarge;
        margin: 0;
        padding: 16px 15px 13px;
        position: relative;
        text-align: left;
        &:before {
            background: $blue;
            content: "";
            display: block;
            height: 3px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}

/*
地域一覧（2017.7.5）
---------------------------------------------------------------------------- */
.category_region {
    /* h1見出し */
    h1 {
        background: url(../img/add_2017/review/bg_region_title.jpg) left center no-repeat;
        span::before {
            @include font-awesome ("\f041", $white, -18px 0 0 0, 36px);
        }
    }

    /* 地域から探す */
    .category_region_navi {
        background: url(../img/add_2017/review/bg_map.png) left top no-repeat;
        border-color: $lightgray;
        border-style: solid;
        border-width: 0 1px 1px 1px;
        min-height: 405px;
        position: relative;
        .region_box {
            background: rgba(255, 255, 255, 0.7);
            border: 1px solid #ccc;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            padding: 13px 20px;
            position: absolute;
            h3 {
                font-size: $fsize-small-middle;
                font-weight: bold;
            }
            li {
                display: inline-block;
                a {
                    color: #3f93c5;
                }
            }
        }
        .region_box.hokkaido {
            height: 88px;
            left: 452px;
            top: 60px;
            width: 168px;
        }
        .region_box.kanto {
            height: 88px;
            left: 412px;
            top: 172px;
            width: 168px;
        }
        .region_box.hokuriku {
            height: 88px;
            left: 267px;
            top: 60px;
            width: 100px;
        }
        .region_box.chubu {
            height: 88px;
            left: 314px;
            top: 282px;
            width: 130px;
        }
        .region_box.kansai {
            height: 88px;
            left: 180px;
            top: 172px;
            width: 140px;
        }
        .region_box.tyugoku {
            height: 88px;
            left: 104px;
            top: 282px;
            width: 180px;
        }
        .region_box.kyusyu {
            height: 110px;
            left: 19px;
            top: 150px;
            width: 128px;
        }
    }
}
