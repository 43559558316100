@charset 'UTF-8';

@media (max-width: 767px) {

    // 各種口コミ投稿者情報共通
    ul.reviewer-info-common1 {
        padding: 0 25% 10px 16px;
        .star {
            i {
                font-size: 14px;
                height: 14px;
                width: 14px;
            }
            span {
                font-size: 13px !important;
            }
        }
    }
    ul.reviewer-info-common2 {
        margin: 0 0 4px 0;
        .star {
            i {
                font-size: 14px;
                height: 14px;
                width: 14px;
            }
            span {
                font-size: 13px !important;
            }
        }
    }
    a.review-cassette-company {
        padding: 0 25% 5px 16px;
    }
    a.review-cassette-company2 {
        padding: 0 0 5px 16px;
    }
    div.review-cassette-company {
        padding: 0 25% 5px 16px;
    }
    div.review-cassette-company2 {
        padding: 0 0 5px 16px;
    }
    .com_comment_star,
    .sgd_list_star {
        height: 14px;
        i {
            font-size: 14px;
            height: 14px;
            width: 14px;
        }
        span {
            font-size: 13px !important;
        }
    }

    /*
    #12332【キャリコネ】SPにてフッター付近のネットワーク広告が左寄せになっている
    ---------------------------------------------------------------------------- */
    .report_footer_bottom_banner {
        width:320px;
        height:70px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }


    /*
    #12768 & #13220【キャリコネ】【Markeship】h1タグの修正【施策ID:1-4 & 施策ID:27-1】
    ---------------------------------------------------------------------------- */
    .company_information {
        .company_information-heading {
            padding: 0 10% 0 0;
        }
    }

    .agent {
        .agent-heading {
            padding: 3px 6px;
        }
    }

}


@media (max-width: 640px) {

    /*
    #12768 & #13220【キャリコネ】【Markeship】h1タグの修正【施策ID:1-4 & 施策ID:27-1】
    ---------------------------------------------------------------------------- */
    .sub_page {
        #column>li.column_2 {
            .column_outbox {
                .com_comment {
                    .com_comment_inner {
                        .com_comment_info {
                            width: 100%;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    .company_information {
        .company_information-heading {
            .company_name_y {
                float: left;
            }
            .kuchikomi_pencil {
                right: -4px;
                top: -6px;
                font-size: 22px;
            }
            .favorite {
                right: 24px;
                top: -6px;
            }
        }
    }

    .agent {
        .agent-heading {
            margin: 0 auto;
            font-size: 13px;
            padding: 8px 6px 3px 6px;
            line-height: 20px;
            color: #ff2e34;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    /*
    #12768 & #13220【キャリコネ】【Markeship】h1タグの修正【施策ID:1-4 & 施策ID:27-1】
    ---------------------------------------------------------------------------- */
    .review {
        .login_box {
            div {
                float: left;
                width: 100px;
                padding-left: 15px;
                img {
                    width: 100%;
                }
            }
        }
    }

}
