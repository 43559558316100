@charset 'UTF-8';

@media (max-width: 640px) {

    /*
    エラーページ 404
    ---------------------------------------------------------------------------- */

    #column {
        li.error_page {
            width: auto;

            //エラーステータス
            .error_intro {
                margin: 40px 0 50px;
                h1 {
                    font-size: 24px;
                }
                .error_status_message {
                    br {
                        display: none;
                    }
                }
            }

            //テキスト検索
            .error_searchform {
                .error_searchform_tab {
                    padding: 0 10px;
                    li {
                        flex-basis: 31.4%;
                        margin: 0;
                        width: auto;
                        .pc {
                            display: none !important;
                        }
                    }
                    li + li {
                        margin: 0 0 0 2.9%;
                    }
                }

                .error_searchform_content {
                    li {
                        padding: 10px;
                        form {
                            display: flex;
                            flex-wrap: wrap;
                        }
                        .form_text {
                            border-radius: 4px 0 0 4px;
                            flex-basis: 80%;
                            margin-right: 0;
                            width: auto;
                        }
                        .select_wrap {
                            flex-basis: 31.4%;
                            margin-bottom: 10px;
                            margin-right: 2.9%;
                            min-width: auto !important;
                            .customSelect {
                                width: 100%;
                            }
                            &:last-of-type {
                                margin-right: 0;
                            }
                        }
                        .select_s {
                            .customSelect {
                                min-width: auto !important;
                            }
                        }
                        .btn_l {
                            border-radius: 0 4px 4px 0;
                            flex-basis: 20%;
                            min-width: auto;
                        }
                    }
                }
            }

            //目的別に探す
            .error_purpose_link {
                ul {
                    display: block;
                    li {
                        margin-bottom: 10px;
                        width: 100%;
                        a {
                            height: 80px;
                            padding: 10px 0;
                            position: relative;
                            em {
                                margin-top: 5px;
                            }
                            &::after {
                                @include sp_link_arrow;
                            }
                            &.error_purpose_link_job {
                                &::after {
                                    color: #cc0000;
                                }
                            }
                            &.error_purpose_link_biz {
                                &::after {
                                    color: #20660a;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
