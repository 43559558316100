@charset 'UTF-8';

/*
口コミを探すリンク（2017.7.5）
---------------------------------------------------------------------------- */

/* REVIEWトップに配置 */
.column_2 {
    .recommend_navi {
        background: $white;
        border: 1px solid $lightgray;
        margin-bottom: 25px;
        padding: 15px 15px 0;

        /* カテゴリごとのボックス  */
        .recommend_search_box {
            margin-bottom: 25px;

            /* ボックス見出し  */
            .search_box_title {
                border-bottom: 1px solid $blue;
                color: $blue;
                font-size: $fsize-middle;
                font-weight: bold;
                margin-bottom: 10px;
                padding-bottom: 10px;
                position: relative;
                &::before {
                    color: $blue;
                    font-family: "fontawesome";
                    font-size: $fsize-large;
                    margin-right: 8px;
                }
                a {
                    color: $blue;
                }
            }
            &.category_region .search_box_title::before {
                content: "\f041";
            }
            &.category_industry .search_box_title::before {
                content: "\f024";
                font-size: $fsize-middle;
            }
            &.category_attribute .search_box_title::before {
                content: "\f2bb";
            }
            &.category_rating .search_box_title::before {
                content: "\f006";
            }
            &.category_study_occupation {
                margin-top: -15px;
                .search_link {
                    li {
                        width: 33.333%;
                    }
                }
            }
            &.category_change .search_box_title::before {
                content: "\f00d";
            }
        }

        /* リンク一覧  */
        .sp_search_accordion {
            > li {
                display: table;
                position: relative;
                width: 100%;
                &::after {
                    background: $lightgray;
                    bottom: 0;
                    content: "";
                    display: block;
                    height: 1px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                }
                > span {
                    display: table-cell;
                    font-weight: bold;
                    padding-top: 9px;
                    vertical-align: top;
                    width: 88px;
                }
            }
            .search_link {
                display: table-cell;
            }
        }

        .search_link {
            &::after {
                content: " ";
                clear: both;
                display: block;
            }
            li {
                border-bottom: 1px solid #f5f5f5;
                display: table;
                float: left;
                line-height: 1.5;
                position: relative;
                width: 14.285%;
                &::after {
                    border-color: transparent transparent transparent #3d32db;
                    border-style: solid;
                    border-width: 4px 0 4px 5px;
                    content: "";
                    display: block;
                    height: 0;
                    left: 5px;
                    margin-top: -5px;
                    position: absolute;
                    top: 50%;
                    width: 0;
                }
                a {
                    color: $darkgray;
                    display: table-cell;
                    height: 40px;
                    padding: 0 5px 0 16px;
                    vertical-align: middle;
                    width: 100%;
                    &:hover {
                        background: #f0f6fc;
                    }
                }
            }
        }
        .category_industry .search_link {
            li {
                width: 25%;
            }
        }
        .category_attribute .search_link {
            li {
                width: 25%;
            }
        }
        .category_rating .search_link {
            li {
                width: 33.333%;
            }
        }
    }
}

/* 企業トップ、口コミ詳細に配置 */
.footer_row_2 {
    .recommend_navi {
        font-size: $fsize-small;
        margin-bottom: 30px;

        /* カテゴリごとのボックス  */
        .recommend_search_box {
            border-bottom: 1px solid $lightgray;
            margin-bottom: 15px;
            padding-bottom: 15px;

            /* ボックス見出し  */
            .search_box_title {
                display: inline-block;
                font-weight: bold;
                vertical-align: top;
                width: 220px;
                a {
                    color: $gray;
                }
            }
        }

        /* リンク一覧  */
        .sp_search_accordion {
            display: inline-block;
            vertical-align: top;
            width: 840px;
            > li {
                display: inline;
                span {
                    color: $gray;
                    display: inline-block;
                    font-weight: bold;
                    &::after {
                        color: #ccc;
                        content: "|";
                        margin-left: .5em;
                    }
                }
            }
        }
        .search_link {
            display: inline;
            letter-spacing: -.4em;
            li {
                display: inline-block;
                font-size: $fsize-small;
                letter-spacing: normal;
                margin-right: .5em;
                a {
                    color: $gray;
                }
                &::after {
                    color: #ccc;
                    content: "|";
                    margin-left: .5em;
                }
            }
        }
    }
}
