@charset 'UTF-8';

.sub_page.biz #column>li.column_2 {

    /* カテゴリーリスト */
    .biz-list {
        background: $white;
        border: 1px solid $lightgray;
        padding: 15px;
        margin-bottom: 25px;
        h3 {
            border-bottom: 3px solid $green;
            color: $green;
            font-size: $fsize-xxlarge;
            font-weight: bold;
            padding: 0 0 10px;
        }
    }
}
